import React, { useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Box, Divider, Tooltip } from '@material-ui/core'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import InsuranceInfoFormProperties from '../InsuranceInfoFormProperties'

const {
    button: { CngIconButton },
    form: {
        field: { CngTextField }
    },
    CngGridItem
} = components

const { initialValues } = InsuranceInfoFormProperties.formikProps

function InsuranceInfoSection({ disabled }) {
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const insuranceInfo = useWatch({ name: 'insuranceInfo' })


    function handleAddInsuranceInfo(data) {
        const insuranceInfo = [...getValues('insuranceInfo'), data].map(
            (insurance, index) => ({ ...insurance, _id: index + 1 })
        )

        setValue('insuranceInfo', insuranceInfo)
    }

    function handleRemoveInsuranceInfo(data) {
        const clonedInsuranceInfo = [...getValues('insuranceInfo')].filter(
            (insurance) => insurance.id ? insurance.id !== data.id : insurance._id !== data._id
        )
        const insuranceInfo = clonedInsuranceInfo.map(
            (insurance, index) => ({ ...insurance, _id: index + 1 })
        )

        setValue('insuranceInfo', insuranceInfo)
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'insuranceInfo')} helperText={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'insuranceSectionHelper')} alignItems='flex-end' id="insurance-info">
                <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
                    {insuranceInfo.map((insurance, index) => (
                        <React.Fragment key={insurance._id || insurance.id || index}>
                            <Grid container spacing={1}>
                                <CngGridItem xs={12} md>
                                    <Grid container spacing={1}>
                                        <CngGridItem xs={12} md={3}>
                                            <CngTextField
                                                size='small'
                                                required
                                                name={`insuranceInfo.${index}.insuranceCompany`}
                                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'insuranceCompany')}
                                                disabled={disabled}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} md={3}>
                                            <CngTextField
                                                size='small'
                                                required
                                                name={`insuranceInfo.${index}.insurancePolicyNo`}
                                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'insurancePolicyNo')}
                                                disabled={disabled}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} md={3}>
                                            <CngTextField
                                                size='small'
                                                required
                                                name={`insuranceInfo.${index}.yearOfIssuance`}
                                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'yearOfIssuance')}
                                                disabled={disabled}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} md={3}>
                                            <CngTextField
                                                size='small'
                                                required
                                                name={`insuranceInfo.${index}.liabilityAmount`}
                                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'liabilityAmount')}
                                                disabled={disabled}
                                            />
                                        </CngGridItem>
                                    </Grid>
                                </CngGridItem>
                                <CngGridItem xs={12} md='auto'>
                                    <Grid container spacing={1} alignItems='center'>
                                        <CngGridItem xs='auto'>
                                            <CngIconButton
                                                type='outlined'
                                                icon={['fal', 'plus']}
                                                onClick={() => handleAddInsuranceInfo(initialValues)}
                                                size='small'
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs='auto'>
                                            <CngIconButton
                                                type='outlined'
                                                icon={['fal', 'copy']}
                                                onClick={() => handleAddInsuranceInfo({ ...insurance, id: undefined })}
                                                size='small'
                                            />
                                        </CngGridItem>
                                        {insuranceInfo.length > 0 && (
                                            <CngGridItem xs='auto'>
                                                <CngIconButton
                                                    type='outlined'
                                                    icon={['fal', 'trash']}
                                                    onClick={() => handleRemoveInsuranceInfo(insurance)}
                                                    size='small'
                                                />
                                            </CngGridItem>
                                        )}
                                    </Grid>
                                </CngGridItem>
                            </Grid>
                            {insuranceInfo.length !== index + 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </Box>
                {insuranceInfo.length === 0 && (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs='auto'>
                                <Tooltip placement='bottom' title='Add new'>
                                    <span>
                                        <CngIconButton
                                            icon={['fal', 'plus']}
                                            onClick={() => handleAddInsuranceInfo(initialValues)}
                                            size='small'
                                            type='outlined'
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </CngSection>
        </Grid>

    )
}

export default InsuranceInfoSection