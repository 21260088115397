import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useFormContext, useWatch } from 'react-hook-form'
import { components, DateTimeFormatter, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography, Card, Hidden } from '@material-ui/core'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { fetchAceHWManifestDefaultValueFromUserProfile } from '../../userprofile/UserProfileService'
import { ACEHighwayTranslationContext } from '../contexts/ACEHighwayTranslationContext'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeValidationSchema from './MakeValidationSchema'
import TemplateSelectDialogWithOption from 'src/components/aciacehighway/TemplateSelectDialogWithOption'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import moment from 'moment-timezone'

import TripInformationSection from './Sections/TripInformationSection'
import TruckSection from './Sections/TruckSection'
import EquipmentInfoSection from './Sections/EquipmentInfoSection'
import CrewInformationSection from './Sections/CrewInformationSection'
import PaxlstInformationSection from './Sections/PaxlstInformationSection'
import InsuranceInfoSection from './Sections/InsuranceInfoSection'
import MessageFunctionSection from './Sections/MessageFunctionSection'

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
    }
  },
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  partyId: "",
  carrierScac: "",
  tripNo: "",
  arrivalDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  arrivalTime: "23:59",
  transitIndicator: "I",
  firstExpectedPort: "",
  messageFunction: "",
  amendmentCode: "",
  status: "",
  responseDate: "",
  templateFlag: false,
  templateName: "",
  aceId: "",
  aceFlag: false,
  paxLSTFlag: false,
  conveyanceId: "",
  conveyanceNo: "",
  conveyanceType: "",
  email: "",
  vin: "",
  dotNo: "",
  transponderId: "",
  licensePlateNo1: "",
  licensePlateCountry1: "",
  licensePlateState1: "",
  licensePlateNo2: "",
  licensePlateCountry2: "",
  licensePlateState2: "",
  licensePlateNo3: "",
  licensePlateCountry3: "",
  licensePlateState3: "",
  licensePlateNo4: "",
  licensePlateCountry4: "",
  licensePlateState4: "",
  iitCode1: false,
  iitCode2: false,
  iitCode3: false,
  iitCode4: false,
  sealNo1: "",
  sealNo2: "",
  sealNo3: "",
  sealNo4: "",
  sealNo5: "",
  sealNo6: "",
  sealNo7: "",
  sealNo8: "",
  sealNo9: "",
  integration: "NANG",
  equipmentInfo: [],
  insuranceInfo: [],
  partyInfo: [],
  shipmentInfo: [],
  paxLSTInfo: [],
  additionalLicensePlateFooterText: "",
  additionalLicensePlateHideText: "",
  additionalLicensePlateShowText: "",
  messageIndicator: "",
  fetchTruck: "",
  saveTruckFlag: false,
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  autoInbondNumUser: false,
  apiAutoSubmit: null,
  fromCloneOrTemplate: false,
  autoSubmitPaxLst: false,
  autoConfirmManifest: false,
  reminderNote: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const {
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { id } = useParams()
  const location = useLocation();

  const { securedSendRequest } = useServices();
  const { setValue, getValues, reset, trigger } = useFormContext();
  const { getTranslatedText } = useContext(ACEHighwayTranslationContext)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()


  let isEditable = false;
  let templateName = getValues("templateName");

  const templateFlag = useWatch({ name: "templateFlag" })
  const fromCloneOrTemplate = useWatch({ name: "fromCloneOrTemplate" })
  const status = useWatch({ name: "status" })
  const paxLSTFlag = useWatch({ name: "paxLSTFlag" })
  const paxLSTInfo = useWatch({ name: 'paxLSTInfo' })
  const licensePlate1 = useWatch({ name: ['licensePlateNo1', 'licensePlateCountry1', 'licensePlateState1'] })
  const licensePlate2 = useWatch({ name: ['licensePlateNo2', 'licensePlateCountry2', 'licensePlateState2'] })
  const licensePlate3 = useWatch({ name: ['licensePlateNo3', 'licensePlateCountry3', 'licensePlateState3'] })
  const licensePlate4 = useWatch({ name: ['licensePlateNo4', 'licensePlateCountry4', 'licensePlateState4'] })

  const templateStatus = status === '1002' ? true : false
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

  const [manifestTemplateDialog, setManifestTemplateDialog] = useState(false)
  const [licensePlateCount, setLicensePlateCount] = useState(1)
  const [requirePaxlst, setRequirePaxlst] = useState(false)
  const [propagateAutoInbondNumberUser, setPropagateAutoInbondNumberUser] = useState([])
  const [showDriver, setShowDriver] = useState(true)

  useEffect(() => {
    setShowDriver(!paxLSTInfo.some((e) => e.partyType === 'VW'))
  }, [paxLSTInfo]);

  if (templateFlag === false && (templateName !== null && templateName !== "")) {
    setValue("templateName", "")
  }

  if (editPage && templateFlag && templateStatus) {
    isEditable = true;
  }
  
  useEffect(() => {
    if (!fromCloneOrTemplate && (id === undefined || id === null || id === '')) {
      fetchAceHWManifestDefaultValueFromUserProfile(setValue, securedSendRequest);
    }
  }, []);

  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  // Set default message function for multistep
  useEffect(() => {
    const formData = new FormData();
    formData.append('manifestId', id);

    let config = {}

    securedSendRequest.execute('POST', AceHighwayShipmentApiUrls.GET_COUNT_BY_STATUS, formData,
      (response) => {
        let data = response.data
        if (status === '1009') {
          var shipmentExists = data === undefined || data === null || data.length === 0 ? false : true
          var shipmentAC = data !== undefined && data !== null && data.length !== 0 && data.some(s => s.STATUS !== '1000') ? false : true

          if (!shipmentExists || shipmentAC) {
            console.log("Auto change message function to Confirm Manifest")
            setValue("messageFunction", '6')
          } else if (shipmentExists) {
            console.log("Auto change message function to Modify Manifest")
            setValue("messageFunction", '4')
          }
        }
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }, []);

  // Set default message function on create manifest
  useEffect(() => {
    if (paxLSTFlag && status === '1005') {
      console.log("Auto change message function to Multistep Add")
      setValue("messageFunction", '2')

    } else if (!paxLSTFlag) {
      if (!status || status === '1005') {
        console.log("Auto change message function to Singlestep Add")
        setValue("messageFunction", '22')

      }
    }
  }, [])

  useEffect(() => {
    if (paxLSTFlag) {
      setRequirePaxlst(true)
    }
  }, [])

  useEffect(() => {
    Promise.all([
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'PRO_AUTO_INB_NUM' }],
        undefined, 'code'
      )
    ]).then(([propagateAutoInbondNumber]) => {
      Object.keys(propagateAutoInbondNumber).forEach(e => {
        compilePropagateInbondNumbers(propagateAutoInbondNumber);
      })
    }
    )
  }, [user])

  useEffect(() => {
    const result = [licensePlate1, licensePlate2, licensePlate3, licensePlate4].reduce((acc, curr) => {
      acc += curr.filter((value) => (value !== undefined && value !== null && value !== '')).length > 0 ? 1 : 0
      return acc
    }, 0)

    setLicensePlateCount(result === 0 ? 1 : result)
  }, [])

  useWatch({ name: "fileForUserId" })
  useWatch({ name: "fileForUserLoginId" })
  useWatch({ name: "fileForUserPartyId" })
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function compilePropagateInbondNumbers(propagateAutoInbondNumber) {
    var jsonArray = [];
    if (propagateAutoInbondNumber) {
      Object.entries(propagateAutoInbondNumber).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        if (jsonObject.code === user.loginId) {
          console.log("matched propagate user " + JSON.stringify(user.loginId));
          setValue("autoInbondNumUser", true);
        }
        jsonArray.push(jsonObject)
      })
    }
    setPropagateAutoInbondNumberUser(jsonArray)
  }

  const translatedTextsObject = {
    id: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'id'),
    createdDate: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'createdDate'),
    updatedDate: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'updatedDate'),
    carrierScac: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'carrierScac'),
    transitIndicator: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'transitIndicator'),
    firstExpectedPort: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'firstExpectedPort'),
    status: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'status'),
    templateName: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'templateName'),
  }

  const memoedTripInformationSection = useMemo(() => (
    <TripInformationSection disabled={disabled} shouldHideMap={shouldHideMap} user={user} id={id} editPage={editPage} trigger={trigger} />
  ), [user])

  const memoedTruckSection = useMemo(() => (
    <TruckSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification}
      licensePlateCount={licensePlateCount}
      setLicensePlateCount={setLicensePlateCount}
    />
  ), [licensePlateCount])

  const memoedEquipmentInfoSection = useMemo(() => (
    <EquipmentInfoSection showNotification={showNotification} />
  ), [])

  const memoedCrewInformationSection = useMemo(() => (
    <CrewInformationSection setRequirePaxlst={setRequirePaxlst} requirePaxlst={requirePaxlst} setShowDriver={setShowDriver} showDriver={showDriver} />
  ), [requirePaxlst, showDriver])

  const memoedPaxlstInformationSection = useMemo(() => (
    <PaxlstInformationSection showNotification={showNotification} />
  ), [])

  const memoedInsuranceInfoSection = useMemo(() => (
    <InsuranceInfoSection disabled={disabled} />
  ), [])

  const memoedMessageFunctionSection = useMemo(() => (
    <MessageFunctionSection disabled={disabled} />
  ), [])

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Card variant='outlined' style={{ padding: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm='auto'>
                <CngButton
                  onClick={() => setManifestTemplateDialog(true)}
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}
                  color='secondary'
                >
                  Use template
                </CngButton>
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <CngCheckboxField
                      label={
                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'templateFlag')}
                        </Typography>
                      }
                      name='templateFlag'
                      disabled={disabled || isEditable}
                      onChange={(e) => setValue('templateFlag', e.target.checked)}
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                    />
                  </Grid>
                  <Grid item xs>
                    <CngTextField
                      required
                      name='templateName'
                      inputProps={{ maxLength: 50 }}
                      disabled={!templateFlag || disabled || isEditable}
                      onBlur={(e) => { setValue('templateName', e.target.value.toUpperCase().trim()) }}
                      placeholder={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'templateName')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {memoedTripInformationSection}
        {memoedTruckSection}
        {memoedEquipmentInfoSection}
        {memoedCrewInformationSection}
        {requirePaxlst && memoedPaxlstInformationSection}
        {memoedInsuranceInfoSection}
        {memoedMessageFunctionSection}

        <TemplateSelectDialogWithOption
          manifestTemplateDialog={manifestTemplateDialog}
          setManifestTemplateDialog={setManifestTemplateDialog}
          showNotification={showNotification}
          translatedTextsObject={translatedTextsObject}
          setLicensePlateCount={setLicensePlateCount}
          setValue={setValue}
          reset={reset}
          trigger={trigger}
        />
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;

  localData.truckId = localData.conveyanceId

  localData.createdDate = moment(localData.createdDate).tz("Canada/Eastern").format();
  localData.updatedDate = moment(localData.updatedDate).tz("Canada/Eastern").format();
  localData.submittedDate = moment(localData.submittedDate).tz("Canada/Eastern").format();
  localData.responseDate = moment(localData.responseDate).tz("Canada/Eastern").format();

  localData.arrivalTime = moment(localData.arrivalDate).tz("Canada/Eastern").format('HH:mm');
  localData.arrivalDate = moment(localData.arrivalDate).tz("Canada/Eastern").format('YYYY-MM-DD');

  localData.equipmentInfo.forEach((datum) => {
    datum.createdDate = moment(datum.createdDate).tz("Canada/Eastern").format();
    datum.updatedDate = moment(datum.updatedDate).tz("Canada/Eastern").format();
    datum.submittedDate = moment(datum.submittedDate).tz("Canada/Eastern").format();

    if (datum.aceFlag == "1") {
      datum.aceFlag = true
    } else {
      datum.aceFlag = false
    }

    if (datum.iitCode1 == "EC") {
      datum.iitCode1 = true
    } else {
      datum.iitCode1 = false
    }

    if (datum.iitCode2 == "EI") {
      datum.iitCode2 = true
    } else {
      datum.iitCode2 = false
    }

    if (datum.iitCode3 == "MC") {
      datum.iitCode3 = true
    } else {
      datum.iitCode3 = false
    }

    if (datum.iitCode4 == "MI") {
      datum.iitCode4 = true
    } else {
      datum.iitCode4 = false
    }
  });
  localData.insuranceInfo.forEach((datum) => {
    datum.createdDate = moment(datum.createdDate).tz("Canada/Eastern").format();
    datum.updatedDate = moment(datum.updatedDate).tz("Canada/Eastern").format();
    datum.submittedDate = moment(datum.submittedDate).tz("Canada/Eastern").format();
  });
  localData.partyInfo.forEach((datum) => {
    datum.createdDate = moment(datum.createdDate).tz("Canada/Eastern").format();
    datum.updatedDate = moment(datum.updatedDate).tz("Canada/Eastern").format();
    datum.submittedDate = moment(datum.submittedDate).tz("Canada/Eastern").format();

    if (datum.aceIdFlag == "1") {
      datum.aceIdFlag = true
    } else {
      datum.aceIdFlag = false
    }

    if (datum.fastCardFlag == "1") {
      datum.fastCardFlag = true
    } else {
      datum.fastCardFlag = false
    }

    if (datum.hazmatEndorsement == "1") {
      datum.hazmatEndorsement = true
    } else {
      datum.hazmatEndorsement = false
    }

  });

  if (localData.templateFlag == "1") {
    localData.templateFlag = true
  } else {
    localData.templateFlag = false
  }

  if (localData.aceFlag == "1") {
    localData.aceFlag = true
  } else {
    localData.aceFlag = false
  }

  if (localData.iitCode1 == "EC") {
    localData.iitCode1 = true
  } else {
    localData.iitCode1 = false
  }

  if (localData.iitCode2 == "EI") {
    localData.iitCode2 = true
  } else {
    localData.iitCode2 = false
  }

  if (localData.iitCode3 == "MC") {
    localData.iitCode3 = true
  } else {
    localData.iitCode3 = false
  }

  if (localData.iitCode4 == "MI") {
    localData.iitCode4 = true
  } else {
    localData.iitCode4 = false
  }

  localData.paxLSTInfo.forEach((datum) => {
    datum.createdDate = moment(datum.createdDate).tz("Canada/Eastern").format();
    datum.updatedDate = moment(datum.updatedDate).tz("Canada/Eastern").format();
    datum.submittedDate = moment(datum.submittedDate).tz("Canada/Eastern").format();
    datum.responseDate = moment(datum.responseDate).tz("Canada/Eastern").format();

    if (datum.dob !== null)
      datum.dob = moment(datum.dob).tz("Canada/Eastern").format('YYYY-MM-DD');

    if (datum.aceId !== undefined && datum.aceId !== null && datum.aceId !== '') {
      datum.aceIdFlag = true
    } else {
      datum.aceIdFlag = false
    }

    if (datum.customProximityId !== undefined && datum.customProximityId !== null && datum.customProximityId !== '') {
      datum.fastCardFlag = true
    } else {
      datum.fastCardFlag = false
    }

    if (datum.hazmatEndorsement == "1") {
      datum.hazmatEndorsement = true
    } else {
      datum.hazmatEndorsement = false
    }
  });

  if (localData.paxLSTInfo.length > 0 || localData.messageIndicator === "MULTI") {
    localData.paxLSTFlag = true
  }

  return localData;
}

function toServerDataFormat(localData) {
  localData.conveyanceId = localData.truckId //for save into manifest

  localData.submittedDate = DateTimeFormatter.toServerDateTime(
    localData.submittedDate
  );

  var arrivalDateTime = localData.arrivalDate + ' ' + localData.arrivalTime;
  localData.arrivalDateTimeString = moment(arrivalDateTime).format('DD/MM/YYYY HHmm');
  localData.arrivalDate = moment(arrivalDateTime).format('YYYY-MM-DDTHH:mmZ');

  localData.responseDate = DateTimeFormatter.toServerDateTime(
    localData.responseDate
  );
  localData.equipmentInfo.forEach((datum) => {

    if (datum.iitCode1 == true) {
      datum.iitCode1 = "EC"
    } else {
      datum.iitCode1 = ""
    }

    if (datum.iitCode2 == true) {
      datum.iitCode2 = "EI"
    } else {
      datum.iitCode2 = ""
    }

    if (datum.iitCode3 == true) {
      datum.iitCode3 = "MC"
    } else {
      datum.iitCode3 = ""
    }

    if (datum.iitCode4 == true) {
      datum.iitCode4 = "MI"
    } else {
      datum.iitCode4 = ""
    }
  });
  localData.partyInfo.forEach((datum) => {

    if (datum.hazmatEndorsement == true) {
      datum.hazmatEndorsement = "1"
    } else {
      datum.hazmatEndorsement = "0"
    }
  });


  if (localData.iitCode1 == true) {
    localData.iitCode1 = "EC"
  } else {
    localData.iitCode1 = ""
  }
  if (localData.iitCode2 == true) {
    localData.iitCode2 = "EI"
  } else {
    localData.iitCode2 = ""
  }
  if (localData.iitCode3 == true) {
    localData.iitCode3 = "MC"
  } else {
    localData.iitCode3 = ""
  }
  if (localData.iitCode4 == true) {
    localData.iitCode4 = "MI"
  } else {
    localData.iitCode4 = ""
  }

  localData.paxLSTInfo.forEach((datum) => {
    datum.submittedDate = DateTimeFormatter.toServerDateTime(
      datum.submittedDate
    );
    datum.responseDate = DateTimeFormatter.toServerDateTime(
      datum.responseDate
    );

    if (datum.dob !== null)
      datum.dobString = moment(datum.dob).format('YYYY-MM-DD');

    if (datum.hazmatEndorsement == true) {
      datum.hazmatEndorsement = "1"
    } else {
      datum.hazmatEndorsement = "0"
    }

    if (datum.aceId === '') {
      datum.aceId = null
    }

    if (datum.customProximityId === '') {
      datum.customProximityId = null
    }

    datum.scac = localData.carrierScac

  });
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties