import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { Box, Divider, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaAceStateCodeAutoCompleteField, NaCrewMasterAutoCompleteField, NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { ACEHighwayTranslationContext } from '../contexts/ACEHighwayTranslationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AciAceCrewTypeMapping } from 'src/common/AciAceCrossMapping'
import CrewMasterApiUrls from '../../../apiUrls/CrewMasterApiUrls'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import TinyChip from 'src/components/aciacehighway/TinyChip'


const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngSelectField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  manifestHeaderId: '',
  shipmentHeaderId: '',
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  submittedBy: '',
  submittedDate: '',
  version: '',
  dob: null,
  gender: '',
  partyType: '',
  partyIdentifier: '',
  partyIdentifierCode: '',
  partyIdentifierValue: '',
  name1: '',
  name2: '',
  address1: '',
  address2: '',
  address3: '',
  cityName: '',
  postalCode: '',
  country: 'US',
  state: '',
  telNo: '',
  email: '',
  ctpatInd: '',
  hazmatEndorsement: '',
  licenseNo: '',
  licenseCountry: '',
  licenseState: '',
  cdlNo: '',
  cdlCountry: '',
  cdlState: '',
  routingCode: '',
  brokerDownloadRefNo: '',
  portOfLoading: '',
  firstName: '',
  middleName: '',
  lastName: '',
  aceIdFlag: true,
  aceId: '',
  fastCard: '',
  crewId: '',
  portCode: '',
  fastCardFlag: false,
  saveCrewFlag: false,
  phoneNo: '',
  faxNo: '',
  saveFlag: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, shouldHideMap, index }) {
  const { getValues, setValue, watch, trigger, reset } = useFormContext();
  const { getTranslatedText } = useContext(ACEHighwayTranslationContext)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `partyInfo.${index}.${field}` : field
  }, [])

  const [lookups, setLookups] = useState(null)
  const [crewTemplateDialog, setCrewTemplateDialog] = useState(false)
  const [locTemplateDialog, setLocTemplateDialog] = useState(false)

  const aceIdFlag = watch(getFieldName('aceIdFlag'))
  const fastCardFlag = watch(getFieldName('fastCardFlag'))
  const partyType = watch(getFieldName('partyType'))
  const saveCrewFlag = watch(getFieldName('saveCrewFlag'))
  const saveFlag = watch(getFieldName('saveFlag'))

  // useEffect(() => {
  //   if (aceIdFlag) {
  //     setValue(getFieldName('fastCard'), '')
  //   } else if (fastCardFlag) {
  //     setValue(getFieldName('aceId'), '')
  //   }
  // }, [aceIdFlag, fastCardFlag])

  // Get Crew Type
  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_CREW_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([partyType]) => { setLookups({ partyType }) })
  }, [])

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue(getFieldName('fileForUserId'), fileForUserDetails.fileForUserId)
      setValue(getFieldName('fileForUserLoginId'), fileForUserDetails.fileForUserLoginId)
      setValue(getFieldName('fileForUserPartyId'), fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function handleApplyCrewTemplate(template) {
    if (!template) {
      reset()
      return
    }

      const {
        crewId,
        crewType,
        customProximityId,
        aceId,
        firstName,
        middleName,
        lastName,
        email,
        telNo
      } = template

    setValue(getFieldName('crewId'), crewId || '', { shouldDirty: true })
    setValue(getFieldName('fetchCrew'), crewId || '', { shouldDirty: true })
    setValue(getFieldName('partyType'), AciAceCrewTypeMapping('ACE', crewType) || '', { shouldDirty: true })

    if (aceId) {
      setValue(getFieldName('aceIdFlag'), true, { shouldDirty: true })
      setValue(getFieldName('fastCardFlag'), false, { shouldDirty: true })
    } else if (customProximityId) {
      setValue(getFieldName('fastCardFlag'), true, { shouldDirty: true })
      setValue(getFieldName('aceIdFlag'), false, { shouldDirty: true })
    }
    setValue(getFieldName('fastCard'), customProximityId || '', { shouldDirty: true })
    setValue(getFieldName('aceId'), aceId || '', { shouldDirty: true })
    setValue(getFieldName('firstName'), firstName || '', { shouldDirty: true })
    setValue(getFieldName('middleName'), middleName || '', { shouldDirty: true })
    setValue(getFieldName('lastName'), lastName || '', { shouldDirty: true })
    setValue(getFieldName('email'), email || '', { shouldDirty: true })
    setValue(getFieldName('telNo'), telNo || '', { shouldDirty: true })

    setValue(getFieldName('partyIdentifier'), '', { shouldDirty: true })
    setValue(getFieldName('partyDropDown'), '', { shouldDirty: true })
    setValue(getFieldName('name1'), '', { shouldDirty: true })
    setValue(getFieldName('address1'), '', { shouldDirty: true })
    setValue(getFieldName('address2'), '', { shouldDirty: true })
    setValue(getFieldName('address3'), '', { shouldDirty: true })
    setValue(getFieldName('cityName'), '', { shouldDirty: true })
    setValue(getFieldName('postalCode'), '', { shouldDirty: true })
    setValue(getFieldName('state'), '', { shouldDirty: true })

      trigger()
  }

  function handleApplyDropOffLocationTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      partyidn,
      partyname1,
      address1,
      address2,
      address3,
      cityname,
      postalcode,
      statecode
    } = template

    setValue(getFieldName('partyIdentifier'), partyidn || '', { shouldDirty: true })
    setValue(getFieldName('partyDropDown'), partyidn || '', { shouldDirty: true })
    setValue(getFieldName('name1'), partyname1 || '', { shouldDirty: true })
    setValue(getFieldName('address1'), address1 || '', { shouldDirty: true })
    setValue(getFieldName('address2'), address2 || '', { shouldDirty: true })
    setValue(getFieldName('address3'), address3 || '', { shouldDirty: true })
    setValue(getFieldName('cityName'), cityname || '', { shouldDirty: true })
    setValue(getFieldName('postalCode'), postalcode || '', { shouldDirty: true })
    setValue(getFieldName('state'), statecode || '', { shouldDirty: true })

    trigger()
  }

  // Party Section template table
  const dropOffLocPartyColumns = [
    {
      field: 'partytype',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      field: 'partyidn',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyIdentifier')
    },
    {
      title: `${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'name1')}/${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'name2')}`,
      render: (data) => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'country')
    },
    {
      field: 'statecode',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'state')
    },
  ]

  // Crew Section template table
  const columns = [
    {
      title: `${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewId')} & ${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceId')} / ${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fastCard')}`,
      render: (data) => {
        const { aceId, crewId, customProximityId } = data

        return (
          <Box>
            <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
              {crewId}
            </Typography>
            {(aceId || customProximityId) && (
              <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
                {aceId ? aceId : customProximityId}
                <TinyChip label={aceId ? 'ACE ID' : 'FAST Card'} variant='outlined' />
              </Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: 'crewType',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.crewType)}
        </Typography>
    },
    {
      field: 'firstName',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'firstName')
    },
    {
      field: 'middleName',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'middleName')
    },
    {
      field: 'lastName',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'lastName')
    },
    {
      field: 'state',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'state')
    },
  ]

  return (
    <>
      <Grid container spacing={1}>
        <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.crewType}>
          <CngCodeMasterAutocompleteField
            required
            name={getFieldName('partyType')}
            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewType')}
            disabled={disabled}
            codeType='ACE_HW_CREW_TYPE'
            key={partyType}
            size='small'
            onChange={(value) => setValue(getFieldName('partyType'), value)}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.saveCrewFlag}>
          <CngCheckboxField
            label={
              <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'saveCrewFlag')}
              </Typography>
            }
            name={getFieldName('saveCrewFlag')}
            onChange={(e) => setValue(getFieldName('saveCrewFlag'), e.target.checked)}
            size='small'
            style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.crewId}>
          <CngTextField
            name={getFieldName('crewId')}
            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewId')}
            disabled={disabled || !saveCrewFlag}
            inputProps={{ maxLength: 15 }}
            InputLabelProps={{ shrink: getValues(getFieldName('crewId')) }}
            onBlur={(e) => { setValue(getFieldName('crewId'), e.target.value.toUpperCase()) }}
            size='small'
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={3}>
          <NaCrewMasterAutoCompleteField
            name={getFieldName('fetchCrew')}
            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fetchCrew')}
            disabled={disabled}
            onChange={(_, options) => handleApplyCrewTemplate(options.data)}
            lookupProps={{
              filters: [{
                field: 'partyId',
                operator: EQUAL,
                value: FileForUserGetPartyId()
              }]
            }}
            size='small'
            fullWidth
            disableClearable
            textFieldProps={{
              InputProps: {
                customEndAdornment: () => (
                  <InputAdornment position='end' style={{ marginTop: -16 }}>
                    <IconButton onClick={(event) => {
                      event.stopPropagation()
                      setCrewTemplateDialog(true)
                    }}>
                      <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                        <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                      </Box>
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
            forcePopupIcon={false}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={3}>
          <CngSelectField
            items={[
              { text: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceId'), value: 'aceIdFlag' },
              { text: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fastCard'), value: 'fastCardFlag' },
            ]}
            label='Document type'
            onChange={(event) => {
              if (event.target.value === 'aceIdFlag') {
                setValue(getFieldName('aceIdFlag'), true)
                setValue(getFieldName('fastCardFlag'), false)
              } else if (event.target.value === 'fastCardFlag') {
                setValue(getFieldName('fastCardFlag'), true)
                setValue(getFieldName('aceIdFlag'), false)
                setValue(getFieldName('aceId'), '')
              }

              setValue(getFieldName('partyIdentifier'), '')
              setValue(getFieldName('partyDropDown'), '')
              setValue(getFieldName('address1'), '')
              setValue(getFieldName('address2'), '')
              setValue(getFieldName('address3'), '')
              setValue(getFieldName('cityName'), '')
              setValue(getFieldName('postalCode'), '')
              setValue(getFieldName('state'), '')
            }}
            size='small'
            value={aceIdFlag ? 'aceIdFlag' : fastCardFlag ? 'fastCardFlag' : null}
          />
        </CngGridItem>


        {aceIdFlag && (
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.aceId}>
            <CngTextField
              required
              name={getFieldName('aceId')}
              label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceId')}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        )}

        {fastCardFlag && (
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.fastCard}>
            <CngTextField
              required
              name={getFieldName('fastCard')}
              label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fastCard')}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        )}

        <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.firstName}>
          <CngTextField
            name={getFieldName('firstName')}
            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'firstName')}
            disabled={disabled}
            size='small'
            onBlur={(e) => { setValue(getFieldName('firstName'), e.target.value.toUpperCase()) }}
            InputLabelProps={{ shrink: getValues(getFieldName('firstName')) }}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.middleName}>
          <CngTextField
            name={getFieldName('middleName')}
            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'middleName')}
            disabled={disabled}
            size='small'
            onBlur={(e) => { setValue(getFieldName('middleName'), e.target.value.toUpperCase()) }}
            InputLabelProps={{ shrink: getValues(getFieldName('middleName')) }}
          />
        </CngGridItem>

        <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.lastName}>
          <CngTextField
            name={getFieldName('lastName')}
            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'lastName')}
            disabled={disabled}
            size='small'
            onBlur={(e) => { setValue(getFieldName('lastName'), e.target.value.toUpperCase()) }}
            InputLabelProps={{ shrink: getValues(getFieldName('lastName')) }}
          />
        </CngGridItem>

        {partyType === 'VW' &&
          <>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.email}>
              <CngTextField
              name={getFieldName('email')}
              label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'email')}
              disabled={disabled}
              size='small'
              onBlur={(e) => { setValue(getFieldName('email'), e.target.value.toUpperCase()) }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.telNo}>
            <CngTextField
                name={getFieldName('telNo')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'telNo')}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
          </>
        }

        {aceIdFlag && (
          <CngGridItem xs={12}>

            <Box paddingTop={2} paddingBottom={2}>
              <Grid alignItems='center' container spacing={2}>
                <CngGridItem xs={12} sm='auto'>
                  <Typography variant='body2'>{getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyInfoAddressTitle')}</Typography>
                </CngGridItem>
                <CngGridItem xs={12} sm><Divider /></CngGridItem>
              </Grid>
            </Box>

            <Grid container spacing={1}>
              <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.saveFlag}>
                <CngCheckboxField
                  label={
                    <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                      {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'saveFlag')}
                    </Typography>
                  }
                  name={getFieldName('saveFlag')}
                  onChange={(e) => setValue(getFieldName('saveFlag'), e.target.checked)}
                  size='small'
                  style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.partyIdentifier}>
                <CngTextField
                  name={getFieldName('partyIdentifier')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyIdentifier')}
                  disabled={disabled || !saveFlag}
                  inputProps={{ maxLength: 15 }}
                  onBlur={(e) => { setValue(getFieldName('partyIdentifier'), e.target.value.toUpperCase()) }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4}>
                <NaAcePartyMasterAutoCompleteField
                  name={getFieldName('partyDropDown')}
                  inputProps={{ maxLength: 35 }}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyDropDown')}
                  disabled={disabled}
                  onChange={(_, options) => { handleApplyDropOffLocationTemplate(options.data) }}
                  lookupProps={{
                    filters: [{
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    },
                    {
                      field: 'partytype',
                      operator: IN,
                      value: ['OS', 'CN', 'NI', 'GC', 'CB', 'IM', 'CZ', 'SH']
                    }]
                  }}
                  size='small'
                  disableClearable
                  fullWidth
                  forcePopupIcon={false}
                  textFieldProps={{
                    InputProps: {
                      customEndAdornment: () => (
                        <InputAdornment position='end' style={{ marginTop: -16 }}>
                          <IconButton onClick={(event) => {
                            event.stopPropagation()
                            setLocTemplateDialog(true)
                          }}>
                            <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                              <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.name}>
                <CngTextField
                  required={aceIdFlag && saveFlag}
                  name={getFieldName('name1')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'name')}
                  disabled={disabled}
                  onBlur={(e) => {
                    setValue(getFieldName('name1'), e.target.value.toUpperCase().trim())

                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address1}>
                <CngTextField
                  name={getFieldName('address1')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'address1')}
                  disabled={disabled}
                  required={partyType === 'VW'}
                  inputProps={{ maxLength: 35 }}
                  onBlur={(e) => {
                    setValue(getFieldName('address1'), e.target.value.toUpperCase())

                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address2}>
                <CngTextField
                  name={getFieldName('address2')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'address2')}
                  disabled={disabled}
                  inputProps={{ maxLength: 35 }}
                  onBlur={(e) => {
                    setValue(getFieldName('address2'), e.target.value.toUpperCase())

                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address3}>
                <CngTextField
                  name={getFieldName('address3')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'address3')}
                  disabled={disabled}
                  inputProps={{ maxLength: 35 }}
                  onBlur={(e) => {
                    setValue(getFieldName('address3'), e.target.value.toUpperCase())

                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.cityName}>
                <CngTextField
                  name={getFieldName('cityName')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'cityName')}
                  disabled={disabled}
                  required={partyType === 'VW'}
                  inputProps={{ maxLength: 35 }}
                  onBlur={(e) => {
                    setValue(getFieldName('cityName'), e.target.value.toUpperCase())

                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.postalCode}>
                <CngTextField
                  name={getFieldName('postalCode')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'postalCode')}
                  disabled={disabled}
                  required={partyType === 'VW'}
                  inputProps={{ maxLength: 10 }}
                  onBlur={(e) => {
                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.state}>
                <NaAceStateCodeAutoCompleteField
                  name={getFieldName('state')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'state')}
                  disabled={disabled}
                  countryCode={'US'}
                  required={partyType === 'VW'}
                  size='small'
                  onChange={(e) => {
                    if (!saveFlag) {
                      setValue(getFieldName('partyIdentifier'), '')
                      setValue(getFieldName('partyDropDown'), '')
                    }
                  }}
                  key={getValues(getFieldName('state'))}
                />
              </CngGridItem>
            </Grid>
          </CngGridItem>
        )}

      </Grid>

      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'crewId', direction: 'ASC' }]}
        fetch={{ url: CrewMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        maxWidth='lg'
        onApplyTemplate={handleApplyCrewTemplate}
        onClose={() => setCrewTemplateDialog(false)}
        open={crewTemplateDialog}
        search={{ field: 'crewId', operator: LIKE }}
        title='Crew template'
      />

      <TemplateSelectDialog
        columns={dropOffLocPartyColumns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: ['OS', 'CN', 'NI', 'GC', 'CB', 'IM', 'CZ', 'SH'] },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyDropOffLocationTemplate}
        onClose={() => setLocTemplateDialog(false)}
        open={locTemplateDialog}
        search={{ field: 'partyidn', operator: LIKE }}
        title='Drop Off Location Party template'
      />
    </>
  )
}

const PartyInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyInfoFormProperties
