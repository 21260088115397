import React from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import StatusNotificationFormProperties from './StatusNotificationFormProperties'
import Box from '@material-ui/core/Box'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function StatusNotificationDialog(props) {
  const {
    open,
    onAddStatusNotification,
    onClose,
    onEditStatusNotification,
    manifestId,
    showNotification,
    statusNotification,
    type,
    isABI
  } = props

  const { initialValues, makeValidationSchema } =
    StatusNotificationFormProperties.formikProps

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.TITLE
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_BUTTON
    )

    return { title, saveButton }
  }

  return (
    <CngDialog
      dialogContent={
        <CngForm
          fieldLevel='form'
          formikProps={{
            initialValues: {
              ...(statusNotification || initialValues)
            },
            makeValidationSchema: makeValidationSchema,
            onSubmit: (data) => {
              if(isABI){
                data.abiInbondId= manifestId
              }else{
                data.shipmentId= manifestId
              }
              data.mobileNum = data.mobileNum && data.mobileNum.substring(0, 1) !== '+' ? '+'+data.mobileNum : data.mobileNum
              if (type === 'EDIT') {
                onEditStatusNotification(data)
              } else {
                delete data.id
                console.log("add"+JSON.stringify(data))
                onAddStatusNotification(data)
              }
              onClose()
            }
          }}
          formState={FormState.COMPLETED}
          renderBodySection={(labelMap, shouldHideMap) => (
            <StatusNotificationFormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              isABI={isABI}
            />
          )}
          renderButtonSection={() => (
            <Box display='flex' justifyContent='flex-end'>
              <CngButton color='primary' size='medium' type='submit'>
                {translatedTextsObject.saveButton}
              </CngButton>
            </Box>
          )}
        />
      }
      dialogTitle={translatedTextsObject.title}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default StatusNotificationDialog
