import makeValidationSchema from './StatusNotificationMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import { Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  shipmentId:"",
  name: "",
  snType: "ALL",
  shipmentControlNum: "",
  email: "",
  mobileNum: "",
  faxNum: "",
  abiInbondId: null
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  manifestId,
  isABI
}) {
  const { setValue, getValues, reset } = useFormContext();
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  let manifestIden = getValues("shipmentId");
  console.log("manifestIDDDDD",manifestIden)

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);
  function makeTranslatedTextsObject() {
    let statusNotification = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.TITLE
    )
    let name = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.NAME
    )
    let snType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.SN_TYPE
    )
    let shipmentControlNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.SHIPMENT_CONTROL_NUM
    )
    let email = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.EMAIL
    )
    let mobileNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.MOBILE_NUM
    )
    let faxNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.FAX_NUM
    )

    return {
      statusNotification,
      name,
      snType,
      shipmentControlNum,
      email,
      mobileNum,
      faxNum
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
        <CngTextField
          required
          name='name'
          label={translatedTextsObject.name}
          disabled={disabled}
          onChange={(e) => {
            setValue('name', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snType}>
        {isABI ? (
          <CngSelectField
            required
            name='snType'
            label={translatedTextsObject.snType}
            disabled={disabled}
            items={[{ text: 'ALL', value: 'ALL' }]}
          />
        ) : (
          <CngCodeMasterAutocompleteField
            required
            name='snType'
            label={translatedTextsObject.snType}
            disabled={disabled}
            codeType='ACEHW_STATUS_NOTIFI_TYPE'
          />
        )}
      </CngGridItem>
      {!isABI && (
        <CngGridItem
          xs={12}
          sm={6}
          shouldHide={shouldHideMap.shipmentControlNum}
        >
          <CngLookupAutocompleteField
            name='shipmentControlNum'
            label={translatedTextsObject.shipmentControlNum}
            lookupProps={{
              filters: [
                { field: 'headerId', operator: EQUAL, value: manifestIden }
              ],
              label: 'scn',
              url: AceHighwayShipmentApiUrls.SN_SCN_SEARCH,
              value: 'scn'
            }}
          />
        </CngGridItem>
      )}
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
        <CngTextField
          required
          name='email'
          label={translatedTextsObject.email}
          disabled={disabled}
          onChange={(e) => {
            setValue('email', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mobileNum}>
        <CngTextField
          name='mobileNum'
          label={translatedTextsObject.mobileNum}
          disabled={disabled}
          onChange={(e) => {
            setValue('mobileNum', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
        />
      </CngGridItem>
      {!isABI && (
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.faxNum}>
          <CngTextField
            name='faxNum'
            label={translatedTextsObject.faxNum}
            disabled={disabled}
            onChange={(e) => {
              setValue('faxNum', e.target.value.toUpperCase(), {
                shouldValidate: true
              })
            }}
          />
        </CngGridItem>
      )}
    </Grid>
	)
}

const StatusNotificationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default StatusNotificationFormProperties
