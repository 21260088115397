import React, { useState } from 'react'
import { constants, useTranslation,useServices } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import AceHighwayShipmentKeys from '../../../../constants/locale/key/AceHighwayShipment'
import AceHighwayShipmentApiUrls from '../../../../apiUrls/AceHighwayShipmentApiUrls'
import QuickGlanceTable from '../../../../components/aciacehighway/QuickGlanceTable'
import Table from '../../../../components/aciacehighway/Table'
import ShipmentDialog from '../shipment/ShipmentDialog'
import { Box, Chip, Typography } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TinyChip from '../../../../components/aciacehighway/TinyChip'

const {
  filter: { EQUAL, IN, LIKE }
} = constants

function ShipmentTable(props) {
  const { disableView = false, getCountryStateLabel, getLookupValue, manifestId, manifestPort, noOfShipment, redirectUrl, redirectData, additionalButtonLabel } = props
  const {securedSendRequest } = useServices();
  const [shipmentDialog, setShipmentDialog] = useState({
    open: false,
    shipment: null
  })
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let scn = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SCN
    )
    let shipmentType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPMENT_TYPE
    )
    let acquittalNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ACQUITTAL_NO
    )
    let shipperName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CONSIGNEE_NAME
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.STATUS
    )
    let customsBrokerName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CUSTOMS_BROKER_NAME
    )
    let brokerEntryNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.BROKER_ENTRY_NO
    )
    let customsBrokerContact = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CUSTOMS_BROKER_CONTACT
    )

    return {
      scn,
      shipmentType,
      acquittalNo,
      shipperName,
      consigneeName,
      status,
      customsBrokerName,
      brokerEntryNo,
      customsBrokerContact
    }
  }

  const columns = [
    {
      field: 'scn',
      title: `${translatedTextsObject.scn} / ${translatedTextsObject.shipmentType} / ${translatedTextsObject.acquittalNo}`,
      render: (data) => {
        return (
        <Box>
          <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
              {data.scn}
          </Typography>
          <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
            {[
              <Typography key='shipmentType' component='span' variant='inherit'>
                {data.shipmentType}
              </Typography>,
              data.acquittalNo && (
                <Typography key='acquittalNo' component='span' variant='inherit'>
                  {data.acquittalNo}
                </Typography>
              )
            ].reduce((acc, curr, index) => {
              return acc !== null
                ? curr
                  ? [...acc, <Typography key={index} component='span' variant='inherit' style={{ margin: '0 4px' }} >&middot;</Typography>, curr]
                  : acc
                : [curr]
            }, null)}
          </Typography>
        </Box>
        )
      }
    },
    {
      field: 'shipperName',
      sortKey: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      sortKey: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      field: 'brokerEntryNo',
      sortKey: 'brokerEntryNo',
      title: translatedTextsObject.brokerEntryNo
    },
    {
      field: 'customsBrokerName',
      sortKey: 'customsBrokerName',
      title: translatedTextsObject.customsBrokerName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        if (data.status == '1000' && data.shipmentType == 'REGULAR' && !data.brokerEntryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            <Chip
              label={status.label}
              size='small'
              style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
            />

            {('REGULAR' == data.shipmentType && data.eventLocation && data.eventLocation != manifestPort) &&
              <Chip key='first' label={"Port Mismatch"} size='small'
                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
              />
            }
          </Box>
        )
      }
    },
    {
      title: translatedTextsObject.customsBrokerContact,
      render: (data) => {
        let content = [
          console.log("data.email", data.email)
        ]

        // <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
        if (data.phoneNo != '' && data.phoneNo != undefined) {
          content.push(
            <Typography variant='inherit'>
              <FontAwesomeIcon icon={['fa-solid', 'fa-phone']} />
              <TinyChip label={data.phoneNo} variant='outlined' style={{ fontSize: 12 }} />
            </Typography>
          )
        }
        if (data.faxNo != '' && data.faxNo != undefined) {
          content.push(
            <Typography variant='inherit'>
              <FontAwesomeIcon icon={['fa-solid', 'fa-fax']} />
              <TinyChip label={data.faxNo} variant='outlined' style={{ fontSize: 12 }} />
            </Typography>
          )
        }
        if (data.email != '' && data.email != undefined) {
          content.push(
            <Typography variant='inherit'>
              <FontAwesomeIcon icon={['fa-solid', 'fa-envelope']} />
              <TinyChip label={data.email} variant='outlined' style={{ fontSize: 12 }} />
            </Typography>
          )
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap'>
            {content}
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <QuickGlanceTable
        disableView={disableView}
        columns={columns}
        compact
        fetch={{ url: AceHighwayShipmentApiUrls.GET_SHIPMENT_QUICK_GLANCE }}
        fetchFilters={[
          { field: 'headerId', operator: EQUAL, value: manifestId }
        ]}

        sortConfig={{ type: 'column', defaultField: 'createdDate', defaultDirection: 'DESC' }}
        {...(!disableView && {
          onRowClick: (rowData) => {
            
            const data = {
              baseFilterDTO: {
                filterType:"AND",
                filterProperties: [{ fieldName: "id", operatorType: "EQUAL", value1: rowData.id }],
              sortProperties: []
              }
            }
            const config = {}

            securedSendRequest.execute(
              'POST',
              AceHighwayShipmentApiUrls.GET,
              data,
              (response) => {
                let shipmentData;
                let data = response.data;
                
                if(data!=null && data!=undefined){
                  shipmentData = data.content[0]
                }
              
                setShipmentDialog({ open: true, shipment: shipmentData })
              },
              (error) => console.error(error),
              undefined,
              config,
              null
            )
          }
        })}
        noOfShipment={noOfShipment}
        redirectUrl={redirectUrl}
        redirectData={redirectData}
        additionalButtonLabel={additionalButtonLabel}
      />
      <ShipmentDialog
        getLookupValue={getLookupValue}
        getCountryStateLabel={getCountryStateLabel}
        isView
        onClose={() => setShipmentDialog({ open: false, shipment: null })}
        open={shipmentDialog.open}
        shipment={shipmentDialog.shipment}
      />
    </>
  )
}

export default ShipmentTable
