import React, { useRef, useEffect, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Box, Card, Chip, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import Alert from 'src/components/Alert'
import CngSection from 'src/components/cngcomponents/CngSection'
import PAXLSTFormProperties from '../PAXLSTFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = PAXLSTFormProperties.formikProps

function PaxlstInformationSection({ showNotification }) {
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const paxLSTInfo = useWatch({ name: 'paxLSTInfo' })
    const lastIndex = useRef(paxLSTInfo.length - 1)

    const statusChip = (label, value, color, contrastColor) => (
        <Grid container justify='flex-end'>
            <Grid item style={{ paddingRight: '5px' }}>{label}:</Grid>

            {value ?
                <Chip label={value} size='small'
                    style={{ backgroundColor: color, color: contrastColor }}
                />
                :
                <Grid item>-</Grid>
            }
        </Grid>
    )

    function handleAddPAXLSTInfo(data) {
        const paxLSTInfo = [...getValues('paxLSTInfo'), { ...data, status: 'DR', _id: ++lastIndex.current }]
        setValue('paxLSTInfo', paxLSTInfo)
    }

    function handleDeletePAXLSTInfo(data) {
        const paxLSTInfo = [...getValues('paxLSTInfo')].filter((paxLST) =>
            paxLST.id ? paxLST.id !== data.id : paxLST._id !== data._id
        )

        setValue('paxLSTInfo', paxLSTInfo)

        if (!paxLSTInfo || paxLSTInfo.length == 0) {
            setValue('autoSubmitPaxLst', false)
            setValue('autoConfirmManifest', false)
        }
    }

    function paxLSTPrevStatus() {
        const prevStatusLabel = getTranslatedText('ACE_HIGHWAY_MANIFEST', 'paxlstPrevStatus')
        if (containPrevStatus()) {
            if (paxLSTInfo[0].previousStatus === 'AC') {
                return (statusChip(prevStatusLabel, 'Accepted', 'green', '#fff'));
            }
            else if (paxLSTInfo[0].previousStatus === 'RJ') {
                return (statusChip(prevStatusLabel, 'Rejected', 'red', '#fff'));
            }
        }
    }

    function paxLSTStatus() {
        const statusLabel = getTranslatedText('ACE_HIGHWAY_MANIFEST', 'paxlstStatus')
        if (paxLSTInfo.length > 0 && paxLSTInfo[0].status) {
            if (paxLSTInfo[0].status === 'AC') {
                return (statusChip(statusLabel, 'Accepted', 'green', '#fff'));
            }
            else if (paxLSTInfo[0].status === 'RJ') {
                return (statusChip(statusLabel, 'Rejected', 'red', '#fff'));
            }
            else if (paxLSTInfo[0].status === 'DR') {
                return (statusChip(statusLabel, 'Draft', '#0662BB', '#fff'));
            }
            else if (paxLSTInfo[0].status === 'ST') {
                return (statusChip(statusLabel, 'Sent', '#ffc400', '#fff'));
            }
            else if (paxLSTInfo[0].status === 'PR') {
                return (statusChip(statusLabel, 'Pending Resubmission', '#0662BB', '#fff'));
            }
        }
        else {
            return (statusChip(statusLabel, null));
        }
    }

    function paxLSTReviewStatus() {
        const reviewStatusLabel = 'Review Status'
        if (containReviewStatus()) {
            if (paxLSTInfo[0].apiAutoSubmit === 'G') {
                return (statusChip(reviewStatusLabel, 'Reviewed', 'green', '#fff'));
            }
            else if (paxLSTInfo[0].apiAutoSubmit === 'R') {
                return (statusChip(reviewStatusLabel, 'Pending Review', 'red', '#fff'));
            }
            else if (paxLSTInfo[0].apiAutoSubmit === 'Y') {
                return (statusChip(reviewStatusLabel, 'Review in Progress', '#ffc400', '#fff'));
            }
        }
    }

    function containReviewStatus() {
        return (paxLSTInfo.length > 0 && paxLSTInfo[0].apiAutoSubmit)
    }

    function containPrevStatus() {
        return (paxLSTInfo.length > 0 && paxLSTInfo[0].status === 'PR' && paxLSTInfo[0].previousStatus)
    }

    return (
        <Grid item xs={12}>
            <CngSection title='PAXLST Information' helperText={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'paxLstSectionHelper')} alignItems='flex-end'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8}>
                                <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}>
                                    <strong>NOTE:</strong>{' '}
                                    {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'paxlstHelper1')}
                                </Alert>
                            </Grid>
                            <Grid xs={12} sm={4}>
                                <Grid container direction='row-reverse' spacing={1}>
                                    <Grid item xs={6}>{paxLSTStatus()}</Grid>
                                    {containPrevStatus && <Grid item xs={6}>{paxLSTPrevStatus()}</Grid>}
                                </Grid>
                                {containReviewStatus &&
                                    <Grid container direction='row-reverse' spacing={1}>
                                        <Grid item xs={12}>{paxLSTReviewStatus()}</Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    {paxLSTInfo.map((paxlst, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>

                                        <Grid item xs={12}>
                                            <PAXLSTFormProperties.Fields index={index} showNotification={showNotification} />
                                        </Grid>

                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Add'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'plus']} size='small' type='outlined'
                                                                onClick={() => handleAddPAXLSTInfo(initialValues)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddPAXLSTInfo({ ...paxlst, id: undefined, internationalDoc: [] })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Delete'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'trash']} size='small' type='outlined'
                                                                onClick={() => handleDeletePAXLSTInfo(paxlst)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}

                    {paxLSTInfo.length === 0 && (
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs='auto'>
                                    <Tooltip placement='bottom' title='Add new'>
                                        <span>
                                            <CngIconButton
                                                icon={['fal', 'plus']}
                                                onClick={() => handleAddPAXLSTInfo(initialValues)}
                                                size='small'
                                                type='outlined'
                                            />
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default PaxlstInformationSection