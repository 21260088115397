import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let templateValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.TEMPLATE_NAME_REQUIRE
  )
  let maxLength10Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_10
  )
  let maxLength15Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_15
  )
  let maxLength17Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_17
  )
  let maxLength18Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_18
  )
  let maxLength19Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_19
  )
  let maxLength23Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_23
  )
  let maxLength30Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_30
  )
  let maxLength50Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_50
  )
  let maxLength100Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_100
  )
  let aceIdValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ACE_ID_REQUIRED
  )
  let vinValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.VIN_REQUIRED
  )
  let emailValid = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.EMAIL_VALID
  )
  let minDriverRequiredValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MIN_DRIVER_REQUIRED
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )

  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  const typeWithoutLicense = ["20", "2B", "40", "4B", "CL", "CU", "CZ", "NC", "OE"];

  return Yup.object({
    // id: Yup.string().nullable(),
    // headerId: Yup.string().nullable(),
    // createdBy: Yup.string().nullable(),
    // // createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    // updatedBy: Yup.string().nullable(),
    // // updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    // submittedBy: Yup.string().nullable(),
    // // submittedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    // version: Yup.string().nullable(),
    aceFlag: Yup.boolean(),
    equipmentId: Yup.string().nullable(),
    equipmentAceId: Yup.string().when('aceFlag', {
      is: (value)=>value === true,
      then: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(10,maxLength10Validation).nullable(),
    }).nullable(),
    equipmentType: Yup.string().required(requiredMessage).nullable(),
    equipmentNo: Yup.string()
	  .when('aceFlag', {
		  is: (value) => value === false,
		  then: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(18,maxLength18Validation).nullable().
		  	when('equipmentType', {
		  	is: (value) => value !== 'NC',
			  then: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(18,maxLength18Validation).nullable()
		  	}),
		 otherwise: Yup.string(),
	  }).nullable(),
    licensePlateNo1: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage)
      .test("licensePlateNo1", requiredMessage, function () {
        let licensePlate1 = this.parent["licensePlateNo1"];
        let licensePlate2 = this.parent["licensePlateNo2"];
        let licensePlate3 = this.parent["licensePlateNo3"];
        let licensePlate4 = this.parent["licensePlateNo4"];
        let eqType = this.parent["equipmentType"];
        let aceFlag = this.parent["aceFlag"];
        if (typeWithoutLicense.includes(eqType) || aceFlag) {
          return true;
        } else {
          if (licensePlate1 !== null && licensePlate1 !== "" && licensePlate1 !== undefined ||
            (licensePlate2 !== null && licensePlate2 !== "" && licensePlate2 !== undefined) ||
            (licensePlate3 !== null && licensePlate3 !== "" && licensePlate3 !== undefined) ||
            (licensePlate4 !== null && licensePlate4 !== "" && licensePlate4 !== undefined)) {
            return true;
          } else {
            return false;
          }
        }
      }).nullable().max(17, maxLength17Validation),
    licensePlateCountry1: Yup.string().when('licensePlateNo1', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState1: Yup.string().when('licensePlateCountry1', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateNo2: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage)
      .test("licensePlateNo2", requiredMessage, function () {
        let licensePlate1 = this.parent["licensePlateNo1"];
        let licensePlate2 = this.parent["licensePlateNo2"];
        let licensePlate3 = this.parent["licensePlateNo3"];
        let licensePlate4 = this.parent["licensePlateNo4"];
        let eqType = this.parent["equipmentType"];
        let aceFlag = this.parent["aceFlag"];
        if (typeWithoutLicense.includes(eqType) || aceFlag) {
          return true;
        } else {
          if ((licensePlate2 !== null && licensePlate2 !== "" && licensePlate2 !== undefined) ||
            (licensePlate1 !== null && licensePlate1 !== "" && licensePlate1 !== undefined) ||
            (licensePlate3 !== null && licensePlate3 !== "" && licensePlate3 !== undefined) ||
            (licensePlate4 !== null && licensePlate4 !== "" && licensePlate4 !== undefined)) {
            return true;
          } else {
            return false;
          }
        }
      }).nullable().max(17, maxLength17Validation),
    licensePlateCountry2: Yup.string().when('licensePlateNo2', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState2: Yup.string().when('licensePlateCountry2', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateNo3: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage)
      .test("licensePlateNo3", requiredMessage, function () {
        let licensePlate3 = this.parent["licensePlateNo3"];
        let licensePlate2 = this.parent["licensePlateNo2"];
        let licensePlate1 = this.parent["licensePlateNo1"];
        let licensePlate4 = this.parent["licensePlateNo4"];
        let eqType = this.parent["equipmentType"];
        let aceFlag = this.parent["aceFlag"];
        if (typeWithoutLicense.includes(eqType) || aceFlag) {
          return true;
        } else {
          if (licensePlate3 !== null && licensePlate3 !== "" && licensePlate3 !== undefined ||
            (licensePlate1 !== null && licensePlate1 !== "" && licensePlate1 !== undefined) ||
            (licensePlate2 !== null && licensePlate2 !== "" && licensePlate2 !== undefined) ||
            (licensePlate4 !== null && licensePlate4 !== "" && licensePlate4 !== undefined)) {
            return true;
          } else {
            return false;
          }
        }
      }).nullable().max(17, maxLength17Validation),
    licensePlateCountry3: Yup.string().when('licensePlateNo3', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState3: Yup.string().when('licensePlateCountry3', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateNo4: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage)
      .test("licensePlateNo4", requiredMessage, function () {
        let licensePlate4 = this.parent["licensePlateNo4"];
        let licensePlate2 = this.parent["licensePlateNo2"];
        let licensePlate3 = this.parent["licensePlateNo3"];
        let licensePlate1 = this.parent["licensePlateNo1"];
        let eqType = this.parent["equipmentType"];
        let aceFlag = this.parent["aceFlag"];
        if (typeWithoutLicense.includes(eqType) || aceFlag) {
          return true;
        } else {
          if (licensePlate4 !== null && licensePlate4 !== "" && licensePlate4 !== undefined ||
            (licensePlate1 !== null && licensePlate1 !== "" && licensePlate1 !== undefined) ||
            (licensePlate3 !== null && licensePlate3 !== "" && licensePlate3 !== undefined) ||
            (licensePlate2 !== null && licensePlate2 !== "" && licensePlate2 !== undefined)) {
            return true;
          } else {
            return false;
          }
        }
      }).nullable().max(17, maxLength17Validation),
    licensePlateCountry4: Yup.string().when('licensePlateNo4', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState4: Yup.string().when('licensePlateCountry4', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    iitCode1: Yup.string().nullable(),
    iitCode2: Yup.string().nullable(),
    iitCode3: Yup.string().nullable(),
    iitCode4: Yup.string().nullable(),
    sealNo1: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo2: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo3: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo4: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo5: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo6: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo7: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo8: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo9: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
  })
}

export default makeValidationSchema
