import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import { Card, Grid } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
// import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  NaAceHwyPOEPortAutocompleteField,
  NaAceHwyPOLPortAutocompleteField
} from 'src/components/na'
// import PartyInfoFormProperties from './PartyInfoFormProperties'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls';
import CryptoJS from 'crypto-js';

const {
  button: { CngIconButton },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  manifestId: "",
  inBond7512No: "",
  inBondDestination: "",
  pointOfLoading: "",
  partyIdentifierValue: "",
  firmsCode: "",
  name1: "",
  noOfPackagesUom: "",
  aceHighwayShipmentHeaders: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, watch } = useFormContext()
  const manifestId = watch('manifestId')

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  const [user, setUser] = useState([]);
  const { securedSendRequest } = useServices();
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);
 
  useEffect(() => {
    function onSuccess(response) {
      setValue('inBond7512No', response.data.inBond7512No);
      setValue('inBondDestination', response.data.inBondDestination, { shouldDirty: true });
      setValue('name1', response.data.name1);

      var encryptedBase64Key = "Y3JpbXNvbmxvZ2ljMTIzNA==";
      var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

      let enableEncryption = `${process.env.REACT_APP_NA_SHOULD_ENABLE_ENCRYPTION}`
      if (enableEncryption == "true" && response.data.partyIdentifierValue && response.data.partyIdentifierValue.includes("=")) {
        var decryptedData = CryptoJS.AES.decrypt(response.data.partyIdentifierValue, parsedBase64Key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });

        var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

        response.data.partyIdentifierValue = decryptedText;
      }

      setValue('partyIdentifierValue', response.data.partyIdentifierValue);
      setValue('noOfPackagesUom', response.data.noOfPackagesUom);
    }

    function onError(error) {
      console.log(error);
    }

    function onComplete() {
    }
    const config = {}

    securedSendRequest.execute('POST', AceHighwayShipmentApiUrls.GET_PROPAGATE_SHIPMENT_INFO, { manifestId }, onSuccess, onError, onComplete, config, null);
  }, []);

  const inBondDestination = watch('inBondDestination')
  const noOfPackagesUom = watch('noOfPackagesUom')

  function makeTranslatedTextsObject() {
    let pointOfLoading = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.POINT_OF_LOADING
    )
    let inBond7512No = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.IN_BOND7512_NO
    )
    let inBondDestination = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.IN_BOND_DESTINATION
    )
    let firmsCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.FIRMS_CODE
    )
    let name1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.NAME1
    )
    let noOfPackagesUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES_UOM
    )
    let bondedCarrierName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.BONDED_CARRIER_NAME
    )
    let partyIdentifier = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE
    )

    return {
      inBond7512No,
      inBondDestination,
      pointOfLoading,
      firmsCode,
      name1,
      noOfPackagesUom,
      bondedCarrierName,
      partyIdentifier
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <Grid container spacing={1}>
              <CngGridItem xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} md={6} lg={4}>
                    <CngTextField
                      size='small'
                      required
                      name='inBond7512No'
                      label={translatedTextsObject.inBond7512No}
                      disabled={disabled}
                      inputProps={{ maxLength: 9 }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('inBond7512No', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} md={6} lg={4}>
                    <NaAceHwyPOEPortAutocompleteField
                      size='small'
                      required
                      name='inBondDestination'
                      label={translatedTextsObject.inBondDestination}
                      disabled={disabled}
                      key={inBondDestination}
                    />
                  </CngGridItem>

                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <NaAceHwyPOLPortAutocompleteField
                      size='small'
                      required
                      name='pointOfLoading'
                      label={translatedTextsObject.pointOfLoading}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} md={6} lg={4}>
                    <CngTextField
                      size='small'
                      name='firmsCode'
                      label={translatedTextsObject.firmsCode}
                      disabled={disabled}
                      onChange={(e) =>
                        setValue(
                          'firmsCode',
                          e.target.value.toUpperCase(),
                          { shouldValidate: true }
                        )
                      }
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('firmsCode', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                    />
                  </CngGridItem>

                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <CngTextField
                      name='partyIdentifierValue'
                      label={translatedTextsObject.partyIdentifier}
                      disabled={disabled}
                      onChange={(e) => {
                        setValue('partyIdentifierValue', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('partyIdentifierValue', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                    />
                  </CngGridItem>

                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <CngTextField
                      name='name1'
                      label={translatedTextsObject.bondedCarrierName}
                      disabled={disabled}
                      onChange={(e) => {
                        setValue('name1', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('name1', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                    />
                  </CngGridItem>

                  <CngGridItem xs={12}
                    md={6}
                    lg={4}>
                    <CngCodeMasterAutocompleteField
                      required
                      name='noOfPackagesUom'
                      label={translatedTextsObject.noOfPackagesUom}
                      disabled={disabled}
                      codeType='ACE_HW_UOM'
                      size='small'
                      key={noOfPackagesUom}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const PropagateForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default PropagateForm
