import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import ManifestEditPage from './ManifestEditForm'
import ShipmentAttachTable from './shipment/ShipmentAttachTable'
import StatusNotificationListPage from './StatusNotificationListPage'
import { Box, Card, useTheme } from '@material-ui/core'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import pathMap from '../../../paths/pathMap'
import Namespace from '../../../constants/locale/Namespace'
import AceHighwayManifestKeys from '../../../constants/locale/key/AceHighwayManifest'
import AceHighwayManifestApiUrls from '../../../apiUrls/AceHighwayManifestApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import axios from 'axios'
import ViewLogsHistory from './ViewLogsHistory'
import FormProperties from './FormProperties'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import moment from 'moment-timezone'
import { useFormStyles } from 'src/common/FormStyles'
import SectionJumper from 'src/components/SectionJumper'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, loading, location, onSetLoading, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const manifestData = useRef(state?.manifestData ? state.manifestData : null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [manifestStatus, setManifestStatus] = useState(state?.status ? state.status : null)
  const [stepIconColor, setStepIconColor] = useState({})
  const [completed, setCompleted] = useState({})
  const theme = useTheme()
  const formClass = useFormStyles()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })

  const [isSuperParty, setSuperParty] = useState(false);

  useEffect(() => {
    if (manifestData != null && manifestData != undefined) {
      if (manifestData.current != null && manifestData.current != undefined) {
        setManifestStatus(manifestData.current.status)
      }
    }
  }, []);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }
  useEffect(() => {
    if (manifestStatus) {
      if (manifestStatus !== '1005') {
        setCompleted({ 0: true })
      }

      if (manifestStatus === '1001') {
        const rejectedColor = getStatusMetadata(manifestStatus)

        setStepIconColor({ 0: rejectedColor })
      }
    }
  }, [manifestStatus])

  function makeTranslatedTextsObject() {
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SUBMIT_SUCCESS_MSG
    )

    return { submitSuccessMsg }
  }

  function preSubmit() {
    const values = { ...manifestData.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      manifestData.current = values
      handleSubmitManifest()
    }
  }

  function handleSubmitManifest() {
    onSetLoading(true)

    const values = { ...manifestData.current }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    var serverData = FormProperties.toServerDataFormat(values)
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHighwayManifestApiUrls.SUBMIT, DataFlattener.unflatten(serverData))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
      .finally(() => {
        onSetLoading(false)
      })
  }

  function handleCreateACIManifest() {
    onSetLoading(true)

    const values = { ...manifestData.current, id }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    var serverData = FormProperties.toServerDataFormat(values)
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHighwayManifestApiUrls.CREATE_ACI_MANIFEST, DataFlattener.unflatten(serverData))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            returnData.expArrivalTime = moment(returnData.expArrivalDate).format('HH:mm');
            returnData.expArrivalDate = moment(returnData.expArrivalDate).format('YYYY-MM-DD');
            history.push(pathMap.ACIHWY_MANIFEST_ADD_VIEW, returnData)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
      .finally(() => {
        onSetLoading(false)
      })
  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <ManifestEditPage
            manifestData={manifestData}
            onChangeStep={(step) => setStep(step)}
            onSetLoading={onSetLoading}
            onSetManifestStatus={(status) => setManifestStatus(status)}
            onSubmitManifest={preSubmit}
            showNotification={showNotification}
            manifestStatus={manifestStatus}
            step={step}
            id={id}
            onCreateACIManifest={handleCreateACIManifest}
          />
        )
      case 1:
        return (
          <ShipmentAttachTable
            history={history}
            manifestId={id}
            onChangeStep={(step) => setStep(step)}
            showNotification={showNotification}
            step={step}
            loading={loading}
            onSetLoading={onSetLoading}
            port={manifestData?.current?.firstExpectedPort ? manifestData.current.firstExpectedPort : null}
            manifestStatus={manifestData?.current?.status && manifestData?.current?.status === '1002' ? manifestData.current.status : null}
            manifestCarrierScac={manifestData?.current?.carrierScac ? manifestData.current.carrierScac : null}
          />
        )
      case 2:
        return (
          <StatusNotificationListPage
            history={history}
            showNotification={showNotification}
            manifestId={id}
            onSetLoading={onSetLoading}
            onSubmitManifest={handleSubmitManifest}
            onChangeStep={(step) => setStep(step)}
            step={step}
            isABI={false}
          />
        )
      case 3:
        return (
          <ViewLogsHistory
            manifestId={id}
            history={history}
            onChangeStep={(step) => setStep(step)}
            onSubmitManifest={preSubmit}
            step={step}
          />
        )
    }
  }

  return (
    <>
      <Card className={clsx(formClass.form, formClass.inputs)}>
        <Stepper
          steps={!manifestStatus || manifestStatus === '1005' ? ['Create Trip', 'Add Shipments', 'Set Status Notification'] : ['Create Trip', 'Add Shipments', 'Set Status Notification', 'View Logs & History']}
          activeStep={step}
          completed={completed}
          onSetActiveStep={(step) => setStep(step)}
          stepIconColor={stepIconColor}
        />
        <Box padding={2}>
          {renderContent(step)}
        </Box>
      </Card>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          manifestData.current = data
          handleSubmitManifest()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      <SectionJumper pageNum={step}
        pages={[
          {
            label: 'Edit Trip',
            sections: [
              {label: 'Trip Information', id: 'trip-info'},
              {label: 'Truck (Conveyance)', id: 'truck-conveyance'},
              {label: 'Equipment Information', id: 'eqp-info'},
              {label: 'Crew Information', id: 'crew-info'},
              {label: 'Insurance Information', id: 'insurance-info'},
              {label: 'Message Function', id: 'msg-fn'},
            ]
          }
        ]}
      />
    </>
  )
}

export default withFormState(EditPage)
