import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import moment from 'moment'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: { CommonValidationMessageKeys },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let arrivalDateValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.EXP_ARRIVAL_DATE_TIME
  )
  let carrierCodeLength = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.CARRIER_CODE_LENGTH
  )
  let carrierCodeRegX= translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.CARRIER_CODE_REGX
  )
  let tripNumLength = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.TRIP_NUM_LENGTH
  )
  let tripNumPrefix = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.TRIP_NUM_PREFIX
  )
  let templateValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.TEMPLATE_NAME_REQUIRE
  )
  let fastCardValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.FAST_CARD_REQUIRE
  )
  let minLength10Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MIN_LENGTH_10
  )
  let maxLength9Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_9
  )
  let maxLength10Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_10
  )
  let maxLength15Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_15
  )
  let maxLength17Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_17
  )
  let maxLength23Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_23
  )
  let maxLength25Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_25
  )
  let maxLength30Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_30
  )
  let maxLength35Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_35
  )
  let maxLength40Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_40
  )
  let maxLength50Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_50
  )
  let maxLength100Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_100
  )
  let maxLength11Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_11
  )
  let maxLength60Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_60
  )
  let numericRegx = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.NUMERIC_REGX
  )
  let aceIdValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ACE_ID_REQUIRED
  )
  let vinValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.VIN_REQUIRED
  )
  let emailValid = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.EMAIL_VALID
  )
  let minDriverRequiredValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MIN_DRIVER_REQUIRED
  )
  let truckIdValidation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.TRUCK_ID_REQUIRED
  )
  let arrivalDateTimeFormat = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.INVALID_ARRIVAL_DATE_TIME_FORMAT
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )
  let alphaNumericDashErrorMessage = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ALPHANUMERIC_DASH
  )

  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  let regexDate= "[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])"
  let regexTime= "(2[0-3]|[01][0-9]):[0-5][0-9]"

  return Yup.object({
    carrierScac: Yup.string().when('templateFlag', {
      is: (value)=>value === false,
      then: Yup.string().matches(regexAlphaNumeric,carrierCodeRegX).required(requiredMessage).min(4, carrierCodeLength),
    }).nullable(),
    tripNo: Yup.string().when('templateFlag', {
      is: (value)=>value === false,
      then: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).required(requiredMessage).min(5, tripNumLength)
      .test("tripNo",tripNumPrefix,function(){
        let carrierScac = this.parent["carrierScac"];
        let tripNo = this.parent["tripNo"];
      if(tripNo && carrierScac) {
        if(tripNo.startsWith(carrierScac))
         return true;
        else
         return false;
      }
      }),
    }).nullable(),
    arrivalDate: Yup.string().matches(regexDate,arrivalDateTimeFormat).nullable(),
    arrivalTime: Yup.string().matches(regexTime, arrivalDateTimeFormat).nullable(),
    transitIndicator: Yup.string().required(requiredMessage).nullable(),
    firstExpectedPort: Yup.string().required(requiredMessage).nullable(),
    msgFunction: Yup.string().nullable(),
    amendmentCode: Yup.string().nullable(),
    status: Yup.string().nullable(),
    templateFlag: Yup.boolean().nullable(),
    templateName: Yup.string().when('templateFlag', {
      is: (value)=>value === true,
      then: Yup.string().required(templateValidation).max(100,maxLength100Validation).nullable(),
    }).nullable(),
    aceFlag: Yup.boolean().nullable(),
    aceId: Yup.string().when('aceFlag', {
      is: (value)=>value === true,
      then: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).required(aceIdValidation).max(10,maxLength10Validation).nullable(),
    }).nullable(),
    conveyanceId: Yup.string().nullable(),
    conveyanceNo: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(23,maxLength23Validation).nullable(),
    conveyanceType: Yup.string().required(requiredMessage).nullable(),
    email: Yup.string().trim().email(emailValid).max(50, maxLength50Validation).nullable(),
    vin: Yup.string().when('aceFlag', {
      is: (value)=>value === false,
      then: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).required(vinValidation).max(17,maxLength17Validation).nullable(),
    }).nullable(),
    dotNo: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(17,maxLength17Validation).nullable(),
    transponderId: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLength30Validation).nullable(),
    licensePlateNo1: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).required(requiredMessage).max(17,maxLength17Validation).nullable(),
    licensePlateCountry1: Yup.string().required(requiredMessage).nullable(),
    licensePlateState1: Yup.string().required(requiredMessage).nullable(),

    licensePlateNo2: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).nullable().max(17,maxLength17Validation),
    licensePlateCountry2: Yup.string().when('licensePlateNo2', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState2: Yup.string().when('licensePlateCountry2', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),

    licensePlateNo3: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).nullable().max(17,maxLength17Validation),
    licensePlateCountry3: Yup.string().when('licensePlateNo3', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState3: Yup.string().when('licensePlateCountry3', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),

    licensePlateNo4: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).nullable().max(17,maxLength17Validation),
    licensePlateCountry4: Yup.string().when('licensePlateNo4', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    licensePlateState4: Yup.string().when('licensePlateCountry4', {
      is: (value)=>(value!==null && value!=="" && value!==undefined),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),

    autoSubmitPaxLst: Yup.boolean().nullable(),
    autoConfirmManifest: Yup.boolean().nullable(),

    iitCode1: Yup.string().nullable(),
    iitCode2: Yup.string().nullable(),
    iitCode3: Yup.string().nullable(),
    iitCode4: Yup.string().nullable(),
    sealNo1: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo2: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo3: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo4: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo5: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo6: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo7: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo8: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    sealNo9: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(15,maxLength15Validation).nullable(),
    integration: Yup.string().nullable(),
    equipmentInfo: Yup.array().nullable(),
    insuranceInfo: Yup.array(Yup.object({
      insuranceCompany: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).required(requiredMessage).max(60,maxLength60Validation).nullable(),
      insurancePolicyNo: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).required(requiredMessage).max(50,maxLength60Validation).nullable(),
      liabilityAmount: Yup.string().required(requiredMessage).trim().matches("^[0-9]*$", numericRegx).max(11, maxLength11Validation).nullable(),
      yearOfIssuance: Yup.number().typeError(numericRegx).required(requiredMessage).min(1900).max(3000).nullable(),
    })).nullable(),
    messageFunction: Yup.string().nullable(),
    saveTruckFlag: Yup.boolean().nullable(),
    truckId: Yup.string().when('saveTruckFlag', {
      is: (value)=>value === true,
      then: Yup.string().required(truckIdValidation).nullable(),
    }).nullable(),

    partyInfo: Yup.array(
      Yup.object({
        saveCrewFlag: Yup.boolean().nullable(),
        crewId: Yup.string().when('saveCrewFlag', {
          is: (value) => value === true,
          then: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).required(requiredMessage).max(15, maxLength15Validation).nullable(),
        }).nullable(),
        saveFlag: Yup.boolean().nullable(),
        partyIdentifier: Yup.string().when('saveFlag', {
          is: (value) => value === true,
          then: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).required(requiredMessage).max(15, maxLength15Validation).nullable(),
        }).nullable(),
        partyType: Yup.string().required(requiredMessage).nullable(),
        name1: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        address1: Yup.string().when('partyType', {
          is: (value) => value === 'VW',
          then: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        }).nullable(),
        address2: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        address3: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        cityName: Yup.string().when('partyType', {
          is: (value) => value === 'VW',
          then: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        }).nullable(),
        postalCode: Yup.string().when('partyType', {
          is: (value) => value === 'VW',
          then: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).trim().max(10, maxLength10Validation).nullable(),
        }).nullable(),
        state: Yup.string().when('partyType', {
          is: (value) => value === 'VW',
          then: Yup.string().nullable(),
        }).nullable(),
        telNo: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericDashErrorMessage).min(10, minLength10Validation).max(25, maxLength25Validation).nullable(),
        email: Yup.string().email(emailValid).max(40, maxLength40Validation).nullable(),
        firstName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        middleName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        lastName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        fastCardFlag: Yup.boolean(),
        fastCard: Yup.string().when('fastCardFlag', {
          is: (value) => value === true,
          then: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage).required(fastCardValidation).max(23, maxLength23Validation).nullable(),
        }).nullable(),
        aceIdFlag: Yup.boolean(),
        aceId: Yup.string().when('aceIdFlag', {
          is: (value) => value === true,
          then: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage).required(aceIdValidation).max(10, maxLength10Validation).nullable(),
        }).nullable(),
        phoneNo: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericDashErrorMessage).max(25, maxLength25Validation).nullable(),
        faxNo: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericDashErrorMessage).max(25, maxLength25Validation).nullable(),
      })
    ),

    paxLSTInfo: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        dob: Yup.date().when(['fastCardFlag', 'aceIdFlag'], {
          is: (fastCardFlag, aceIdFlag) => {
            if (fastCardFlag || aceIdFlag) {
              return false;
            }
            return true;
          },
          then: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
        }).nullable(),
        nationality: Yup.string().nullable(),
        gender: Yup.string().nullable(),
        partyType: Yup.string().nullable(),
        address1: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation)
        .when(['partyType', 'aceIdFlag'], {
          is: (partyType, aceIdFlag) => {
            if (partyType === 'VW' && aceIdFlag) {
              return true;
            }
            return false;
          },
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable(),
        address2: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        address3: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        cityName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation)
        .when(['partyType', 'aceIdFlag'], {
          is: (partyType, aceIdFlag) => {
            if (partyType === 'VW' && aceIdFlag) {
              return true;
            }
            return false;
          },
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable(),
        postalCode: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).trim().max(9, maxLength9Validation)
        .when(['partyType', 'aceIdFlag'], {
          is: (partyType, aceIdFlag) => {
            if (partyType === 'VW' && aceIdFlag) {
              return true;
            }
            return false;
          },
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable(),
        state: Yup.string()
        .when(['partyType', 'aceIdFlag'], {
          is: (partyType, aceIdFlag) => {
            if (partyType === 'VW' && aceIdFlag) {
              return true;
            }
            return false;
          },
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable(),
        telNo: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericDashErrorMessage).min(10, minLength10Validation).max(25, maxLength25Validation).nullable(),
        email: Yup.string().email(emailValid).max(50, maxLength50Validation).nullable(),
        hazmatEndorsement: Yup.string().nullable(),
        licenseNo: Yup.string().nullable().max(35, "Max 35 characters are allowed"),
        licenseCountry: Yup.string().when('licenseNo', {
          is: (value) => (value !== null && value !== "" && value !== undefined),
          then: Yup.string().nullable(),
        }).nullable(),
        licenseState: Yup.string().when('licenseCountry', {
          is: (value) => (value !== null && value !== "" && value !== undefined),
          then: Yup.string().nullable(),
        }).nullable(),

        cdlNo: Yup.string().nullable().max(35, "Max 35 characters are allowed").matches(regexAlphaNumeric, alphaNumericErrorMessage),
        cdlCountry: Yup.string().when('cdlNo', {
          is: (value) => (value !== null && value !== "" && value !== undefined),
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable(),
        cdlState: Yup.string().when('cdlCountry', {
          is: (value) => (value !== null && value !== "" && value !== undefined),
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable(),

        firstName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        middleName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        lastName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLength35Validation).nullable(),
        fastCardFlag: Yup.boolean().nullable(),
        customProximityId: Yup.string().when('fastCardFlag', {
          is: (value) => value === true,
          then: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(23, maxLength23Validation).nullable(),
        }).nullable(),
        aceIdFlag: Yup.boolean().nullable(),
        aceId: Yup.string().when('aceIdFlag', {
          is: (value) => value === true,
          then: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(10, maxLength10Validation).nullable(),
        }).nullable(),

        internationalDoc: Yup.array(Yup.object({
          docType: Yup.string().nullable(),
          docNo: Yup.string().matches("^[a-zA-Z0-9]+$", "Enter alphanumeric characters.").nullable().when('docType', {
            is: docType => docType && docType.length > 0,
            then: Yup.string().required(requiredMessage),
            othewise: Yup.string().nullable()
          }),
          country: Yup.string().nullable().when('docType', {
            is: docType => docType && docType.length > 0,
            then: Yup.string().required(requiredMessage),
            othewise: Yup.string().nullable()
          }),
          state: Yup.string().nullable(),
        })).nullable(),
      })
    )

  })
}

export default makeValidationSchema
