import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AceHighwayResponseApiUrls from 'src/apiUrls/AceHighwayResponseApiUrls'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import CngSection from '../../../components/cngcomponents/CngSection'
import { format } from 'date-fns'
import { Grid } from '@material-ui/core'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function AbiInbondResponse(props) {
  const { showNotification, id, isViewOnly = false, is7512 } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords, securedSendRequest } = useServices()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_ABI_INBOND_7512])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const classes = useStyles()

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        AceHighwayResponseApiUrls.GET,
        {
          filters: [
            {
              field: 'headerId',
              operator: EQUAL,
              value: id
            },
            {
              field: 'documentType',
              operator: IN,
              value: is7512 ? ['ABI_INBOND_7512'] : ['ABI_INBOND_EVENT']
            }
          ]
        },
        (res) => res.content
      )
      // ,
      // fetchCodeMaintenanceLookup(
      //   constants.CodeMaintenanceType.CODE_MASTER,
      //   undefined,
      //   [
      //     {
      //       field: 'codeType',
      //       operator: EQUAL,
      //       value: 'ACI_HW_SUBMISSION_TYPE'
      //     }
      //   ],
      //   undefined,
      //   'code'
      // )
    ]).then(([data]) => {
      const groupedData = Object.values(data.reduce((resp, obj) => {
        const key = obj.batchId;
        if (!resp[key]) {
          resp[key] = [];
        }
        resp[key].push(obj);
        return resp;
      }, {}));
      setData(groupedData)
    })
  }, []);

  function makeTranslatedTextsObject() {
    let processingDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.PROCESSING_DATE
    )
    let responseCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.RESPONSE_CODE
    )
    let responseText = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.RESPONSE_TEXT
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.RESPONSE_DATE
    )
    let remarks = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.REMARKS
    )

    return {
      processingDate,
      responseCode,
      responseText,
      responseDate,
      remarks
    }
  }

  const downloadColumns = [
    { title: 'Submitted File' },
    { title: 'Response File' }
  ]

  const columns = [
    {
      title: translatedTextsObject.processingDate,
      render: (data) =>
        data.cbsaProcessingDate &&
        moment(data.cbsaProcessingDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
    },
    { field: 'responseCode', title: translatedTextsObject.responseCode },
    { field: 'responseText', title: translatedTextsObject.responseText },
    {
      title: translatedTextsObject.responseDate,
      render: (data) =>
        data.responseDate &&
        moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
    },
    {
      title: translatedTextsObject.remarks
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  async function handleDownload({ url, respData, type}) {
    console.log(JSON.stringify(respData))
    var batch = respData[0].batchId
    var data = {batchId: batch, documentType: type}
    console.log(JSON.stringify(data))
    try {
      await securedSendRequest.execute(
        'POST',
        url,
        data,
        (response) => {
          console.log("resp: "+JSON.stringify(response))
          if(undefined !== response.data && "" !== response.data && null !== response.data){
            const blob = new Blob([response.data])
            const curDate = format(new Date(), 'yyyyMMddHHmmss')
            const a = document.createElement('a')

            a.href = window.URL.createObjectURL(blob)
            a.download = `${batch}_${curDate}_${type}.txt`
            a.click()

            showNotification('success', 'Download success')
          }else{
            showNotification('error', 'Empty File')
          }
        },
        (error) => {
          console.error(error)
          showNotification('error', 'Download failed')
        }
      )
    } finally {
      
    }
  }

  return (
    <>
      {data.length > 0 ? (data.map((resp) => (
        <>
        <Grid style={{marginBottom: '1em'}}>
          <CngSection>
          <TableContainer>
            <Table className={classes.table}>
              <TableBody>
                {resp.length > 0 && (
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2} >
                        <Grid item xs='auto'>
                          {'Batch ID: '+resp[0].batchId}
                        </Grid>
                      </Grid>
                      <Grid container justify='flex-end' spacing={2} >
                        <Grid item xs='auto' style={{alignSelf : 'center'}}>
                          {'Submitted File'}
                        </Grid>
                        <Grid item xs='auto'>
                          <CngButton size='medium' variant='outlined' onClick={() =>handleDownload({url: AceHighwayResponseApiUrls.DOWNLOAD_FILE, respData: resp, type: 'SUB'})}> 
                            Download
                          </CngButton>
                        </Grid>
                        <Grid item xs='auto' style={{alignSelf : 'center'}}>
                          {'Response File'}
                        </Grid>
                        <Grid item xs='auto'>
                          <CngButton size='medium' variant='outlined' onClick={() =>handleDownload({url: AceHighwayResponseApiUrls.DOWNLOAD_FILE, respData: resp, type: 'RES'})}> 
                          Download
                          </CngButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}>{column.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {resp.map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                    
                  </TableRow>
                ))
                }
              </TableBody>
              
            </Table>
          </TableContainer>

          </CngSection>
        </Grid>
        </>
      ))) : (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={columns.length}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default AbiInbondResponse
