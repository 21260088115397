import React, { useState, useEffect } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import AceHighwayShipmentKeys from '../../../../constants/locale/key/AceHighwayShipment'
import AceHighwayShipmentApiUrls from '../../../../apiUrls/AceHighwayShipmentApiUrls'
import { Box, Grid, Paper } from '@material-ui/core'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  CngDialog
} = components

function ShipmentUploadDialog(props) {
  const { manifestId, onClose, open, showNotification } = props

  const [files, setFiles] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SAVE_BUTTON
    )

    return { saveButton }
  }

  useEffect(() => {
    setFiles([])
    setErrorMessages([])
  }, [open]);

  const downloadFile = () => {
    const blob = new Blob(["Shipment Type,Shipment Control Number,Point of Loading (Schedule K),INBOND 7512#,Inbond Destination,Foreign Destination,US Exit Date,Consignee Name,Consignee Address,Consignee City,Consignee State,Consignee Country,Consignee Postal Cd,Shipper Name,Shipper Address,Shipper City,Shipper State,Shipper Country,Shipper Postal Cd,Bonded Carrier Code,Bonded Carrier ID,Bonded Carrier Name,FDA,Commodity Description,Total Number of Pieces,Total Number of Pieces UOM,Total Weight,Total Weight UOM,Value(Amount) USD,Country of Origin,Dangerous Goods Flag,UNDG Code #1,UNDG Contact Name #1,UNDG Contact Number #1,UNDG Code #2,UNDG Contact Name #2,UNDG Contact Number #2,UNDG Code #3,UNDG Contact Name #3,UNDG Contact Number #3,UNDG Code #4,UNDG Contact Name #4,UNDG Contact Number #4,Harmonized Tariff Code #1,HArmonized Tariff Code #2,C4 Code #1,C4 Code #2\nPAPS,TESTNEWSHIP01,97102,,,,,RL JONES,8830 SIEMPRE VIVA ROAD ST 100,San Diego,CA,US,92154,SOLUCIONES XB MEXICO S DE RL DE CV,BLVD HECTOR TERAN TERAN 2102 INTERIOR 2DBLVD HECTOR TERAN TERAN 2102 INTERIOR 2D,TIJUANA,BCN,MX,22203,,,,0,PAPER PLATE CUPCAKE NAPKIN CEL PHONE ACCESORY BACKPACK BRACELETE RING EARRING WALLET TABLE CLOTH T SHIRT DISPLAY YARD SIGN,27573,PCS,993,K,,,0,,,,,,,,,,,,,,,,"], { type: "text/csv" })
    const element = document.createElement("a");
    element.href = window.URL.createObjectURL(blob)
    element.download = "ACEHWY_Shipment_SampleUpload.csv";
    document.body.appendChild(element);
    element.click();
    element.parentNode.removeChild(element);
  }

  function handleUploadShipment() {
    try {
      if (!files || files.length === 0) {
        showNotification('error', 'No file attached. Please attach a file to upload.');
        return;
      }
      
      setLoading(true)

      let fileForUserDetails = FileForUserGetUserDetails();
      const formData = new FormData()

      formData.append('file', files[0].file)
      formData.append('manifestId', manifestId)
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails))
      } else {
        formData.append("fileForUserDetails", "{}")
      }

      securedSendRequest.execute(
        'POST',
        AceHighwayShipmentApiUrls.UPLOAD,
        formData,
        (response) => {
          if (response.status === 200) {
            if (response.data) {
              const errorMessages = response.data.errorMessages

              if (errorMessages) {
                setErrorMessages(errorMessages)
                setFiles([])
              }
            }else{
              showNotification('success', 'File has been uploaded successfully')
              onClose()
              open.tableRef.current.performRefresh()
            }
          }
        },
        () => {
          showNotification('error', 'Something went wrong when uploading shipment.')
          setFiles([])
        },
        () => setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CngDialog
      dialogTitle='Upload new shipment'
      dialogContent={
        <Paper variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.csv']}
                  files={files}
                  maxFiles={1}
                  maxSize={5.3e+7}
                  onFileSelect={(file) => setFiles(file)}
                  showFormFields={false}
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                  onDropRejected={(errorMessages) => showNotification('error', errorMessages)}
                />
              </Grid>

              <ErrorMessageSection errorMessages={errorMessages} />

              <Grid item xs={12}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton size='medium' variant='outlined' onClick={downloadFile}>
                      Download template CSV
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      }
      dialogAction={
        <>
          <CngButton color='secondary' disabled={loading} onClick={onClose}>
            Discard
          </CngButton>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={handleUploadShipment}
            shouldShowProgress={loading}
          >
            {translatedTextsObject.saveButton}
          </CngButton>
        </>
      }
      fullWidth
      maxWidth='lg'
      open={open.open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default ShipmentUploadDialog
