import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import {
  constants,
} from 'cng-web-lib'
import { min } from 'lodash'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  
  let maxLength12Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_12
  )
  let maxLength60Validation = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.MAX_LENGTH_60
  )
  let numericRegx = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.NUMERIC_REGX
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )
  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    AceHighwayManifestKeys.Validations.NUMERIC_REGX
  )

  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  

  return Yup.object({
    // id: Yup.string().nullable(),
    // headerId: Yup.string().nullable(),
    // createdBy: Yup.string().nullable(),
    // // createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    // updatedBy: Yup.string().nullable(),
    // // updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    // submittedBy: Yup.string().nullable(),
    // // submittedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    // version: Yup.string().nullable(),
    insuranceCompany: Yup.string().required(requiredMessage).max(60,maxLength60Validation).nullable(),
    insurancePolicyNo: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).required(requiredMessage).max(50,maxLength60Validation).nullable(),
    liabilityAmount: Yup.string().required(requiredMessage).trim().matches("^[0-9]*$", numericRegx).max(12, maxLength12Validation).nullable(),
    yearOfIssuance: Yup.number().typeError(numericRegx).required(requiredMessage).min(1900).max(3000).nullable(),
  })
}

export default makeValidationSchema
