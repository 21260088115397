import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AceHighwayResponseApiUrls from 'src/apiUrls/AceHighwayResponseApiUrls'
import AceHighwayKeys from 'src/constants/locale/key/AceHighwayResponse'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function ShipmentResponse(props) {
  const { showNotification, id, isViewOnly = false } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords,fetchPageableRecords } = useServices()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_RESPONSE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const classes = useStyles()
  const [totalElements,setTotalElements] = useState(0)
  const [filter, setFilter] = useState([])
  const [sort, setSort] = useState({
    field: 'createdBy',
    direction:'ASC'
  })
  const fetchFilters = [{
    field: 'headerId',
    operator: EQUAL,
    value: id
  },
  {
    field: 'documentType',
    operator: EQUAL,
    value: 'ACE_SHIP'
  }]
  

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        AceHighwayResponseApiUrls.GET,
        {
          filters: [
            {
              field: 'headerId',
              operator: EQUAL,
              value: id
            },
            {
              field: 'documentType',
              operator: EQUAL,
              value: 'ACE_SHIP'
            }
          ]
        },
        (res) => res.content
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACE_HW_SHIP_SUBM_ACTION'
          }
        ],
        undefined,
        'code'
      )
    ]).then(([data, submissionType]) => {
      setData(data)
      setLookups({ submissionType })
    })
    
  }, [])

  function makeTranslatedTextsObject() {
    let cbsaProcessingDate = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.CBSA_PROCESSING_DATE
    )
    let CBPRefNo = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.CBP_REF_NO
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.TRIP_NO
    )
    let responseCode = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.RESPONSE_CODE
    )
    let responseText = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.RESPONSE_TEXT
    )
    let shipmentControlNo = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.SHIPMENT_CONTROL_NO
    )
    let entryNo = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.ENTRY_NO
    )
    let entryType = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.ENTRY_TYPE
    )
    let noOfPackage = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.NO_OF_PACKAGE
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.RESPONSE_DATE
    )
    let submissionType = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.SUBMISSION_TYPE
    )
    let submitId = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.SUBMIT_ID
    )
    let methodOfSubmission = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.METHOD
    )

    return {
      cbsaProcessingDate,
      CBPRefNo,
      tripNo,
      responseCode,
      responseText,
      shipmentControlNo,
      entryNo,
      entryType,
      noOfPackage,
      responseDate,
      submissionType,
      submitId,
      methodOfSubmission
    }
  }

  const columns = [
    {
      title: translatedTextsObject.cbsaProcessingDate,
      render: (data) =>
        data.cbsaProcessingDate &&
        moment(data.cbsaProcessingDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
    },
    { field: 'cbpRefNo', title: translatedTextsObject.CBPRefNo },
    { field: 'responseCode', title: translatedTextsObject.responseCode },
    { field: 'responseText', title: translatedTextsObject.responseText },
    { field: 'noOfPkgs', title: translatedTextsObject.noOfPackage },
    { field: 'entryNo', title: translatedTextsObject.entryNo },
    { field: 'entryType', title: translatedTextsObject.entryType },
    {
      title: `${translatedTextsObject.tripNo} & ${translatedTextsObject.shipmentControlNo}`,
      render: (data) => [data.tripNo, data.shipmentControlNo].join(', ')
    },
    {
      title: translatedTextsObject.responseDate,
      render: (data) =>
        data.responseDate &&
        moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
    },
    {
      title: translatedTextsObject.submissionType,
     render: (data) =>
       lookups
         ? lookups['submissionType'][data.submissionType]
         : data.submissionType
   },
   { field: 'submitId',
   title: translatedTextsObject.submitId,
   render: (data) => data.submitId }, 
   { field: 'methodOfSubmission',
   title: translatedTextsObject.methodOfSubmission,
   render: (data) => data.methodOfSubmission } 
  ]

  function fetchRecord(newPage, newsRowsPerPage){
    fetchPageableRecords.execute(
      AceHighwayResponseApiUrls.GET,
      {
        page: newPage,
        pageSize: newsRowsPerPage,
        filters: [...fetchFilters, ...filter],
        sorts: [sort]
      },
      (result) => {
        console.log("result",result)
        setTotalElements(result.totalElements)
        setData(result.content)
      },
      (error) => console.log(error),
      () => {
      }
    )
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key={data}>
            {data.length > 0 ? (
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={columns.length}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box padding={2}>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  )
}

export default ShipmentResponse
