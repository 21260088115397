import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHWStatusNotificationApiUrls from 'src/apiUrls/AceHWStatusNotificationApiUrls'
import pathMap from 'src/paths/pathMap'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import IconDescButton from '../../../components/button/IconDescButton'
import StatusNotificationDialog from './StatusNotificationDialog'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Box, Divider, Grid } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const {
  filter: { EQUAL }
} = constants

function StatusNotificationListPage(props) {
  const {
    history,
    manifestId,
    onChangeStep,
    onSetLoading,
    onSubmitManifest,
    showNotification,
    step,
    isABI
  } = props

  const [data, setData] = useState([])
  const [statusNotificationDialog, setStatusNotificationDialog] = useState({
    open: false,
    statusNotification: null,
    type: 'ADD'
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    statusNotification: null
  })
  const { createRecord, deleteRecord, fetchRecords, updateRecord } =
    useServices()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    fetchRecords.execute(
      AceHWStatusNotificationApiUrls.GET,
      {
        filters: [{ field: isABI?'abiInbondId':'shipmentId', operator: EQUAL, value: manifestId }]
      },
      (res) => {
        res.content.forEach((datum) => {
          datum.mobileNum = (datum.mobileNum && datum.mobileNum.substring(0,1) === '+') ? datum.mobileNum.substring(1) : datum.mobileNum
        });
        setData(res.content)
      }
    )
  }, [])

  useEffect(() => {
    data.forEach((datum) => {
      datum.mobileNum = (datum.mobileNum && datum.mobileNum.substring(0,1) === '+') ? datum.mobileNum.substring(1) : datum.mobileNum
    });
    setData(data)
    
  }, [data])

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.TITLE
    )
    let name = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.NAME
    )
    let snType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.SN_TYPE
    )
    let shipmentControlNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.SHIPMENT_CONTROL_NUM
    )
    let email = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.EMAIL
    )
    let mobileNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.MOBILE_NUM
    )
    let faxNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.FAX_NUM
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.DELETE_BUTTON
    )
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      title,
      name,
      snType,
      shipmentControlNum,
      email,
      mobileNum,
      faxNum,
      deleteButton,
      submitSuccessMsg
    }
  }

  function handleAddStatusNotification(datum) {
    onSetLoading(true)

    createRecord.execute(
      AceHWStatusNotificationApiUrls.POST,
      datum,
      (serverData) => {
        setData((prev) => [...prev, serverData])
        showNotification('success', translatedTextsObject.submitSuccessMsg)
      },
      (error) => {
        console.log(error)
        const { errorMessages } = error.response.data
        if(errorMessages){
          errorMessages.forEach((message) => {
            showNotification('error', message)
          })
        }else{
          if(error.message){
            showNotification('error', error.message)
          }else{
            showNotification('error', "Transaction Unsuccessful")
          }
        }
       
      },
      () => onSetLoading(false)
    )
  }

  function handleEditStatusNotification(datum) {
    onSetLoading(true)

    updateRecord.execute(
      AceHWStatusNotificationApiUrls.PUT,
      datum,
      (serverData) => {
        const clonedData = [...data]
        const index = data.findIndex((sn) => sn.id === datum.id)

        clonedData[index] = serverData

        setData(clonedData)
        showNotification('success', 'Status notification updated.')
      },
      (error) => {
        console.log(error)
        const { errorMessages } = error.response.data
        if(errorMessages){
          errorMessages.forEach((message) => {
            showNotification('error', message)
          })
        }else{
          if(error.message){
            showNotification('error', error.message)
          }else{
            showNotification('error', "Transaction Unsuccessful")
          }
        }
      },
      () => onSetLoading(false)
    )
  }

  function handleDeleteStatusNotification() {
    const { statusNotification } = confirmDialog

    if (statusNotification) {
      onSetLoading(true)

      deleteRecord.execute(
        AceHWStatusNotificationApiUrls.DELETE,
        statusNotification,
        () => {
          setData((prev) =>
            prev.filter((sn) => sn.id !== statusNotification.id)
          )
          setConfirmDialog({ open: false, statusNotification: null })
          showNotification('success', 'Delete succeeded')
        },
        (error) => {
          console.log(error)
          const { errorMessages } = error.response.data
          if(errorMessages){
            errorMessages.forEach((message) => {
              showNotification('error', message)
            })
          }else{
            if(error.message){
              showNotification('error', error.message)
            }else{
              showNotification('error', "Transaction Unsuccessful")
            }
          }
        },
        () => onSetLoading(false)
      )
    }
  }

  return (
    <>
      <CngSection title={translatedTextsObject.title} subheader='Optional'>
        <Grid container spacing={2}>
          {data.map((datum, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.name}>
                          {datum.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.email}>
                          {datum.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.snType}>
                          {datum.snType}
                        </CngField>
                      </Grid>
                      {!isABI && (
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField
                          label={translatedTextsObject.shipmentControlNum}
                        >
                          {datum.shipmentControlNum}
                        </CngField>
                      </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.mobileNum}>
                          {datum.mobileNum}
                        </CngField>
                      </Grid>
                      {!isABI && (
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.faxNum}>
                          {datum.faxNum}
                        </CngField>
                      </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'EDIT'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'copy']}
                              onClick={() =>
                                setStatusNotificationDialog({
                                  open: true,
                                  statusNotification: datum,
                                  type: 'ADD'
                                })
                              }
                              size='small'
                            />
                          </Grid>
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={() =>
                                setConfirmDialog({
                                  open: true,
                                  statusNotification: datum
                                })
                              }
                              size='small'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Box marginTop={2}>
          <IconDescButton
            description='Or clone the previous one'
            label='Add Status Notification'
            iconButtonProps={{
              icon: ['fal', 'plus'],
              onClick: () =>
                setStatusNotificationDialog({
                  open: true,
                  statusNotification: null,
                  type: 'ADD'
                })
            }}
          />
        </Box>
      </CngSection>
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                isABI ? history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW) : history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onChangeStep(step - 1)}
                  size='medium'
                >
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => onChangeStep(step + 1)}
                  size='medium'
                >
                  Continue
                </CngButton>
              </Grid>
              {/* <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={onSubmitManifest}
                  size='medium'
                >
                  Submit manifest
                </CngButton>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <StatusNotificationDialog
        open={statusNotificationDialog.open}
        onAddStatusNotification={handleAddStatusNotification}
        onClose={() =>
          setStatusNotificationDialog({
            open: false,
            statusNotification: null,
            type: 'ADD'
          })
        }
        onEditStatusNotification={handleEditStatusNotification}
        manifestId={manifestId}
        showNotification={showNotification}
        statusNotification={statusNotificationDialog.statusNotification}
        type={statusNotificationDialog.type}
        isABI={isABI}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() =>
          setConfirmDialog({ open: false, statusNotification: null })
        }
        confirmDialog={handleDeleteStatusNotification}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default StatusNotificationListPage
