import React, { useEffect, useCallback } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import Namespace from 'src/constants/locale/Namespace'
import CrewMasterKeys from 'src/constants/locale/key/CrewMaster'
import { Grid } from '@material-ui/core'
import { NaAceStateCodeAutoCompleteField} from 'src/components/na'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  docType: "",
  docNo: "",
  country: "",
  state: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
}

function Fields({
  disabled,
  shouldHideMap,
  index,
  parentIndex,
  isRequired
}) {
  const { setValue, getValues , watch } = useFormContext();
  const { translate } = useTranslation(Namespace.CREW_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `paxLSTInfo.${parentIndex}.internationalDoc.${index}.${field}` : field
  }, [])

  const country = watch(getFieldName('country'));

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let internationalDoc = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.TITLE
    )
    let docType = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.DOC_TYPE
    )
    let docNo = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.DOC_NO
    )
    let country = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.COUNTRY
    )
    let state = translate(
      Namespace.CREW_MASTER,
      CrewMasterKeys.InternationalDoc.STATE
    )

    return {
      internationalDoc,
      docType,
      docNo,
      country,
      state
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.docType}>
        <CngCodeMasterAutocompleteField
          required={isRequired}
          name={getFieldName('docType')}
          label={translatedTextsObject.docType}
          disabled={disabled}
          codeType='PAXLST_TRAVEL_DOC_TYPE'
          size='small'
          key={getValues(`paxLSTInfo.${parentIndex}.internationalDoc.${index}.docType`)}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.docNo}>
        <CngTextField
          required={isRequired}
          name={getFieldName('docNo')}
          label={translatedTextsObject.docNo}
          disabled={disabled}
          onBlur={(e) => { setValue(getFieldName('docNo'), e.target.value.toUpperCase()) }}
          inputProps={{ maxLength: 30 }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.country}>
        <CngCountryAutocompleteField
          required={isRequired}
          name={getFieldName('country')}
          label={translatedTextsObject.country}
          disabled={disabled}
          key={country}
          lookupProps={{
            label: (record) => {
              return `${record.code};${record.descriptionEn}`
            }
          }}
          onChange={() => { setValue(getFieldName('state'), '') }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.state}>
        <NaAceStateCodeAutoCompleteField
          name={getFieldName('state')}
          label={translatedTextsObject.state}
          disabled={disabled}
          countryCode={country}
          key={country}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const PAXLSTInternationalDocFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
})

export default PAXLSTInternationalDocFormProperties
