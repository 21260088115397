import AceHighwayResponseApiUrls from "src/apiUrls/AceHighwayResponseApiUrls";
import React from "react";
import { constants, useTranslation } from 'cng-web-lib'
import AceHighwayKeys from 'src/constants/locale/key/AceHighwayResponse';
import Namespace from 'src/constants/locale/Namespace';
import moment from 'moment-timezone'
import Table from '../../../components/aciacehighway/Table'

  const {
    filter: { BETWEEN, EQUAL, LIKE }
  } = constants  

  function MedpidResponse({ showNotification, headerId, docType }){
    const { translate } = useTranslation([Namespace.ACE_HIGHWAY_RESPONSE])
    const translatedTextsObject = makeTranslatedTextsObject()
    
    function makeTranslatedTextsObject() {
      let shipmentResponseTitle = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.RESPONSE_TITLE
      )

      let batchId = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.BATCH_ID
      )

      let cbsaProcessingDate = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.CBSA_PROCESSING_DATE
      )

      let CBPRefNo = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.CBP_REF_NO
      )
  
      let tripNo = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.TRIP_NO
      )

      let responseCode = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.RESPONSE_CODE
      )

      let responseText = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.RESPONSE_TEXT
      )

      let noOfPackage = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.NO_OF_PACKAGE
      )

      let entryNo = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.ENTRY_NO
      )

      let entryType = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.ENTRY_TYPE
      )

      let shipmentControlNo = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.SHIPMENT_CONTROL_NO
      )

      let responseDate = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.RESPONSE_DATE
      )

      let submissionType = translate(
        Namespace.ACE_HIGHWAY_RESPONSE,
        AceHighwayKeys.SUBMISSION_TYPE
      )
  
      return {
        shipmentResponseTitle,
        batchId,
        cbsaProcessingDate,
        CBPRefNo,
        tripNo,
        responseCode,
        responseText,
        noOfPackage,
        entryNo,
        entryType,
        shipmentControlNo,
        responseDate,
        submissionType
      }
    }

    const columns = [
      {
        field: 'cbsaProcessingDate',
        title: translatedTextsObject.cbsaProcessingDate,
        render: (data) =>
          data.cbsaProcessingDate &&
          moment(data.cbsaProcessingDate).tz('Canada/Eastern').format('D MMM YYYY - HH:mm')
      },
      {
        field: 'cbpRefNo',
        title: translatedTextsObject.CBPRefNo
      },
      {
        field: 'responseCode',
        title: translatedTextsObject.responseCode
      },
      {
        field: 'responseText',
        title: translatedTextsObject.responseText
      },
      {
        field: 'responseDate',
        title: translatedTextsObject.responseDate,
        render: (data) =>
          data.responseDate &&
          moment(data.responseDate).tz('Canada/Eastern').format('D MMM YYYY - HH:mm')
      }
    ]

    const filters = [
      {
        label: translatedTextsObject.cbsaProcessingDate,
        type: 'daterange',
        name: 'cbsaProcessingDate',
        operatorType: BETWEEN
      },
      {
        label: translatedTextsObject.CBPRefNo,
        type: 'textfield',
        name: 'cbpRefNo',
        operatorType: LIKE
      },
      {
        label: translatedTextsObject.responseCode,
        type: 'textfield',
        name: 'responseCode',
        operatorType: LIKE
      },
      {
        label: translatedTextsObject.responseText,
        type: 'textfield',
        name: 'responseText',
        operatorType: LIKE
      },
      {
        label: translatedTextsObject.responseDate,
        type: 'daterange',
        name: 'responseDate',
        operatorType: BETWEEN
      }
    ]
    
    return (
      <Table
        columns={columns}
        compact
        fetch={{ url: AceHighwayResponseApiUrls.GET }}
        fetchFilters={[
          { field: 'headerId', operator: EQUAL, value: headerId },
          { field: 'documentType', operator: EQUAL, value: docType }
        ]}
        filters={filters}
        showNotification={showNotification}
      />
    )
    
  }
  
  export default MedpidResponse;
  