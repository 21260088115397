import React, { useRef } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import EquipmentInfoFormProperties from './EquipmentInfoFormProperties'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'

const {
  button: { CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function EquipmentInfoDialog(props) {
  const { open, onClose, equipment, showNotification } = props
  const { initialValues, makeValidationSchema } =
    EquipmentInfoFormProperties.formikProps

  const lastIndex = useRef(0)
  const {
    getValues,
    setValue,
    formState: { isSubmitting }
  } = useFormContext()

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let equipmentInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.TITLE
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATE_BUTTON
    )

    return { equipmentInfo, saveButton, updateButton }
  }

  function handleAddEquipmentInfo(data) {
    const equipmentInfo = [...getValues('equipmentInfo'), data].map(
      (equipment) => ({ ...equipment, _id: ++lastIndex.current })
    )

    setValue('equipmentInfo', equipmentInfo)
    onClose()
  }

  function handleEditEquipmentInfo(data) {
    const equipmentInfo = [...getValues('equipmentInfo')]
    const index = equipmentInfo.findIndex((equipment) =>
      equipment.id ? equipment.id === data.id : equipment._id === data._id
    )

    equipmentInfo[index] = data

    setValue('equipmentInfo', equipmentInfo, { shouldValidate: true })
    onClose()
  }

  return (
    <CngDialog
      dialogContent={
        <CngForm
          fieldLevel='form'
          formikProps={{
            initialValues: equipment || initialValues,
            makeValidationSchema: makeValidationSchema,
            onSubmit: (data) => {
              if (equipment) {
                handleEditEquipmentInfo(data)
              } else {
                handleAddEquipmentInfo(data)
              }
            }
          }}
          formState={FormState.COMPLETED}
          innerForm={true}
          renderBodySection={(labelMap, shouldHideMap) => (
            <EquipmentInfoFormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          renderButtonSection={() => (
            <Box display='flex' justifyContent='flex-end'>
              <CngPrimaryButton type='submit' disabled={isSubmitting}>
                {equipment
                  ? translatedTextsObject.updateButton
                  : translatedTextsObject.saveButton}
              </CngPrimaryButton>
            </Box>
          )}
        />
      }
      dialogTitle={translatedTextsObject.equipmentInfo}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default EquipmentInfoDialog
