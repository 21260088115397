import makeValidationSchema from './EquipmentInfoMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from "react-hook-form";
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import { Grid, Divider, Typography, useTheme, IconButton, Box, InputAdornment } from '@material-ui/core'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import ToggleButtonGroup from '../../../components/aciacehighway/ToggleButtonGroup';
import CngSection from '../../../components/cngcomponents/CngSection';
import IconDescButton from '../../../components/button/IconDescButton';
import CheckboxButton from '../../../components/button/CheckboxButton';
import CollapsibleSection from '../../../components/ui/CollapsibleSection';
import EquipmentInfoTemplateDialog from './EquipmentInfoTemplateDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NaEquipmentMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetUserDetails, FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  button: { CngButton, CngIconButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants


const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  equipmentId: "",
  equipmentAceId: "",
  equipmentType: "",
  equipmentNo: "",
  licensePlateNo1: "",
  licensePlateCountry1: "",
  licensePlateState1: "",
  licensePlateNo2: "",
  licensePlateCountry2: "",
  licensePlateState2: "",
  licensePlateNo3: "",
  licensePlateCountry3: "",
  licensePlateState3: "",
  licensePlateNo4: "",
  licensePlateCountry4: "",
  licensePlateState4: "",
  iitCode1: "",
  iitCode2: "",
  iitCode3: "",
  iitCode4: "",
  sealNo1: "",
  sealNo2: "",
  sealNo3: "",
  sealNo4: "",
  sealNo5: "",
  sealNo6: "",
  sealNo7: "",
  sealNo8: "",
  sealNo9: "",
  aceFlag: false,
  saveEquipmentFlag: false,
  fetchEquipment: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, getValues, watch, trigger } = useFormContext();
  const theme = useTheme()
  const [templateDialog, setTemplateDialog] = useState(false)
  const [isLicenseNeed, setIsLicenseNeed] = useState(false)
  const [licensePlateCount, setLicensePlateCount] = useState(getLicensePlateCount())
  const aceFlagState = watch('aceFlag')
  const saveEquipmentFlagState = watch('saveEquipmentFlag')
  const equipmentTypeState = watch('equipmentType')
  const sealNos = watch([
    'sealNo1',
    'sealNo2',
    'sealNo3',
    'sealNo4',
    'sealNo5',
    'sealNo6',
    'sealNo7',
    'sealNo8',
    'sealNo9'
  ])
  const iitCodes = watch(['iitCode1', 'iitCode2', 'iitCode3', 'iitCode4'])
  const licensePlates = watch(['licensePlateNo1', 'licensePlateNo2', 'licensePlateNo3', 'licensePlateNo4'])

  useEffect(() => {
    const typeWithoutLicense = ["20", "2B", "40", "4B", "CL", "CU", "CZ", "NC", "OE"];
    if (typeWithoutLicense.includes(equipmentTypeState) || aceFlagState) {
      setIsLicenseNeed(false);
    } else {
      if (licensePlates.filter((value) => value !== '' && value !== null && value !== undefined).length === 0) {
        setIsLicenseNeed(true);
      } else {
        setIsLicenseNeed(false);
      }
    }
    trigger()
  }, [equipmentTypeState, aceFlagState, licensePlates])

  useEffect(() => {
    if (aceFlagState) {
      setValue('equipmentNo', '', { shouldValidate: true })
    } else {
      setValue('equipmentAceId', '', { shouldValidate: true })
    }
  }, [aceFlagState])

  function getLicensePlateCount() {
    const licensePlate1 = watch(['licensePlateNo1', 'licensePlateCountry1', 'licensePlateState1'])
    const licensePlate2 = watch(['licensePlateNo2', 'licensePlateCountry2', 'licensePlateState2'])
    const licensePlate3 = watch(['licensePlateNo3', 'licensePlateCountry3', 'licensePlateState3'])
    const licensePlate4 = watch(['licensePlateNo4', 'licensePlateCountry4', 'licensePlateState4'])

    const result = [licensePlate1, licensePlate2, licensePlate3, licensePlate4].reduce((acc, curr) => {
      acc += curr.filter((value) => value !== '').length > 0 ? 1 : 0
      return acc
    }, 0)

    return result === 0 ? 1 : result
  }

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let equipmentInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.ID
    )
    let headerId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.HEADER_ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.VERSION
    )
    let equipmentId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ID
    )
    let equipmentAceId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ACE_ID
    )
    let equipmentType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_TYPE
    )
    let equipmentNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_NO
    )
    let licensePlateNo1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_NO1
    )
    let licensePlateCountry1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_COUNTRY1
    )
    let licensePlateState1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_STATE1
    )
    let licensePlateNo2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_NO2
    )
    let licensePlateCountry2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_COUNTRY2
    )
    let licensePlateState2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_STATE2
    )
    let licensePlateNo3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_NO3
    )
    let licensePlateCountry3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_COUNTRY3
    )
    let licensePlateState3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_STATE3
    )
    let licensePlateNo4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_NO4
    )
    let licensePlateCountry4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_COUNTRY4
    )
    let licensePlateState4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_STATE4
    )
    let iitCode1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.IIT_CODE1
    )
    let iitCode2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.IIT_CODE2
    )
    let iitCode3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.IIT_CODE3
    )
    let iitCode4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.IIT_CODE4
    )
    let sealNo1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO1
    )
    let sealNo2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO2
    )
    let sealNo3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO3
    )
    let sealNo4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO4
    )
    let sealNo5 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO5
    )
    let sealNo6 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO6
    )
    let sealNo7 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO7
    )
    let sealNo8 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO8
    )
    let sealNo9 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.SEAL_NO9
    )
    let aceFlag = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.ACE_FLAG
    )

    let additionalLicensePlateInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_LICENSE_PLATE_TITLE
    )
    let additionalSealNoInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_SEAL_NO_TITLE
    )
    let additionalIITInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_IIT_TITLE
    )

    let additionalLicensePlateHideText = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_LICENSE_PLATE_HIDE_TEXT
    )
    let additionalLicensePlateShowText = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_LICENSE_PLATE_SHOW_TEXT
    )
    let additionalLicensePlateFooterText =  translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_LICENSE_PLATE_FOOTER_TEXT
    )

    let additionalSealNoHideText = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_SEAL_NO_HIDE_TEXT
    )
    let additionalSealNoShowText = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_SEAL_NO_SHOW_TEXT
    )
    let additionalSealNoFooterText =  translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_SEAL_NO_FOOTER_TEXT
    )

    let additionalIITHideText = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_IIT_HIDE_TEXT
    )
    let additionalIITShowText = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_IIT_SHOW_TEXT
    )
    let additionalIITFooterText =  translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ADDITIONAL_IIT_FOOTER_TEXT
    )
    let saveEquipmentFlag = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_EQUIPMENT_FLAG
    )
    let fetchEquipment = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.FETCH_EQUIPMENT
    )

    return {
      equipmentInfo,
      id,
      headerId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      equipmentId,
      equipmentAceId,
      equipmentType,
      equipmentNo,
      licensePlateNo1,
      licensePlateCountry1,
      licensePlateState1,
      licensePlateNo2,
      licensePlateCountry2,
      licensePlateState2,
      licensePlateNo3,
      licensePlateCountry3,
      licensePlateState3,
      licensePlateNo4,
      licensePlateCountry4,
      licensePlateState4,
      iitCode1,
      iitCode2,
      iitCode3,
      iitCode4,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      aceFlag,
      additionalLicensePlateInfo,
      additionalSealNoInfo,
      additionalIITInfo,
      additionalLicensePlateHideText,
      additionalLicensePlateShowText,
      additionalLicensePlateFooterText,
      additionalSealNoHideText,
      additionalSealNoShowText,
      additionalSealNoFooterText,
      additionalIITHideText,
      additionalIITShowText,
      additionalIITFooterText,
      saveEquipmentFlag,
      fetchEquipment
    }
  }

  function handleDuplicatePlate(index) {
    setValue(
      `licensePlateNo${licensePlateCount + 1}`,
      getValues(`licensePlateNo${index}`),
      { shouldValidate: true }
    )
    setValue(
      `licensePlateCountry${licensePlateCount + 1}`,
      getValues(`licensePlateCountry${index}`)
    )
    setValue(
      `licensePlateState${licensePlateCount + 1}`,
      getValues(`licensePlateState${index}`)
    )

    setLicensePlateCount((prev) => prev + 1)
  }

  function handleRemoveLicensePlate(removedIndex) {
    // Skips when removed index is the last index
    if (removedIndex !== licensePlateCount) {
      for (let i = removedIndex; i <= licensePlateCount; i++) {
        // Replace removed plate's values with the next plate's
        setValue(`licensePlateNo${i}`, getValues(`licensePlateNo${i + 1}`), {
          shouldValidate: true
        })
        setValue(
          `licensePlateCountry${i}`,
          getValues(`licensePlateCountry${i + 1}`)
        )
        setValue(
          `licensePlateState${i}`,
          getValues(`licensePlateState${i + 1}`)
        )
      }
    }

    // Remove last plate's values
    setValue(`licensePlateNo${licensePlateCount}`, '', { shouldValidate: true })
    setValue(`licensePlateCountry${licensePlateCount}`, '')
    setValue(`licensePlateState${licensePlateCount}`, '')

    setLicensePlateCount((prev) => prev - 1)
  }

  function handleClearLicensePlates() {
    setLicensePlateCount(1)
    setValue('licensePlateNo1', '')
    setValue('licensePlateState1', '')
    setValue('licensePlateCountry1', '')
    setValue('licensePlateNo2', '')
    setValue('licensePlateState2', '')
    setValue('licensePlateCountry2', '')
    setValue('licensePlateNo3', '')
    setValue('licensePlateState3', '')
    setValue('licensePlateCountry3', '')
    setValue('licensePlateNo4', '')
    setValue('licensePlateState4', '')
    setValue('licensePlateCountry4', '')
  }

  function handleApplyTemplate(template) {
    if (template) {
      const {
        equipmentId,
        equipmentType,
        equipmentNo,
        aceId,
        licensePlates,
      } = template
      
      if(aceId){
        setValue('aceFlag', true)
        setValue('equipmentAceId', aceId)
      }else{
        setValue('aceFlag', false)
      }
      setValue('equipmentId', equipmentId || '')
      setValue('equipmentType', equipmentType || '')
      setValue('equipmentNo', equipmentNo || '')

      if (licensePlates.length > 0) {
        licensePlates.forEach((licensePlate, index) => {
          setValue(`licensePlateNo${index + 1}`, licensePlate.licensePlateNo || '')
          setValue(`licensePlateState${index + 1}`, licensePlate.state || '')
          setValue( `licensePlateCountry${index + 1}`, licensePlate.countryofRegistration || '')
        })

        setLicensePlateCount(licensePlates.length)
      } else {
        handleClearLicensePlates()
      }

      showNotification('success', 'Template applied successfully.')
    } else {
      setValue('equipmentType', '')
      setValue('equipmentNo', '')
      setValue('equipmentAceId', '')

      handleClearLicensePlates()
    }

    trigger()
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <ToggleButtonGroup
                exclusive
                fullWidth
                onChange={(_, value) => {
                  if(value === null) return

                  setValue('aceFlag', value, { shouldValidate: true })

                  if (value) {
                    setValue('equipmentNo', '', { shouldValidate: true })
                  } else {
                    setValue('equipmentAceId', '', { shouldValidate: true })
                  }
                }}
                options={[
                  { label: 'With ACE no.', value: true },
                  { label: 'Without ACE no.', value: false }
                ]}
                value={aceFlagState}
              />
            </Grid>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.saveEquipmentFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {translatedTextsObject.saveEquipmentFlag}
                  </Typography>
                }
                name="saveEquipmentFlag"
                onChange={(e) =>
                  setValue('saveEquipmentFlag', e.target.checked, {
                    shouldValidate: true
                  })
                }
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.equipmentId}>
              <CngTextField
                name='equipmentId'
                label={translatedTextsObject.equipmentId}
                disabled={disabled || !saveEquipmentFlagState}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => {
                  setValue('equipmentId', e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3}>
              <NaEquipmentMasterAutoCompleteField
                name="fetchEquipment"
                label={translatedTextsObject.fetchEquipment}
                disabled={disabled}
                onChange={(_, options) => handleApplyTemplate(options.data)}
                size='small'
                fullWidth
                disableClearable
                textFieldProps={{
                  InputProps: {
                    customEndAdornment: () => (
                      <InputAdornment position='end' style={{ marginTop: -16 }}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation()
                          setTemplateDialog(true)
                        }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
                forcePopupIcon={false}
                lookupProps={{
                  filters: [{
                    field: 'partyId',
                    operator: EQUAL,
                    value: FileForUserGetPartyId()
                  }]
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={!aceFlagState || shouldHideMap.equipmentAceId}>
              <CngTextField
                required
                size='small'
                name="equipmentAceId"
                label={translatedTextsObject.equipmentAceId}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={aceFlagState || shouldHideMap.equipmentNo}>
              <CngTextField
              	required={equipmentTypeState!="NC"}
                size='small'
                name="equipmentNo"
                label={translatedTextsObject.equipmentNo}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.equipmentType}>
              <CngCodeMasterAutocompleteField
                required
                size='small'
                name='equipmentType'
                label={translatedTextsObject.equipmentType}
                disabled={disabled}
                codeType='ACE_HW_EQUIPMENT_TYPE'
                key={equipmentTypeState}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="id"
                label={translatedTextsObject.id}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="headerId"
                label={translatedTextsObject.headerId}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="createdBy"
                label={translatedTextsObject.createdBy}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngDateField
                name="createdDate"
                label={translatedTextsObject.createdDate}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="updatedBy"
                label={translatedTextsObject.updatedBy}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngDateField
                name="updatedDate"
                label={translatedTextsObject.updatedDate}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="submittedBy"
                label={translatedTextsObject.submittedBy}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngDateField
                name="submittedDate"
                label={translatedTextsObject.submittedDate}
                disabled={disabled}
              />
            </CngGridItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CngSection title='License plate'>
            <Grid container spacing={1}>
              {[...Array(licensePlateCount)]
                .map((_, index) => index + 1)
                .map((count, index, array) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <CngGridItem
                          xs={12}
                          md={4}
                          shouldHide={shouldHideMap[`licensePlateNo${count}`]}
                        >
                          <CngTextField
                            size='small'
                            required={isLicenseNeed}
                            name={`licensePlateNo${count}`}
                            label={translatedTextsObject[`licensePlateNo${count}`]}
                            disabled={disabled}
                          />
                        </CngGridItem>
                        <CngGridItem
                          xs={12}
                          md={4}
                          shouldHide={
                            shouldHideMap[`licensePlateCountry${count}`]
                          }
                        >
                          <CngCodeMasterAutocompleteField
                            size='small'
                            required={getValues(`licensePlateNo${count}`) !== null && getValues(`licensePlateNo${count}`) !== "" ? true : false}
                            name={`licensePlateCountry${count}`}
                            label={translatedTextsObject[`licensePlateCountry${count}`]}
                            disabled={disabled}
                            codeType='ACIHWY_MANIFEST_COUNTRY'
                            key={getValues(`licensePlateCountry${count}`)}
                            onChange={() => {
                              setValue(`licensePlateState${count}`, '')
                            }}
                            lookupProps={{
                              label: (record) => {
                                return `${record.code};${record.descriptionEn}`
                              }
                            }}
                          />
                        </CngGridItem>
                        <CngGridItem
                          xs={12}
                          md={4}
                          shouldHide={shouldHideMap[`licensePlateState${count}`]}
                        >
                          <Grid container spacing={1} alignItems='center'>
                            <CngGridItem xs>
                              <NaAceStateCodeAutoCompleteField
                                size='small'
                                required={getValues(`licensePlateNo${count}`) !== null && getValues(`licensePlateNo${count}`) !== "" ? true : false }
                                name={`licensePlateState${count}`}
                                label={translatedTextsObject[`licensePlateState${count}`]}
                                disabled={disabled}
                                countryCode={getValues(`licensePlateCountry${count}`)}
                                key={getValues(`licensePlateCountry${count}`)}
                              />
                            </CngGridItem>
                            <CngGridItem xs='auto'>
                              <CngIconButton
                                disabled={licensePlateCount > 3}
                                type='outlined'
                                icon={['fal', 'plus']}
                                onClick={() => setLicensePlateCount((prev) => prev + 1)}
                                size='small'
                              />
                            </CngGridItem>
                            <CngGridItem xs='auto'>
                              <CngIconButton
                                disabled={licensePlateCount > 3}
                                type='outlined'
                                icon={['fal', 'copy']}
                                onClick={() => handleDuplicatePlate(count)}
                                size='small'
                              />
                            </CngGridItem>
                            {count !== 1 && (
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'trash']}
                                  onClick={() => handleRemoveLicensePlate(count)}
                                  size='small'
                                />
                              </CngGridItem>
                            )}
                          </Grid>
                        </CngGridItem>
                      </Grid>
                    </Grid>
                    {array.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={iitCodes.filter((iitCode) => iitCode).length > 0}
            title='Instruments of International Traffic - IIT'
            footerText={translatedTextsObject.additionalIITFooterText}
            hideText={translatedTextsObject.additionalIITHideText}
            showText={translatedTextsObject.additionalIITShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.iitCode1}>
                <CheckboxButton
                  name='iitCode1'
                  label="EC"
                  description={translatedTextsObject.iitCode1}
                  disabled={disabled}
                  checked={iitCodes[0]}
                  onChange={(e) =>
                    setValue('iitCode1', e.target.checked, {
                      shouldValidate: true
                    })
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.iitCode2}>
                <CheckboxButton
                  name='iitCode2'
                  label="EI"
                  description={translatedTextsObject.iitCode2}
                  disabled={disabled}
                  checked={iitCodes[1]}
                  onChange={(e) =>
                    setValue('iitCode2', e.target.checked, {
                      shouldValidate: true
                    })
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.iitCode3}>
                <CheckboxButton
                  name='iitCode3'
                  label="MC"
                  description={translatedTextsObject.iitCode3}
                  disabled={disabled}
                  checked={iitCodes[2]}
                  onChange={(e) =>
                    setValue('iitCode3', e.target.checked, {
                      shouldValidate: true
                    })
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.iitCode4}>
                <CheckboxButton
                  name='iitCode4'
                  label="MI"
                  description={translatedTextsObject.iitCode4}
                  disabled={disabled}
                  checked={iitCodes[3]}
                  onChange={(e) =>
                    setValue('iitCode4', e.target.checked, {
                      shouldValidate: true
                    })
                  }
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={sealNos.filter((sealNo) => sealNo).length > 0}
            title='Seal nos.'
            footerText={translatedTextsObject.additionalSealNoFooterText}
            hideText={translatedTextsObject.additionalSealNoHideText}
            showText={translatedTextsObject.additionalSealNoShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo1}>
                <CngTextField
                  size='small'
                  name='sealNo1'
                  label={translatedTextsObject.sealNo1}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo2}>
                <CngTextField
                  size='small'
                  name='sealNo2'
                  label={translatedTextsObject.sealNo2}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo3}>
                <CngTextField
                  size='small'
                  name='sealNo3'
                  label={translatedTextsObject.sealNo3}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo4}>
                <CngTextField
                  size='small'
                  name='sealNo4'
                  label={translatedTextsObject.sealNo4}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo5}>
                <CngTextField
                  size='small'
                  name='sealNo5'
                  label={translatedTextsObject.sealNo5}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo6}>
                <CngTextField
                  size='small'
                  name='sealNo6'
                  label={translatedTextsObject.sealNo6}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo7}>
                <CngTextField
                  size='small'
                  name='sealNo7'
                  label={translatedTextsObject.sealNo7}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo8}>
                <CngTextField
                  size='small'
                  name='sealNo8'
                  label={translatedTextsObject.sealNo8}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNo9}>
                <CngTextField
                  size='small'
                  name='sealNo9'
                  label={translatedTextsObject.sealNo9}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
      <EquipmentInfoTemplateDialog
        open={templateDialog}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
      />
    </>
	)
}

const EquipmentInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default EquipmentInfoFormProperties
