import React from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../../components/cngcomponents/CngSection'
import ManifestResponse from '../../response/ManifestResponse'
import TripResponse from '../../response/TripResponse'
import ShipmentResponse from '../../response/ShipmentResponse'
import PAXLSTResponse from '../../response/PAXLSTResponse'
import Grid from '@material-ui/core/Grid';
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayKeys from 'src/constants/locale/key/AceHighwayResponse'
import { constants, useServices, useTranslation } from 'cng-web-lib'

const { CngTabs } = components

function ResponseLog(props) {
  const { manifestId } = props
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_RESPONSE)
  const translatedTextsObject = makeTranslatedTextsObject()
  function makeTranslatedTextsObject() {
    let manifestResponseTitle = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.MANIFEST_RESPONSE_TITLE
    )
    let tripResponseTitle = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.TRIP_RESPONSE_TITLE
    )
    let shipmentResponseTitle = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.SHIPMENT_RESPONSE_TITLE
    )
    let paxlstResponseTitle = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AceHighwayKeys.PAXLST_RESPONSE_TITLE
    )
    return {
      manifestResponseTitle,
      tripResponseTitle,
      shipmentResponseTitle,
      paxlstResponseTitle
    }
  }
  return (
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.manifestResponseTitle}>
          <ManifestResponse
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.tripResponseTitle}>
          <TripResponse
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.shipmentResponseTitle}>
          <ShipmentResponse
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
	  <Grid item xs={12}>
        <CngSection title={translatedTextsObject.paxlstResponseTitle}>
          <PAXLSTResponse
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ResponseLog
