import React from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import PropagateForm from '../../manifest/shipment/PropagateForm'
import ShipmentViewContent from '../../shipment/ShipmentViewContent'
import Box from '@material-ui/core/Box'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function PropagateDialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    isView,
    loading,
    manifestId,
    onClose,
    handlePropagateUpdateShipment,
    open,
    propagateShipments,
    showNotification
  } = props
  const { initialValues, makeValidationSchema } = PropagateForm.formikProps
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATE_BUTTON
    )
    let updateShipmentInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATE_SHIPMENT_INFO
    )

    return { saveButton, updateButton, updateShipmentInfo }
  }

  return (
    <CngDialog
      dialogContent={
        <CngForm
          fieldLevel='form'
          formikProps={{
            initialValues: {
              ...(initialValues),
              manifestId: manifestId,
              aceHighwayShipmentHeaders: propagateShipments
            },
            makeValidationSchema: makeValidationSchema,
            onSubmit: (data) => {
              handlePropagateUpdateShipment(data);
            }
          }}
          formState={FormState.COMPLETED}
          renderBodySection={(labelMap, shouldHideMap) => (
            <PropagateForm.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          innerForm={true}
          renderButtonSection={() => (
            <Box display='flex' justifyContent='flex-end'>
              <CngButton
                color='primary'
                size='medium'
                type='submit'
                disabled={loading}
                shouldShowProgress={loading}
              >
                {translatedTextsObject.updateShipmentInfo}
              </CngButton>
            </Box>
          )}
        />
      }
      // dialogAction={
      //   <CngButton onClick={onClose}>OK</CngButton>
      // }
      dialogTitle='Propagate Shipments'
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default PropagateDialog
