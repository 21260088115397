import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AceHighwayManifestKeys from '../../../constants/locale/key/AceHighwayManifest'
import EquipmentMasterApiUrls from '../../../apiUrls/EquipmentMasterApiUrls'
import NaCodeMaintenanceApiURLs from '../../../apiUrls/NaCodeMaintenanceApiUrls'
import CngField from 'src/components/cngcomponents/CngField'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import _ from 'lodash'
import { Box, Grid, Typography } from '@material-ui/core'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, LIKE }
} = constants

function EquipmentInfoTemplateDialog(props) {
  const { open, ...otherProps } = props

  const [lookups, setLookups] = useState(null)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    if (open) {
      Promise.all([
        // Equipment type
        fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          undefined,
          [
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACE_HW_EQUIPMENT_TYPE'
            }
          ],
          undefined,
          'code'
        ),
        // Countries
        fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          undefined,
          [
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACIHWY_MANIFEST_COUNTRY'
            }
          ],
          undefined,
          'code'
        ),
        // Country states
        fetchRecords.execute(
          NaCodeMaintenanceApiURLs.GET,
          {
            filters: [{ field: 'indicator', operator: EQUAL, value: 'ACE' }],
            customData: { codeMType: 'CountryStateCode' }
          },
          (res) => res.content
        )
      ]).then(([equipmentType, countries, countryStates]) => {
        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          equipmentType,
          countries,
          countryStates: states
        })
      })
    }
  }, [open])

  function makeTranslatedTextsObject() {
    let equipmentAceId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ACE_ID
    )
    let equipmentId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ID
    )
    let equipmentType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_TYPE
    )

    return {
      equipmentAceId,
      equipmentId,
      equipmentType
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  const columns = [
    {
      title: `${translatedTextsObject.equipmentAceId} & ${translatedTextsObject.equipmentId}`,
      render: (template) => (
        <Box>
          <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
            {template.aceId}
          </Typography>
          <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
            {template.equipmentId}
          </Typography>
        </Box>
      )
    },
    {
      title: translatedTextsObject.equipmentType,
      render: (template) => (
        <Typography component='span' variant='inherit'>
          {getLookupValue('equipmentType', template.equipmentType)}
          <TinyChip label={template.equipmentType} variant='outlined' />
        </Typography>
      )
    }
  ]

  const extraFields = [
    {
      label: 'License plates',
      gridProps: { sm: 12, md: 12 },
      render: (template) =>
        template.licensePlates.length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {template.licensePlates.map((licensePlate) => (
                <Grid item key={licensePlate.id} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label='License plate no.'>
                        {licensePlate.licensePlateNo}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label='Country registration'>
                        {getLookupValue(
                          'countries',
                          licensePlate.countryofRegistration
                        )}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CngField label='Province/state/region registration'>
                        {getCountryStateLabel(
                          licensePlate.countryofRegistration,
                          licensePlate.state
                        )}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      extraFields={extraFields}
      defaultSorts={[{ field: 'equipmentId', direction: 'ASC' }]}
      fetch={{ url: EquipmentMasterApiUrls.GET }}
      fetchFilters={[
        { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
      ]}
      open={open}
      search={{ field: 'equipmentId', operator: LIKE }}
      title='Equipment info template'
      {...otherProps}
    />
  )
}

export default EquipmentInfoTemplateDialog
