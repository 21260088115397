import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { getStatusMetadata } from '../../../../common/NACommon'
import CngField from '../../../../components/cngcomponents/CngField'
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton },
  CngDialog
} = components

function SetResponseMessageDialog(props) {
  const {
    amendmentCodeOptions,
    onClose,
    onSaveShipment,
    onSubmitShipment,
    open,
    shipments,
    translatedTextsObject
  } = props

  const shipmentTypes = [
    ...new Set(shipments.map((shipment) => shipment.shipmentType))
  ].reduce(
    (acc, shipmentType) => {
      if (shipmentType === 'IN-BOND') {
        acc['IN-BOND'] = getStatusList({ isINBOND: true })
      } else {
        acc['Others'] = getStatusList()
      }

      return acc
    },
    { 'IN-BOND': [], Others: [] }
  )

  function getStatusList(config = { isINBOND: false }) {
    const { isINBOND } = config
    const result = []
    const statusList = [
      ...new Set(
        shipments
          .filter((shipment) =>
            isINBOND
              ? shipment.shipmentType === 'IN-BOND'
              : shipment.shipmentType !== 'IN-BOND'
          )
          .map((shipment) => shipment.status)
      )
    ]

    statusList.forEach((status) => {
      result.push({
        data: { code: status, ...getStatusMetadata(status) },
        shipments: shipments.filter(
          (shipment) =>
            (isINBOND
              ? shipment.shipmentType === 'IN-BOND'
              : shipment.shipmentType !== 'IN-BOND') &&
            shipment.status === status
        )
      })
    })

    return result
  }

  return (
    <CngDialog
      dialogContent={
        <Box paddingTop={1}>
          <Grid container spacing={2}>
            {Object.entries(shipmentTypes).map(([shipmentType, statusList]) => (
              <Grid key={shipmentType} item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      color='textSecondary'
                      variant='body2'
                      style={{ fontWeight: 700 }}
                    >
                      {shipmentType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {statusList.map((status, index) => (
                      <Grid key={index} item xs={12}>
                        <StatusItem
                          amendmentCodeOptions={amendmentCodeOptions}
                          index={index}
                          status={status}
                          translatedTextsObject={translatedTextsObject}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onSaveShipment(shipments)}
                  size='medium'
                >
                  Save
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => {
                    onSubmitShipment(shipments)
                    onClose()
                  }}
                  size='medium'
                >
                  Submit to customs
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Set response message'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default SetResponseMessageDialog

function StatusItem(props) {
  const {
    amendmentCodeOptions,
    index,
    status: { data, shipments },
    translatedTextsObject
  } = props

  const [expanded, setExpanded] = useState(false)
  const [messageFunction, setMessageFunction] = useState('23')
  const [amendmentCode, setAmendmentCode] = useState(null)
  const [isSplitShipment, setIsSplitShipment] = useState(false)

  const shipmentTypes = [
    ...new Set(shipments.map((shipment) => shipment.shipmentType))
  ]

  const isINBONDShipmentType =
    shipmentTypes.length === 1 && shipmentTypes[0] === 'IN-BOND'

  useEffect(() => {
    shipments.forEach((shipment) => {
      shipment.messageFunction = messageFunction
      shipment.amendmentCode = amendmentCode
    })
  }, [messageFunction, amendmentCode])

  useEffect(() => {
    setIsSplitShipment(shipments.find((shipment) => {
      return shipment.splitIndicator
    }))
  }, [])

  function handleMessageFunctionChange(event) {
    if (event.target.value === '24') {
      setAmendmentCode(isINBONDShipmentType ? '16' : '03')
    } else {
      setAmendmentCode(null)
    }

    setMessageFunction(event.target.value)
  }

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md='auto'>
            <Typography variant='body1'>
              <Typography
                variant='inherit'
                style={{
                  color: data?.color || 'inherit',
                  fontWeight: 600
                }}
              >
                {data?.label}
              </Typography>
              &nbsp;
              <Typography variant='inherit'>({shipments.length})</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor={`message-function-label-${index}`}>
                    {translatedTextsObject.messageFunction}
                  </InputLabel>
                  <Select
                    label={translatedTextsObject.messageFunction}
                    inputProps={{
                      id: `message-function-label-${index}`
                    }}
                    native
                    onChange={handleMessageFunctionChange}
                    size='small'
                    value={messageFunction}
                  >
                    <option value='23'>
                      {translatedTextsObject.msgFuncAdd}
                    </option>
                    {!isSplitShipment && 
                      <option value='24'>
                        {translatedTextsObject.msgFuncModify}
                      </option>
                    }
                    <option value='22'>
                      {isSplitShipment ? translatedTextsObject.msgFuncDelink : translatedTextsObject.msgFuncCancel}
                    </option>
                  </Select>
                </FormControl>
              </Grid>
              {messageFunction === '24' && (
                <Grid item xs={12} md>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor={`amendment-code-label-${index}`}>
                      {translatedTextsObject.amendmentCode}
                    </InputLabel>
                    <Select
                      label={translatedTextsObject.amendmentCode}
                      inputProps={{
                        id: `amendment-code-label-${index}`
                      }}
                      native
                      onChange={(event) => setAmendmentCode(event.target.value)}
                      size='small'
                      value={amendmentCode}
                    >
                      {amendmentCodeOptions
                        .filter(
                          (option) =>
                            (isINBONDShipmentType && option.code > 15) ||
                            (!isINBONDShipmentType &&
                              option.code !== '16' &&
                              option.code !== '17' &&
                              option.code !== '26')
                        )
                        .map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.desc}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md='auto'>
            <CngIconButton
              icon={['fal', expanded ? 'chevron-up' : 'chevron-down']}
              onClick={() => setExpanded((prev) => !prev)}
              size='small'
              type='outlined'
            />
          </Grid>
        </Grid>
        <Collapse in={expanded}>
          <Box marginTop={2}>
            <Grid container spacing={2}>
              {shipments.map((shipment, index) => (
                <React.Fragment key={index}>
                  <Grid key={index} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography component='div' variant='inherit'>
                          {shipment.scn}
                        </Typography>
                        <Typography color='textSecondary' variant='caption'>
                          {[shipment.shipmentType, shipment.acquittalNo]
                            .filter((value) => value)
                            .join(' • ')}
                        </Typography>
                      </Grid>
                      {shipment.splitIndicator && (
                        <>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.pointOfLoading}>
                            {shipment.pointOfLoading}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.quantity}>
                            {shipment.quantity}
                          </CngField>
                        </Grid>
                        </>
                      )}
                      {!shipment.splitIndicator && (
                        <>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.shipperName}>
                            {shipment.shipperName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.consigneeName}>
                            {shipment.consigneeName}
                          </CngField>
                        </Grid>
                      </>
                      )}
                    </Grid>
                  </Grid>
                  {shipments.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Collapse>
      </Box>
    </Paper>
  )
}
