import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function EquipmentInfoTable(props) {
  const {
    data,
    onAddEquipmentInfo,
    onDeleteEquipmentInfo,
    onEditEquipmentInfo
  } = props

  const [lookups, setLookups] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const classes = useStyles()

  const filtered =
    searchTerm !== ''
      ? data.filter((equipment) =>
          equipment.equipmentNo.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : data

  useEffect(() => {
    async function fetchData() {
      const data = await fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          { field: 'codeType', operator: EQUAL, value: 'ACE_HW_EQUIPMENT_TYPE' }
        ],
        undefined,
        'code'
      )

      setLookups({ equipmentType: data })
    }

    fetchData()
  }, [])

  function makeTranslatedTextsObject() {
    let equipmentNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_NO
    )
    let equipmentType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_TYPE
    )
    let equipmentAceId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ACE_ID
    )
    let licensePlateNo1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.LICENSE_PLATE_NO1
    )

    return { equipmentNo, equipmentType, equipmentAceId, licensePlateNo1 }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddEquipmentInfo && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddEquipmentInfo}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add equipment
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.equipmentNo}</TableCell>
              <TableCell>{translatedTextsObject.equipmentType}</TableCell>
              <TableCell>{translatedTextsObject.equipmentAceId}</TableCell>
              <TableCell>{translatedTextsObject.licensePlateNo1}</TableCell>
              {(onEditEquipmentInfo || onDeleteEquipmentInfo) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((equipment, index) => (
                <TableRow key={equipment._id || equipment.id || index}>
                  <TableCell>{equipment.equipmentNo}</TableCell>
                  <TableCell>
                    {getLookupValue('equipmentType', equipment.equipmentType)}
                  </TableCell>
                  <TableCell>{equipment.equipmentAceId}</TableCell>
                  <TableCell>{equipment.licensePlateNo1}</TableCell>
                  {(onEditEquipmentInfo || onDeleteEquipmentInfo) && (
                    <TableCell>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-end'
                        style={{ gap: 8 }}
                      >
                        {onEditEquipmentInfo && (
                          <CngIconButton
                            type='outlined'
                            icon={['fal', 'pen']}
                            onClick={() => onEditEquipmentInfo(equipment)}
                            size='small'
                          />
                        )}
                        {onDeleteEquipmentInfo && (
                          <CngIconButton
                            type='outlined'
                            icon={['fal', 'trash']}
                            onClick={() => onDeleteEquipmentInfo(equipment)}
                            size='small'
                          />
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    3 + (onEditEquipmentInfo || onDeleteEquipmentInfo ? 1 : 0)
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default EquipmentInfoTable
