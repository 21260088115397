import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {constants} from 'cng-web-lib'
import ManifestKeys from 'src/constants/locale/key/AceHighwayManifest'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let faxValid=translate(
    Namespace.ACE_HIGHWAY_MANIFEST,
    ManifestKeys.Validations.FAX_VALID
  )

  return Yup.object({
    fax: Yup.string().required(requiredMessage).trim().matches("[0-9]$", "numbers only, without spaces")
    .test("fax",faxValid,function(){
      let fax = this.parent["fax"];
      if(fax.startsWith("1"))
        return true;
      else
        return false;
    }).min(11, "Min length allowed is 11.").nullable(),
    subject: Yup.string().nullable(),
    message: Yup.string().nullable(),
  }) 
}

export default makeValidationSchema
