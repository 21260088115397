import React, { useRef, useState, useEffect } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import moment from 'moment-timezone'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import AceHighwayManifestNoteLogApiUrls from 'src/apiUrls/AceHighwayManifestNoteLogApiUrls'
import AceHighwayManifestFormProperties from './FormProperties'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  CngDialog,
} = components

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#282C34' : theme.palette.grey[100],
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function NoteFlagDialog(props) {
  const {
    manifestId,
    openDialog,
    onClose,
    showNotification,
    tableRef
  } = props

  const { initialValues, makeValidationSchema } = AceHighwayManifestFormProperties.formikProps
  const noteValue = useRef()
  const [validateNote, setValidateNote] = useState("")
  const [invalidChar, setInvalidChar] = useState("")
  const { createRecord, updateRecord } = useServices()
  const { translate } = useTranslation(Namespace.ACEHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let noteContent = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      ManifestKeys.NOTE_CONTENT
    )

    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      ManifestKeys.SAVE_BUTTON
    )

    return { noteContent, saveButton }
  }

  useEffect(() => {
    let invalidCharacterList = []
    validateNote.split("").map((character, index) => {
      const filterRegex = /^[A-Za-z0-9\w.+\-\=\/:_();,{}\[\]\@\.\"\#\$\|%&!'^?\\ ]*$/.exec(character)

      if (!filterRegex) {
        noteValue.current.setSelectionRange(index, index + 1);
        noteValue.current.focus();
        if (character.search(/\t/) === 0 && !invalidCharacterList.includes("tab")) {
          invalidCharacterList.push("tab")
        } else if (!invalidCharacterList.includes(character)) {
          invalidCharacterList.push(character)
        }
      }

      setInvalidChar(invalidCharacterList)
    })

    if (validateNote === "") {
      setInvalidChar("")
    }
  }, [validateNote]);

  function handleSaveNote() {
    if (openDialog.manifest) {
      processToServerData();

      openDialog.manifest.reminderFlag = true
      let currNote = openDialog.manifest.reminderNote

      if (!openDialog.manifest.reminderNote || (openDialog.manifest.reminderNote.trim() != noteValue.current.value.trim())) {
        openDialog.manifest.reminderNote = noteValue.current.value

        const formData = new FormData()
        formData.append('reminderNote', noteValue.current.value)
        formData.append('manifestId', openDialog.manifest.id)

        createRecord.execute(
          AceHighwayManifestNoteLogApiUrls.POST,
          formData,
          () => {
            if (!noteValue.current.value) {
              showNotification('success', 'Note cleared successfully')
              onClose()
              tableRef.current.performRefresh()
            } else {
              postSaveNote(openDialog.manifest.id, openDialog.manifest.reminderNote)
            }
          },
          (error) => {
            showNotification('error', 'Something went wrong while saving note.')
            openDialog.manifest.reminderNote = currNote
            noteValue.current.value = null
          }
        )
      } else {
        showNotification('success', 'Note updated successfully')
        onClose()
      }
    }
  }

  function postSaveNote(manifestId, reminderNote) {
    let fileForUserDetails = FileForUserGetUserDetails();

    createRecord.execute(
      AceHighwayManifestNoteLogApiUrls.POST,
      {
        manifestId: manifestId,
        reminderNote: reminderNote,
        fileForUserId: fileForUserDetails ? fileForUserDetails.fileForUserId : null,
        fileForUserLoginId: fileForUserDetails ? fileForUserDetails.fileForUserLoginId : null,
        fileForUserPartyId: fileForUserDetails ? fileForUserDetails.fileForUserPartyId : null
      },

      () => {
        showNotification('success', 'Note updated successfully')
        onClose()
        tableRef.current.performRefresh()
      },
      (error) => {
        showNotification('error', 'Something went wrong while saving note log.')
        onClose()
        tableRef.current.performRefresh()
      }
    )
  }

  function handleClearNote() {
    if (openDialog.manifest) {
      processToServerData();

      openDialog.manifest.reminderFlag = true
      openDialog.manifest.reminderNote = null

      updateRecord.execute(
        AceHighwayManifestApiUrls.CUSTOM_UPDATE,
        openDialog.manifest,
        () => {
          showNotification('success', 'Note cleared successfully')
          onClose()
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong while clearing note.')
          console.log(error)
        }
      )
    }
  }

  function processToServerData() {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      openDialog.manifest.fileForUserId = fileForUserDetails.fileForUserId;
      openDialog.manifest.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      openDialog.manifest.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    var arrivalDateTime = openDialog.manifest.arrivalDate;
    openDialog.manifest.arrivalDate = moment(arrivalDateTime).format('YYYY-MM-DDTHH:mmZ');

    var submittedDateTime = openDialog.manifest.submittedDate;
    if (submittedDateTime) {
      openDialog.manifest.submittedDate = moment(submittedDateTime).format('YYYY-MM-DDTHH:mmZ');
    }

  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
              <Typography variant='body2'>{translatedTextsObject.noteContent}:</Typography>
              <Typography variant='body2' style={{ color: "#f44336" }}>Max 300 characters</Typography>
              <TextField
                placeholder="Note for manifest"
                defaultValue={openDialog.manifest && openDialog.manifest.reminderNote ? openDialog.manifest.reminderNote : null}
                multiline
                fullWidth
                rows={10}
                variant="outlined"
                inputRef={noteValue}
                inputProps={{ maxLength: 300 }}
                onChange={(e) => {
                  noteValue.current.value = e.target.value.replace(/(\r\n|\r|\n)+/g, ' ')
                  setValidateNote(noteValue.current.value)
                }}
              />
              {invalidChar && invalidChar.length > 0 &&
                <Typography variant='body2' style={{ color: "#f44336" }}>Invalid Character Found: {invalidChar}</Typography>
              }
              </Box>
            <Box display='flex' flexDirection='row-reverse' mt={2}>
              <Box flexGrow={1}></Box>
            </Box>

            <NoteLog manifestId={openDialog.manifest && openDialog.manifest.id ? openDialog.manifest.id : null} />

          </Box>
        </Paper>
      }
      dialogAction={
        <>
          <CngButton color='secondary' onClick={() => {
            noteValue.current.value = null
            setInvalidChar("")
          }}>Reset Note</CngButton>
          <CngButton color='primary' disabled={invalidChar && invalidChar.length > 0 ? true : false} onClick={handleSaveNote}>{translatedTextsObject.saveButton}</CngButton>
        </>
      }
      dialogTitle={
        <>
          <b>"Reminder Notes"</b>
          <CloseDialogIconButton onClick={onClose} />
        </>
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={openDialog.open}
    />
  )
}

export default NoteFlagDialog

function NoteLog(props) {
  const { manifestId } = props

  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const { fetchRecords } = useServices()
  const style = useStyles()

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(AceHighwayManifestNoteLogApiUrls.GET,
        {
          filters: [{
            field: 'manifestId',
            operator: 'equal',
            value: manifestId
          }]
        }, (res) => res.content)
    ]).then(([respData]) => setData(
      respData.sort(function (a, b) {
        return new Date(b.createdDate) - new Date(a.createdDate);
      })
    ))
  }, [])

  const columns = [
    {
      field: 'createdDate',
      title: 'Created Date (EST)',
      render: (data) =>
        data.createdDate &&
        moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'username', title: 'Username',
      render: (data) => data.username
    },
    {
      field: 'reminderNote',
      title: 'Note',
      render: (data) => data.reminderNote
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}