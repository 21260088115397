import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { Box, Divider, Grid, Typography, Tooltip, IconButton, InputAdornment } from '@material-ui/core'
import { FileForUserGetUserDetails, FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { NaAceStateCodeAutoCompleteField, NaCrewMasterAutoCompleteField, NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { ACEHighwayTranslationContext } from '../contexts/ACEHighwayTranslationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AciAceCrewTypeMapping } from 'src/common/AciAceCrossMapping'
import Alert from '../../../components/Alert'
import Switch from '../../../components/Switch'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import CngSection from '../../../components/cngcomponents/CngSection'
import ToggleButtonGroup from '../../../components/aciacehighway/ToggleButtonGroup'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import PAXLSTInternationalDocEntry from './PAXLSTInternationalDocEntry'
import PAXLSTInternationalDocFormProperties from './PAXLSTInternationalDocFormProperties'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import CrewMasterApiUrls from '../../../apiUrls/CrewMasterApiUrls'
import moment from 'moment-timezone'


const {
  button: { CngIconButton },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    },
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  dob: null,
  nationality: '',
  gender: '',
  partyType: '',
  address1: '',
  address2: '',
  address3: '',
  cityName: '',
  postalCode: '',
  country: 'US',
  state: '',
  telNo: '',
  email: '',
  internationalDoc: [],
  hazmatEndorsement: '',
  licenseNo: '',
  licenseCountry: '',
  licenseState: '',
  cdlNo: '',
  cdlCountry: '',
  cdlState: '',
  firstName: '',
  middleName: '',
  lastName: '',
  aceIdFlag: false,
  aceId: '',
  customProximityId: '',
  fastCardFlag: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, showNotification, shouldHideMap, index }) {
  const { getValues, setValue, watch, reset, trigger } = useFormContext();
  const { getTranslatedText } = useContext(ACEHighwayTranslationContext)
  const { initialValues } = PAXLSTInternationalDocFormProperties.formikProps
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `paxLSTInfo.${index}.${field}` : field
  }, [])

  const [lookups, setLookups] = useState(null)
  const [partyTemplateDialog, setPartyTemplateDialog] = useState(false)
  const [locTemplateDialog, setLocTemplateDialog] = useState(false)

  const partyType = watch(getFieldName('partyType'))
  const aceIdFlag = watch(getFieldName('aceIdFlag'))
  const fastCardFlag = watch(getFieldName('fastCardFlag'))
  const internationalDoc = watch(getFieldName('internationalDoc'))
  const crewType = watch(getFieldName('partyType'))
  const cdlNo = useWatch({ name: getFieldName('cdlNo') })
  const cdlCountry = useWatch({ name: getFieldName('cdlCountry') })
  const licenseNo = useWatch({ name: getFieldName('licenseNo') })
  const licenseCountry = useWatch({ name: getFieldName('licenseCountry') })


  useEffect(() => {
    if (aceIdFlag) {
      setValue(getFieldName('customProximityId'), '')
    } else if (fastCardFlag) {
      setValue(getFieldName('aceId'), '')
    }
  }, [aceIdFlag, fastCardFlag])


  
  useEffect(() => {
    if (!aceIdFlag && !fastCardFlag && internationalDoc.length === 0) {
      const { initialValues } = PAXLSTInternationalDocFormProperties.formikProps
      setValue(getFieldName('internationalDoc'), [{ ...initialValues, _id: 1 }])
    }
  }, [aceIdFlag, fastCardFlag])

  useEffect(() => {
    Promise.all([
      // Crew Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_CREW_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([partyType]) => { setLookups({ partyType }) })
  }, [])


  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue(getFieldName('fileForUserId'), fileForUserDetails.fileForUserId)
      setValue(getFieldName('fileForUserLoginId'), fileForUserDetails.fileForUserLoginId)
      setValue(getFieldName('fileForUserPartyId'), fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value
    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  // Crew Section template table
  const columns = [
    {
      title: `${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewId')} & ${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceId')} / ${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fastCard')}`,
      render: (data) => {
        const { aceId, crewId, customProximityId } = data

        return (
          <Box>
            <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
              {crewId}
            </Typography>
            {(aceId || customProximityId) && (
              <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
                {aceId ? aceId : customProximityId}
                <TinyChip label={aceId ? 'ACE ID' : 'FAST Card'} variant='outlined' />
              </Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: 'crewType',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.crewType)}
        </Typography>
    },
    {
      field: 'firstName',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'firstName')
    },
    {
      field: 'middleName',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'middleName')
    },
    {
      field: 'lastName',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'lastName')
    },
  ]

  const sortOptions = [
    {
      label: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewId'),
      value: 'crewId'
    },
    {
      label: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewType'),
      value: 'crewType'
    },
  ]

  function handleApplyCrewTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      crewId,
      aceId,
      cdlCountry,
      cdlNo,
      cdlState,
      crewType,
      customProximityId,
      dob,
      email,
      firstName,
      gender,
      hazmatEndorsement,
      internationalDoc,
      lastName,
      licenseCountry,
      licenseNo,
      licenseState,
      middleName,
      nationality,
      telNo,
      address1,
      address2,
      address3,
      state,
      cityName,
      postalCode
    } = template

    const highwayPaxlstInterchange = [{ id: '39', value: 'AIG' }, { id: 'AEW', value: 'DM' }, { id: '6W', value: 'ACE' }, { id: 'AGS', value: 'AQY' }, { id: 'AEF', value: 'ARJ' }, { id: 'AGR', value: 'ASX' }, { id: 'ALR', value: 'ALR' }, { id: 'ALX', value: 'AVP' },
    { id: '989', value: 'AUB' }, { id: 'AGT', value: 'GN' }, { id: 'ALY', value: 'AFZ' }, { id: 'ACU', value: 'ET' }, { id: 'AAG', value: 'AGF' }, { id: 'ALV', value: 'ALV' }, { id: 'BCP', value: 'CR' }, { id: 'REP', value: 'REP' }, { id: 'RTP', value: 'RTP' }, { id: 'BCN', value: 'BCN' }, { id: 'CDN', value: 'CDN' }, { id: 'CON', value: 'CON' }, { id: 'OTD', value: 'OTD' }]

    const docList = internationalDoc.map((d, index) => ({
      docType: d.docType ? (highwayPaxlstInterchange.find(obj => {
        return obj.id === d.docType || obj.value === d.docType;
      }))?.id : d.docType,
      docNo: d.docNo,
      country: d.country,
      state: '',
      _id: index + 1
    }))
    setValue(getFieldName('fetchCrew'), crewId, { shouldDirty: true })

    const aceIdFlag = aceId ? true : false
    const fastCardFlag = customProximityId ? true : false

    setValue(getFieldName('aceIdFlag'), aceIdFlag, { shouldDirty: true })
    setValue(getFieldName('fastCardFlag'), fastCardFlag, { shouldDirty: true })

    setValue(getFieldName('aceId'), aceIdFlag ? aceId || '' : '', { shouldDirty: true })
    setValue(getFieldName('customProximityId'), fastCardFlag ? customProximityId || '' : '', { shouldDirty: true })
    setValue(getFieldName('partyType'), AciAceCrewTypeMapping('ACE', crewType) || '', { shouldDirty: true })
    setValue(getFieldName('firstName'), firstName || '', { shouldDirty: true })
    setValue(getFieldName('lastName'), lastName || '', { shouldDirty: true })
      
    if (!aceIdFlag && !fastCardFlag) {
      setValue(getFieldName('middleName'), middleName || '', { shouldDirty: true })
      setValue(getFieldName('gender'), gender || '', { shouldDirty: true })
      setValue(getFieldName('dob'), dob ? moment(dob).tz('Canada/Eastern').format('YYYY-MM-DD') : null, { shouldDirty: true })
      setValue(getFieldName('nationality'), nationality || '', { shouldDirty: true })
    }
    if (!fastCardFlag) {
      setValue(getFieldName('address1'), address1 || '', { shouldDirty: true })
      setValue(getFieldName('address2'), address2 || '', { shouldDirty: true })
      setValue(getFieldName('address3'), address3 || '', { shouldDirty: true })
      setValue(getFieldName('cityName'), cityName || '', { shouldDirty: true })
      setValue(getFieldName('postalCode'), postalCode || '', { shouldDirty: true })
      setValue(getFieldName('state'), state || '', { shouldDirty: true })
    }

    setValue(getFieldName('email'), email || '', { shouldDirty: true })
    setValue(getFieldName('telNo'), telNo || '', { shouldDirty: true })
      
    setValue(getFieldName('licenseNo'), licenseNo || '', { shouldDirty: true })
    setValue(getFieldName('licenseCountry'), licenseCountry || '', { shouldDirty: true })
    setValue(getFieldName('licenseState'), licenseState || '', { shouldDirty: true })
    setValue(getFieldName('cdlNo'), cdlNo || '', { shouldDirty: true })
    setValue(getFieldName('cdlCountry'), cdlCountry || '', { shouldDirty: true })
    setValue(getFieldName('cdlState'), cdlState || '', { shouldDirty: true })
    setValue(getFieldName('hazmatEndorsement'), hazmatEndorsement || '', { shouldDirty: true })
    setValue(getFieldName('internationalDoc'), internationalDoc ? docList : [], { shouldDirty: true })

    showNotification('success', 'Template applied successfully.')
    trigger()
  }
  // Party Section template table
  const dropOffLocPartyColumns = [
    {
      field: 'partytype',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyType'),
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      field: 'partyidn',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyIdentifier')
    },
    {
      title: `${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'name1')}/${getTranslatedText('ACE_HIGHWAY_MANIFEST', 'name2')}`,
      render: (data) => [data.partyname1, data.partyname2].join(' ')
    },
    {
      field: 'countrycode',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'country')
    },
    {
      field: 'statecode',
      title: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'state')
    },
  ]

  const dropOffLocPartySortOptions = [
    {
      label: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyIdentifier'),
      value: 'partyidn'
    },
    {
      label: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyType'),
      value: 'partytype'
    }
  ]

  function handleApplyLocTemplate(template) {
    if (!template) {
      reset()
      return
    }

    const {
      partyidn,
      address1,
      address2,
      address3,
      cityname,
      postalcode,
      statecode
    } = template

    setValue(getFieldName('partyDropDown'), partyidn || '', { shouldDirty: true })
    setValue(getFieldName('address1'), address1 || '', { shouldDirty: true })
    setValue(getFieldName('address2'), address2 || '', { shouldDirty: true })
    setValue(getFieldName('address3'), address3 || '', { shouldDirty: true })
    setValue(getFieldName('cityName'), cityname || '', { shouldDirty: true })
    setValue(getFieldName('postalCode'), postalcode || '', { shouldDirty: true })
    setValue(getFieldName('state'), statecode || '', { shouldDirty: true })
      
    trigger()
  }

  function handleAddInternationalDoc(data) {
    const internationalDoc = [...getValues(getFieldName('internationalDoc')), data].map(
      (document, index) => ({ ...document, _id: index + 1 })
    )

    setValue(getFieldName('internationalDoc'), internationalDoc)
  }

  function handleDeleteInternationalDoc(data) {
    const internationalDoc = [...getValues(getFieldName('internationalDoc'))].filter((document) =>
      document.id ? document.id !== data.id : document._id !== data._id
    )

    setValue(getFieldName('internationalDoc'), internationalDoc, { shouldDirty: true })
  }
  
	return (
    <>
      <Grid container spacing={2}>
        <CngGridItem xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <ToggleButtonGroup
                    exclusive
                    fullWidth
                    onChange={(_, value) => {
                      if (value === 'aceIdFlag') {
                        setValue(getFieldName('aceIdFlag'), true)
                        setValue(getFieldName('fastCardFlag'), false)
                        setValue(getFieldName('customProximityId'), '')
                        setValue(getFieldName('middleName'), '')
                        setValue(getFieldName('gender'), '')
                        setValue(getFieldName('dob'), null)
                        setValue(getFieldName('nationality'), '')
                      } else if (value === 'fastCardFlag') {
                        setValue(getFieldName('address1'), '')
                        setValue(getFieldName('address2'), '')
                        setValue(getFieldName('address3'), '')
                        setValue(getFieldName('cityName'), '')
                        setValue(getFieldName('postalCode'), '')
                        setValue(getFieldName('state'), '')

                        setValue(getFieldName('fastCardFlag'), true)
                        setValue(getFieldName('aceIdFlag'), false)
                        setValue(getFieldName('aceId'), '')
                        setValue(getFieldName('middleName'), '')
                        setValue(getFieldName('gender'), '')
                        setValue(getFieldName('dob'), null)
                        setValue(getFieldName('nationality'), '')
                      }else{
                        setValue(getFieldName('fastCardFlag'), false)
                        setValue(getFieldName('aceIdFlag'), false)
                        setValue(getFieldName('customProximityId'), '')
                        setValue(getFieldName('aceId'), '')
                      }
                    }}
                    options={[
                      { label: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceIdFlag'), value: 'aceIdFlag' },
                      { label: getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fastCardFlag'), value: 'fastCardFlag' }
                    ]}
                    value={aceIdFlag ? 'aceIdFlag' : fastCardFlag ? 'fastCardFlag' : null}
                  />
                </Grid>

                <CngGridItem xs={12} sm={6}>
                  <NaCrewMasterAutoCompleteField
                    name={getFieldName('fetchCrew')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fetchCrew')}
                    disabled={disabled}
                    onChange={(_, options) => handleApplyCrewTemplate(options.data)}
                    lookupProps= {{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      }]
                    }} 
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setPartyTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                  />
                </CngGridItem>

                {aceIdFlag && (
                  <Grid item xs={12} sm={8}>
                    <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}>
                      <strong>NOTE:</strong> {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'paxlstInfoAddressTitle')}
                    </Alert>
                  </Grid>
                )}

                {!aceIdFlag && !fastCardFlag && (
                  <Grid item xs={12} sm={8}>
                    <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}>
                      <strong>NOTE:</strong> *Personal information is required if the person has neither an ACE id nor a FAST card.
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </CngGridItem>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.crewType}>
              <CngCodeMasterAutocompleteField
                required
                name={getFieldName('partyType')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewType')}
                disabled={disabled}
                codeType='ACE_HW_CREW_TYPE'
                key={partyType}
                size='small'
                onChange={(value) => setValue(getFieldName('partyType'), value)}
              />
            </CngGridItem>

            {aceIdFlag && (
              <CngGridItem xs={12} md={8} shouldHide={shouldHideMap?.aceId}>
                <CngTextField
                  required
                  name={getFieldName('aceId')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceId')}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            )}

            {fastCardFlag && (
              <CngGridItem xs={12} md={8} shouldHide={shouldHideMap?.customProximityId}>
                <CngTextField
                  required
                  name={getFieldName('customProximityId')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fastCard')}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            )}

            <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.firstName}>
              <CngTextField
                required={!aceIdFlag && !fastCardFlag}
                name={getFieldName('firstName')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'firstName')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName('firstName'), e.target.value.toUpperCase()) }}
                size='small'
              />
            </CngGridItem>

            {!aceIdFlag && !fastCardFlag && (
              <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.middleName}>
                <CngTextField
                  name={getFieldName('middleName')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'middleName')}
                  disabled={disabled}
                  onBlur={(e) => { setValue(getFieldName('middleName'), e.target.value.toUpperCase()) }}
                  size='small'
                />
              </CngGridItem>
            )}

            <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.lastName}>
              <CngTextField
                required={!aceIdFlag && !fastCardFlag}
                name={getFieldName('lastName')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'lastName')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName('lastName'), e.target.value.toUpperCase()) }}
                size='small'
              />
            </CngGridItem>

            {partyType === 'VW' && (aceIdFlag || fastCardFlag) && (
              <>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.telNo}>
                  <CngTextField
                    name={getFieldName('telNo')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'telNo')}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.email}>
                  <CngTextField
                    name={getFieldName('email')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'email')}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </>
            )}

            {!aceIdFlag && !fastCardFlag && (
              <>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.telNo}>
                  <CngTextField
                    name={getFieldName('telNo')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'telNo')}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.email}>
                  <CngTextField
                    name={getFieldName('email')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'email')}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.gender}>
                  <CngSelectField
                    required
                    name={getFieldName('gender')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'gender')}
                    disabled={disabled}
                    items={[
                      { text: 'Male', value: 'M' },
                      { text: 'Female', value: 'F' }
                    ]}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dob}>
                  <CngDateField
                    required
                    name={getFieldName('dob')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'dob')}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.nationality}>
                  <CngCountryAutocompleteField
                    required
                    name={getFieldName('nationality')}
                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'nationality')}
                    disabled={disabled}
                    lookupProps={{
                      label: (record) => {
                        return `${record.code};${record.descriptionEn}`
                      }
                    }}
                    size='small'
                    key={getValues(getFieldName('nationality'))}
                  />
                </CngGridItem>
              </>
            )}
          </Grid>
        </CngGridItem>

        <CngGridItem xs={12}>
          <Divider />
        </CngGridItem>

        {!fastCardFlag && (
          <CngGridItem xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} shouldHide={shouldHideMap?.partyDropDown}>
                <NaAcePartyMasterAutoCompleteField
                  name={getFieldName('partyDropDown')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'partyDropDown')}
                  disabled={disabled}
                  onChange={(_, options) => handleApplyLocTemplate(options.data)}
                  lookupProps={{
                    filters: [{
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    },
                      {
                        field: 'partytype',
                        operator: IN,
                        value: ['OS', 'CN', 'NI', 'GC', 'CB', 'IM', 'CZ', 'SH']
                    }]
                  }}
                  size='small'
                  fullWidth
                  disableClearable
                  textFieldProps={{
                    InputProps: {
                      customEndAdornment: () => (
                        <InputAdornment position='end' style={{ marginTop: -16 }}>
                          <IconButton onClick={(event) => {
                            event.stopPropagation()
                            setLocTemplateDialog(true)
                          }}>
                            <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                              <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }}
                  forcePopupIcon={false}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address1}>
                <CngTextField
                  name={getFieldName('address1')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'address1')}
                  disabled={disabled}
                  required
                  onBlur={(e) => { setValue(getFieldName('address1'), e.target.value.toUpperCase()) }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address2}>
                <CngTextField
                  name={getFieldName('address2')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'address2')}
                  disabled={disabled}
                  onBlur={(e) => { setValue(getFieldName('address2'), e.target.value.toUpperCase()) }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.address3}>
                <CngTextField
                  name={getFieldName('address3')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'address3')}
                  disabled={disabled}
                  onBlur={(e) => { setValue(getFieldName('address3'), e.target.value.toUpperCase()) }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.cityName}>
                <CngTextField
                  name={getFieldName('cityName')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'cityName')}
                  disabled={disabled}
                  required
                  onBlur={(e) => { setValue(getFieldName('cityName'), e.target.value.toUpperCase()) }}
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.postalCode}>
                <CngTextField
                  name={getFieldName('postalCode')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'postalCode')}
                  disabled={disabled}
                  required
                  size='small'
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.state}>
                <NaAceStateCodeAutoCompleteField
                  name={getFieldName('state')}
                  label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'state')}
                  disabled={disabled}
                  countryCode={'US'}
                  required
                  size='small'
                  key={getValues(getFieldName('state'))}
                />
              </CngGridItem>
            </Grid>
          </CngGridItem>
        )}

        <CngGridItem xs={12}>
          <CngSection
            title={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'internationalDoc')}
            subheader={!aceIdFlag && !fastCardFlag ? (
                <Typography color='error' variant='caption'>
                  *Required
                </Typography>
              ) : null
            }>
            <Grid container spacing={1}>
              {internationalDoc.map((document, childIndex) => (
                <React.Fragment key={document._id || document.id || childIndex}>
                  <Grid item xs={12}>
                    <PAXLSTInternationalDocEntry
                      index={childIndex}
                      parentIndex={index}
                      isLast={internationalDoc.length === 1}
                      onAddInternationalDoc={handleAddInternationalDoc}
                      onDeleteInternationalDoc={handleDeleteInternationalDoc}
                      internationalDoc={document}
                      showNotification={showNotification}
                      crewType={getValues(getFieldName('partyType'))}
                      aceIdFlag={aceIdFlag}
                      fastCardFlag={fastCardFlag}
                    />
                  </Grid>
                  {internationalDoc.length !== childIndex + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
            {internationalDoc.length === 0 && (
            <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add new'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => handleAddInternationalDoc(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          )}
          </CngSection>
        </CngGridItem>

        <CngGridItem xs={12}>
          {partyType === 'VW' && !fastCardFlag && !aceIdFlag ? (
            <Typography color='error' variant='caption'>
              *At least one license is required if the person is a driver and has neither an ACE id nor a FAST card
            </Typography>
          ) : null}
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.licenseNo}>
              <CngTextField
                name={getFieldName('licenseNo')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'licenseNo')}
                disabled={disabled}
                onBlur={(e) => { setValue(getFieldName('licenseNo'), e.target.value.toUpperCase()) }}
                required={crewType === 'VW' && !aceIdFlag && !fastCardFlag && cdlNo === ''}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.licenseCountry}>
              <CngCodeMasterAutocompleteField
                name={getFieldName('licenseCountry')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'licenseCountry')}
                disabled={disabled}
                codeType='ACIHWY_MANIFEST_COUNTRY'
                onChange={() => { setValue(getFieldName('licenseStateState'), '') }}
                lookupProps={{
                  label: (record) => {
                    return `${record.code};${record.descriptionEn}`
                  }
                }}
                required={licenseNo !== null && licenseNo !== ''}
                size='small'
                key={getValues(getFieldName('licenseCountry'))}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.licenseState}>
              <NaAceStateCodeAutoCompleteField 
                name={getFieldName('licenseState')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'licenseState')}
                disabled={disabled}
                countryCode={licenseCountry}
                required={licenseNo !== null && licenseNo !== ''}
                size='small'
                key={getValues(getFieldName('licenseCountry'))}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.cdlNo}>
              <CngTextField
                name={getFieldName('cdlNo')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'cdlNo')}
                disabled={disabled}
                required={crewType === 'VW' && !aceIdFlag && !fastCardFlag && licenseNo === ''}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.cdlCountry}>
              <CngCodeMasterAutocompleteField
                name={getFieldName('cdlCountry')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'cdlCountry')}
                disabled={disabled}
                codeType='ACIHWY_MANIFEST_COUNTRY'
                onChange={() => { setValue(getFieldName('cdlStateState'), '') }}
                lookupProps={{
                  label: (record) => {
                    return `${record.code};${record.descriptionEn}`
                  }
                }}
                required={cdlNo !== null && cdlNo !== ''}
                size='small'
                key={getValues(getFieldName('cdlCountry'))}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.cdlState}>
              <NaAceStateCodeAutoCompleteField 
                name={getFieldName('cdlState')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'cdlState')}
                disabled={disabled}
                countryCode={cdlCountry}
                required={cdlNo !== null && cdlNo !== ''}
                size='small'
                key={getValues(getFieldName('cdlCountry'))}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.hazmatEndorsement}>
              <Switch
                fullWidth
                name={getFieldName('hazmatEndorsement')}
                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'hazmatEndorsement')}
                disabled={disabled}
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </Grid>

      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'crewId', direction: 'ASC' }]}
        fetch={{ url: CrewMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyCrewTemplate}
        onClose={() => setPartyTemplateDialog(false)}
        open={partyTemplateDialog}
        search={{ field: 'crewId', operator: LIKE }}
        sortOptions={sortOptions}
        title='Crew template'
      />

      <TemplateSelectDialog
        columns={dropOffLocPartyColumns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: ['OS', 'CN', 'NI', 'GC', 'CB', 'IM', 'CZ', 'SH'] },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyLocTemplate}
        onClose={() => setLocTemplateDialog(false)}
        open={locTemplateDialog}
        search={{ field: 'partyidn', operator: LIKE }}
        sortOptions={dropOffLocPartySortOptions}
        title='Drop Off Location Party template'
      />
    </>
	)
}

const PartyInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyInfoFormProperties
