import React from 'react'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import EquipmentInfoTable from './EquipmentInfoTable'
import ShipmentTable from './view/ShipmentTable'
import ResponseLog from './view/ResponseLog'
import History from './view/History'
import moment from 'moment-timezone'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ManifestViewContent(props) {
  const {
    data,
    getCountryStateLabel,
    getLookupValue,
    id,
    isReviewPage,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const iitCodes = [
    data.iitCode1,
    data.iitCode2,
    data.iitCode3,
    data.iitCode4
  ].filter((iitcode) => iitcode)

  function getLicensePlates() {
    const result = []

    // Loop 4 times because of API response's structure
    for (let i = 1; i <= 4; i++) {
      const plateNo = data[`licensePlateNo${i}`]
      const plateCountry = data[`licensePlateCountry${i}`]
      const plateState = data[`licensePlateState${i}`]

      // If any of 3 values exists, push to result array
      if (plateNo || plateCountry || plateState) {
        result.push({
          licensePlateNo: plateNo,
          licensePlateCountry: {
            label: getLookupValue('countries', plateCountry),
            value: plateCountry
          },
          licensePlateState: getCountryStateLabel(plateCountry, plateState)
        })
      }
    }

    return result
  }

  function getSealNos() {
    const result = []

    // Loop 9 times because of API response's structure
    for (let i = 1; i <= 9; i++) {
      if (data[`sealNo${i}`]) {
        result.push(data[`sealNo${i}`])
      }
    }

    return result
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.status} />
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aceHighwayManifest}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.carrierScac}>
                {data.carrierScac}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.tripNo}>
                {data.tripNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.arrivalDate}>
                {data.arrivalDate &&
                  moment(data.arrivalDate).format('D MMM YYYY, HH:mm')}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.transitIndicator}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('transportIndicator', data.transitIndicator)}
                  <TinyChip label={data.transitIndicator} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.firstExpectedPort}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
                  <TinyChip label={data.firstExpectedPort} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aceHighwayTruckConveyance}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box paddingTop={1} paddingBottom={2}>
                <Grid container justify='space-between' spacing={3}>
                  <Grid item xs={12} md='auto'>
                    <Typography color='textSecondary' variant='body2'>
                      {data.aceFlag
                        ? 'Conveyance with ACE no.'
                        : 'Conveyance without ACE no.'}
                    </Typography>
                  </Grid>
                  {data.aceFlag && (
                    <Grid item xs={12} md='auto'>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textSecondary'
                      >
                        {translatedTextsObject.aceId}
                      </Typography>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textSecondary'
                      >
                        :&nbsp;
                      </Typography>
                      <Typography
                        component='span'
                        variant='body2'
                        style={{ fontWeight: 700 }}
                      >
                        {data.aceId}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.conveyanceType}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('conveyanceType', data.conveyanceType)}
                      <TinyChip label={data.conveyanceType} variant='outlined' />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CngField label={translatedTextsObject.conveyanceNo}>
                    {data.conveyanceNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CngField label={translatedTextsObject.email}>
                    {data.email}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
            {getLicensePlates().length > 0 && (
              <Grid item xs={12}>
                <CngSubSection
                  title='License plate'
                  style={{ marginBottom: 16 }}
                >
                  <Grid container spacing={2}>
                    {getLicensePlates().map((plate, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <CngField label={translatedTextsObject[`licensePlateNo${index + 1}`]}>
                                {plate.licensePlateNo}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <CngField label={translatedTextsObject[`licensePlateCountry${index + 1}`]}>
                                <Typography component='div' variant='inherit'>
                                  {plate.licensePlateCountry.label}
                                  <TinyChip label={plate.licensePlateCountry.value} variant='outlined' />
                                </Typography>
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <CngField label={translatedTextsObject[`licensePlateState${index + 1}`]}>
                                {plate.licensePlateState}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {getLicensePlates().length !== index + 1 && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
            {getSealNos().length > 0 && (
              <Grid item xs={12}>
                <CngSubSection title='Seal nos.'>
                  <Grid container spacing={2}>
                    {getSealNos().map((sealNo, index) => (
                      <Grid key={index} item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject[`sealNo${index + 1}`]}>
                          {sealNo}
                        </CngField>
                      </Grid>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
            <Grid item xs={12}>
              <CngSubSection title='Instruments of International Traffic - IIT'>
                {iitCodes.length > 0 ? (
                  <Grid container spacing={3}>
                    {iitCodes.map(
                      (iitCode, index) =>
                        iitCode !== '' && (
                          <Grid item key={`iitCode${index + 1}`} xs={12} md={6}>
                            <Box display='flex' style={{ gap: 8 }}>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                height={24}
                                width={24}
                              >
                                <FontAwesomeIcon
                                  icon={['fal', 'check']}
                                  color={theme.palette.primary.main}
                                />
                              </Box>
                              <Box display='flex' flexDirection='column'>
                                <Typography>{iitCode}</Typography>
                                <Typography>
                                  {translatedTextsObject[`iitCode${index + 1}`]}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )
                    )}
                  </Grid>
                ) : (
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                )}
              </CngSubSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.equipmentInfo}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {`/ ${data.equipmentInfo.length} Entries`}
              </Typography>
            </Box>
          }
        >
          <EquipmentInfoTable data={data.equipmentInfo} />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.partyInfo}>
          {data.partyInfo.length > 0 ? (
            <Grid container spacing={2}>
              {data.partyInfo.map(party => (
                <Grid key={party.id || party._id} item xs={12}>
                  <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={getLookupValue('crewType', party.partyType)}>
                            {[party.firstName, party.middleName, party.lastName]
                              .filter((name) => name)
                              .join(' ')}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label='Document type'>
                                {party.aceIdFlag && translatedTextsObject.partyAceId}
                                {party.fastCardFlag && translatedTextsObject.fastCard}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label='Document no.'>
                                {party.aceIdFlag && party.aceId}
                                {party.fastCardFlag && party.fastCard}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.telNo}>
                                {party.telNo}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.email}>
                                {party.email}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!party.fastCardFlag && (
                          <>
                            <Grid item xs={12}>
                              <Grid alignItems='center' container spacing={2}>
                                <Grid item xs={12} sm='auto'>
                                  <Typography variant='body2' color='textSecondary' style={{ fontWeight: 600 }}>
                                    {translatedTextsObject.partyInfoAddressTitle}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm>
                                  <Divider />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <CngField label='Address'>
                                {[
                                  party.address1,
                                  party.address2,
                                  party.address3
                                ]
                                  .filter((address) => address)
                                  .join(', ')}
                              </CngField>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.insuranceInfo}>
          {data.insuranceInfo.length > 0 ? (
            <Grid container spacing={2}>
              {data.insuranceInfo.map((insurance, index) => (
                <React.Fragment key={insurance.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.insuranceCompany}>
                          {insurance.insuranceCompany}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.insurancePolicyNo}>
                          {insurance.insurancePolicyNo}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.yearOfIssuance}>
                          {moment(insurance.yearOfIssuance).format('YYYY')}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.liabilityAmount}>
                          {`$ ${parseFloat(insurance.liabilityAmount).toFixed(
                            2
                          )}`}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.insuranceInfo.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.aceManifestShipment}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {/*`/ ${data.shipmentInfo.length} Entries`*/}
              </Typography>
            </Box>
          }
        >
          <ShipmentTable
            //data={data.shipmentInfo}
            getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
            manifestId={data.id}
            manifestPort={data.firstExpectedPort}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.statusNotificationTitle}>
          {data.statusNotificationInfo.length > 0 ? (
            <Grid container spacing={3}>
              {data.statusNotificationInfo.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationName}>
                          {notification.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationEmail}>
                          {notification.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationSnType}>
                          {notification.snType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationShipmentControlNum}>
                          {notification.shipmentControlNum}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationMobileNum}>
                          {notification.mobileNum}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.statusNotificationInfo.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      {!isReviewPage && (
        <>
          <Grid item xs={12}>
            <ResponseLog manifestId={id} />
          </Grid>
          <Grid item xs={12}>
            <History manifestId={id} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ManifestViewContent
