import makeValidationSchema from './InsuranceInfoMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import { useFormContext, useWatch } from "react-hook-form"
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import { Box, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  insuranceCompany: "",
  insurancePolicyNo: "",
  liabilityAmount: "",
  yearOfIssuance: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext();

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let insuranceInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.ID
    )
    let headerId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.HEADER_ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.VERSION
    )
    let insuranceCompany = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.INSURANCE_COMPANY
    )
    let insurancePolicyNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.INSURANCE_POLICY_NO
    )
    let liabilityAmount = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.LIABILITY_AMOUNT
    )
    let yearOfIssuance = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.YEAR_OF_ISSUANCE
    )

    return {
      insuranceInfo,
      id,
      headerId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      insuranceCompany,
      insurancePolicyNo,
      liabilityAmount,
      yearOfIssuance
    }
  }

	return (
      <Box padding={2}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="id"
              label={translatedTextsObject.id}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="headerId"
              label={translatedTextsObject.headerId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="createdBy"
              label={translatedTextsObject.createdBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngDateField
              name="createdDate"
              label={translatedTextsObject.createdDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="updatedBy"
              label={translatedTextsObject.updatedBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngDateField
              name="updatedDate"
              label={translatedTextsObject.updatedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="submittedBy"
              label={translatedTextsObject.submittedBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngDateField
              name="submittedDate"
              label={translatedTextsObject.submittedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="version"
              label={translatedTextsObject.version}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.insuranceCompany}>
            <CngTextField
              required
              name="insuranceCompany"
              label={translatedTextsObject.insuranceCompany}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.insurancePolicyNo}>
            <CngTextField
              required
              name="insurancePolicyNo"
              label={translatedTextsObject.insurancePolicyNo}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.liabilityAmount}>
            <CngTextField
              required
              name="liabilityAmount"
              label={translatedTextsObject.liabilityAmount}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.yearOfIssuance}>
            <CngTextField
              required
              name="yearOfIssuance"
              label={translatedTextsObject.yearOfIssuance}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </Box>
	)
}

const InsuranceInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default InsuranceInfoFormProperties
