import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import EquipmentInfoDialog from '../EquipmentInfoDialog'
import EquipmentInfoTable from '../EquipmentInfoTable'

function EquipmentInfoSection(props) {
    const { showNotification } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const [equipmentInfoDialog, setEquipmentInfoDialog] = useState({ open: false, equipment: null })

    const equipmentInfo = useWatch({ name: 'equipmentInfo' })

    function handleRemoveEquipmentInfo(data) {
        const equipmentInfo = [...getValues('equipmentInfo')].filter((equipment) =>
            equipment.id ? equipment.id !== data.id : equipment._id !== data._id
        )

        setValue('equipmentInfo', equipmentInfo, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'equipmentInfo') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${equipmentInfo.length} Entries`}
                        </Typography>
                    </Typography>
                }
                helperText={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'equipmentSectionHelper')}
                alignItems='flex-end'
                id="eqp-info"
            >

                <EquipmentInfoTable
                    data={equipmentInfo}
                    onAddEquipmentInfo={() =>
                        setEquipmentInfoDialog({ open: true, equipment: null })
                    }
                    onEditEquipmentInfo={(equipment) =>
                        setEquipmentInfoDialog({ open: true, equipment })
                    }
                    onDeleteEquipmentInfo={handleRemoveEquipmentInfo}
                />

                <EquipmentInfoDialog
                    onClose={() =>
                        setEquipmentInfoDialog({ open: false, equipment: null })
                    }
                    open={equipmentInfoDialog.open}
                    equipment={equipmentInfoDialog.equipment}
                    showNotification={showNotification}
                />
            </CngSection>
        </Grid>
    )
}

export default EquipmentInfoSection