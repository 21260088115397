import makeValidationSchema from './EmailCoverSheetMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { useFormContext } from "react-hook-form";

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  email: "",
  subject: "",
  message: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  shouldHideMap,
  tripNo
}) {
  const { setValue } = useFormContext();
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    setValue("subject", tripNo)
  }, [tripNo]);

  function makeTranslatedTextsObject() {
    let email = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      ManifestKeys.EMAIL
    )

    let emailSubject = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      ManifestKeys.EMAIL_SUBJECT
    )

    let emailMessage = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      ManifestKeys.EMAIL_MESSAGE
    )

    return { email, emailSubject, emailMessage }
  }

	return (
    <Card>
      <Grid container spacing={2}>
        <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.email}>
          <CngTextField
            required
            name="email"
            label={translatedTextsObject.email}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.emailSubject}>
          <CngTextField
            name="subject"
            label={translatedTextsObject.emailSubject}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.emailMessage}>
          <CngTextField
            multiline
            rows={4}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            name="message"
            label={translatedTextsObject.emailMessage}
            disabled={disabled}
            onChange={(e) => { setValue('message', e.target.value.replace(/(\r\n|\r|\n)+/g, ' ')) }}
          />
        </CngGridItem>
      </Grid>
    </Card>
	)
}

const EmailCoverSheetFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default EmailCoverSheetFormProperties
