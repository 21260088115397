import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  DataFlattener,
  useServices,
  useTranslation
} from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    manifestData,
    manifestStatus,
    onChangeStep,
    onSetLoading,
    onSetManifestStatus,
    onSubmitManifest,
    showNotification,
    step,
    onCreateACIManifest
  } = props
  const { id } = useParams()
  const [paxLSTInfo, setPAXLSTInfo] = useState([])
  const onSuccessCallback = useRef(() => { })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AceHighwayManifestApiUrls.GET_DETAILS,
            onPreSuccess: (serverData) => {
              serverData.arrivalDateTimeString = ""
              manifestData.current = serverData
              onSetManifestStatus(serverData.status)
              setPAXLSTInfo(serverData.paxLSTInfo)
            }
          }}
          update={{
            url: AceHighwayManifestApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              manifestData.current = e
              onSetManifestStatus(e.status)
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <AceHWCustomButtonSection
              paxLSTInfo={paxLSTInfo}
              showNotification={showNotification}
              status={manifestStatus}
              onSaveOnly={() => {
                onSuccessCallback.current = () => {
                  onSetLoading(false)
                }
              }}
              onSaveAndContinue={() => {
                onSuccessCallback.current = () => {
                  onChangeStep(step + 1)
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
              onSkip={() => onChangeStep(step + 1)}
              onSubmitManifest={(data) => {
                manifestData.current = data
                onSubmitManifest()
              }}
              onCreateACIManifest={(data) => {
                manifestData.current = data
                onCreateACIManifest()
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage

function AceHWCustomButtonSection(props) {
  const {
    loading,
    paxLSTInfo,
    onSaveOnly,
    onSaveAndContinue,
    onSkip,
    onSubmitManifest,
    onSetLoading,
    showNotification,
    status,
    onCreateACIManifest
  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const { createRecord } = useServices()
  const history = useHistory()
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let submitPAXLST = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SUBMIT_PAXLST_BUTTON
    )

    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SUBMIT_SUCCESS_MSG
    )

    return { submitPAXLST, submitSuccessMsg }
  }

  function handleSubmitPAXLST() {
    const values = getValues()
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    onSetLoading(true)

    var serverData = FormProperties.toServerDataFormat(values)
    createRecord.execute(
      AceHighwayManifestApiUrls.SUBMIT_PAXLST,
      DataFlattener.unflatten(serverData),
      (result) => {
        var errorMessages = result.errorMessages
        if (result !== null && errorMessages === null) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      (error) => {
        console.log(error)
        showNotification('error', 'Fail to submit PAXLST')
      },
      () => onSetLoading(false)
    )
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={() => onCreateACIManifest(getValues())}
                size='medium'
                shouldShowProgress={loading}
              >
                Create ACI Manifest
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveAndContinue}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save &amp; continue
              </CngButton>
            </Grid>
            {paxLSTInfo && paxLSTInfo.length > 0 && status === '1009' && (
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  disabled={loading || paxLSTInfo[0].status === 'ST'}
                  onClick={handleSubmitPAXLST}
                  size='medium'
                  variant='contained'
                >
                  {translatedTextsObject.submitPAXLST}
                </CngButton>
              </Grid>
            )}
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={() => onSubmitManifest(getValues())}
                // type='submit'
                variant='contained'
                size='medium'
                shouldShowProgress={loading}
              >
                Submit manifest
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() =>
          history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
        }
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
