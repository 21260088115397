import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import FormProperties from '../../shipment/FormProperties'
import ShipmentViewContent from '../../shipment/ShipmentViewContent'
import Box from '@material-ui/core/Box'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function ShipmentDialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    isView,
    loading,
    manifestId,
    firstExpectedCode,
    onClose,
    onCreateShipment,
    onEditShipment,
    open,
    shipment,
    showNotification,
    manifestStatus,
    manifestCarrierScac
  } = props
  const { initialValues, makeValidationSchema } = FormProperties.formikProps
  const [status, setStatus] = useState(manifestStatus)

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATE_BUTTON
    )

    return { saveButton, updateButton }
  }

  let viewContent = (
    <ShipmentViewContent
      data={shipment}
      getLookupValue={getLookupValue}
      getCountryStateLabel={getCountryStateLabel}
    />
  )

  useEffect(() => {
    if (manifestStatus !== '1002') {
      setStatus(null !== shipment ? shipment.status : null)
    }

    if(shipment && shipment.scn){
      shipment.carrierScac = shipment.scn.substring(0, 4);
    }
  }, [shipment]);

  return (
    <CngDialog
      dialogContent={
        shipment && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: {
                ...(shipment || initialValues),
                headerId: manifestId,
                firstExpectedCode: firstExpectedCode,
                status: status
              },
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (shipment) {
                  onEditShipment(data)
                } else {
                  onCreateShipment(data)
                }
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <FormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                manifestCarrierScac={manifestCarrierScac}
              />
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngButton
                  color='primary'
                  size='medium'
                  type='submit'
                  disabled={loading}
                  shouldShowProgress={loading}
                >
                  {shipment
                    ? translatedTextsObject.updateButton
                    : translatedTextsObject.saveButton}
                </CngButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle='Fill-up new shipment'
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default ShipmentDialog
