import React, { useState, useEffect, useRef } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import { Grid, Typography, Box, Chip} from '@material-ui/core'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import axios from 'axios'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import moment from 'moment-timezone'
import { getStatusMetadata } from '../../../../common/NACommon'

const { button: { CngButton }, CngDialog } = components

const {
  filter: { EQUAL, LIKE, IN, IS_NULL, BETWEEN }
} = constants

function AttachShipmentDialog(props) {
  const { port, manifestId, open, onAttachShipment, onClose, showNotification } = props
  const { securedSendRequest } = useServices()

  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_SHIPMENT])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [totalPackages, setTotalPackages] = useState(0);
  const [totalSelectedPackages, setTotalSelectedPackages] = useState(0);
  const [totalGrossWeight, setTotalGrossWeight] = useState(0);
  const [totalSelectedGrossWeight, setTotalSelectedGrossWeight] = useState(0);
  const [isRowsSelected, setIsRowsSelected] = useState(false)

  useEffect(() => {
    if (open) {
      const formData = new FormData();
      formData.append('partyId', FileForUserGetPartyId());

      securedSendRequest.execute('POST', AceHighwayShipmentApiUrls.ATTACH_SEARCH_DETAILS, formData,
        (response) => {
          let data = response.data
          setTotalPackages(data.totalPackages)
          setTotalGrossWeight(data.totalGrossWeight)
        })
    }
  }, [open])

  function makeTranslatedTextsObject() {
    let scn = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SCN
    )
    let shipmentType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPMENT_TYPE
    )
    let shipperName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CONSIGNEE_NAME
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CREATED_DATE
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATED_DATE
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.RESPONSE_DATE
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.EDIT_BUTTON
    )
    let attachButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ATTACH_BUTTON
    )
    let detachButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DETACH_BUTTON
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SAVE_BUTTON
    )
    let attachSentRecords = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ATTACH_SENT_RECORDS
    )
    let attachShipmentSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ATTACH_SHIPMENT_SUCCESS_MSG
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.STATUS
    )
    let acquittalNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ACQUITTAL_NO
    )

    return {
      scn,
      shipmentType,
      shipperName,
      consigneeName,
      createdDate,
      updatedDate,
      responseDate,
      editButton,
      attachButton,
      detachButton,
      saveButton,
      attachSentRecords,
      attachShipmentSuccessMsg,
      status,
      acquittalNo
    }
  }

  function handleSubmitSelectedShipment(rows) {
    const result = {
      manifestId,
      shipmentId: rows.map((shipment) => shipment.id)
    }

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('userToken')),
        'Content-Type': 'application/json'
      }
    })

    instance
      .post(AceHighwayManifestApiUrls.ATTACH, JSON.stringify(result))
      .then((res) => {
        const { errorMessages } = res.data

        if (res.status === 200 && errorMessages === null) {
          showNotification(
            'success',
            translatedTextsObject.attachShipmentSuccessMsg
          )

          onAttachShipment()
        } else {
          showNotification('error', errorMessages)
        }

        onClose()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function getSelectedShipmentsDetails(rows) {
    let packageCount = 0
    let weightCount = 0

    if (rows && rows.length > 0) {
      rows.forEach((data) => {
        packageCount += data.totalPackages
        weightCount += data.totalGrossWeight
      })
      setIsRowsSelected(true)
    } else {
      setIsRowsSelected(false)
    }

    setTotalSelectedPackages(packageCount)
    setTotalSelectedGrossWeight(weightCount)
  }

  const columns = [
    {
      title: "Sno"
    },
    {
      field: 'scn',
      sortKey: 'scn',
      title: `${translatedTextsObject.scn} / ${translatedTextsObject.shipmentType} / ${translatedTextsObject.acquittalNo}`,
      render: (rowData) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {rowData.scn}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {[rowData.shipmentType, rowData.acquittalNo].join(' • ')}
          </Typography>
        </Box>
      )
    },
    {
      field: 'shipperName',
      sortKey: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      sortKey: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        if (data.status == '1000' && data.shipmentType == 'REGULAR' && !data.brokerEntryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            <Chip
              label={status.label}
              size='small'
              style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
            />

            {('REGULAR' == data.shipmentType && data.eventLocation && data.eventLocation != port) &&
              <Chip key='first' label={"Port Mismatch"} size='small'
                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
              />
            }
          </Box>
        )
      }
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) => data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
  ]

  const filters = [
    {
      label: translatedTextsObject.scn,
      type: 'textfield',
      name: 'scn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.shipperName,
      type: 'textfield',
      name: 'shipperName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.consigneeName,
      type: 'textfield',
      name: 'consigneeName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.brokerEntryNo,
      type: 'textfield',
      name: 'brokerEntryNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.customsBroker,
      type: 'textfield',
      name: 'customsBroker',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
  ]

  return (
    <CngDialog
      dialogTitle='Attach shipment'
      dialogContent={
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}><Typography variant='subtitle2'>Total Packages: {totalPackages}</Typography></Grid>
              <Grid item xs={12}><Typography variant='subtitle2'>Total Gross Weight: {totalGrossWeight}</Typography></Grid>
            </Grid>
          </Grid>

          {isRowsSelected &&
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}><Typography variant='subtitle2'>Selected Shipment Packages: {totalSelectedPackages}</Typography></Grid>
                <Grid item xs={12}><Typography variant='subtitle2'>Selected Shipment Gross Weight: {totalSelectedGrossWeight}</Typography></Grid>
              </Grid>
            </Grid>
          }

          <Grid item xs={12}>
            <Table
              checkboxSelection
              columns={columns}
              compact
              fetch={{ url: AceHighwayShipmentApiUrls.ATTACH_SEARCH }}
              fetchFilters={[
                { field: 'integration', operator: IN, value: ['NANG', 'SFTP'] },
                { field: 'status', operator: IN, value: ['1005', '1003'] },
                { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
                // { field: 'headerId', operator: IS_NULL, value: null }, //backend AceHighwayShipmentAttachSearchServiceImpl had catered
                { field: 'masterIndicator', operator: EQUAL, value: '0' } //not to show master shipment
              ]}
              filters={filters}
              selectActions={[
                {
                  buttonProps: { size: 'medium' },
                  disabled: (rows) => {
                    let shouldDisable = false

                    rows.forEach((rowData) => {
                      if (rowData.status === '1008' || rowData.status === '1006' || rowData.status === '1002') {
                        shouldDisable = true
                      }
                    })

                    return shouldDisable
                  },
                  icon: <FontAwesomeIcon icon={['fal', 'link']} />,
                  label: translatedTextsObject.attachButton,
                  onClick: (rows) => handleSubmitSelectedShipment(rows),
                  tooltip: (rows) => {
                    let includesSentRecords = false

                    rows.forEach((rowData) => {
                      if (rowData.status === '1008' || rowData.status === '1006' || rowData.status === '1002') {
                        includesSentRecords = true
                      }
                    })

                    return includesSentRecords ? translatedTextsObject.attachSentRecords : null
                  }
                }
              ]}
              onSelectRows={(rows) => getSelectedShipmentsDetails(rows)}
              search={{ field: 'scn', operator: LIKE }}
              showNotification={showNotification}
              sortConfig={{ type: 'column', defaultDirection: 'DESC', defaultField: 'updatedDate' }}
            />
          </Grid>
        </Grid>
      }
      dialogAction={
        <CngButton color='secondary' onClick={onClose}>
          Discard
        </CngButton>
      }
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default AttachShipmentDialog
