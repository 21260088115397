import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AceHighwayManifestKeys from '../../../constants/locale/key/AceHighwayManifest'
import AceHighwayShipmentKeys from '../../../constants/locale/key/AceHighwayShipment'
import AceHighwayResponseKeys from '../../../constants/locale/key/AceHighwayResponse'
import AceHwyInbondArrivalKeys from '../../../constants/locale/key/AceHwyInbondArrival'
import AceHighwayAbiInbond7512Keys from '../../../constants/locale/key/AceHighwayAbiInbond7512'
import AceHighwayAbiInbondEventKeys from '../../../constants/locale/key/AceHighwayAbiInbondEvent'


const ACEHighwayTranslationContext = React.createContext()

const ACEHighwayTranslationConsumer = ACEHighwayTranslationContext.Consumer

const ACEHighwayTranslationProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.ACE_HIGHWAY_MANIFEST,
        Namespace.ACE_HIGHWAY_SHIPMENT
    ])

    const translation = {
        ACE_HIGHWAY_MANIFEST: {
            aceHighwayManifest: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TITLE),
            aceHighwayTruckConveyance: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRUCK_CONVEYANCE_TITLE),
            id: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.ID),
            headerId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.HEADER_ID),
            createdBy: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CREATED_BY),
            createdDate: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CREATED_DATE),
            updatedBy: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.UPDATED_BY),
            updatedDate: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.UPDATED_DATE),
            submittedBy: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SUBMITTED_BY),
            submittedDate: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SUBMITTED_DATE),
            version: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.VERSION),
            partyId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PARTY_ID),
            carrierScac: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CARRIER_SCAC),
            tripNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRIP_NO),
            arrivalDate: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.ARRIVAL_DATE),
            arrivalDateOnly: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.ARRIVAL_DATE_ONLY),
            arrivalTime: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.ARRIVAL_TIME),
            transitIndicator: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRANSIT_INDICATOR),
            firstExpectedPort: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.FIRST_EXPECTED_PORT),
            messageFunction: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.MSG_FUNCTION),
            amendmentCode: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.AMENDMENT_CODE),
            status: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.STATUS),
            paxlstPrevStatus: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_PREV_STATUS),
            paxlstStatus: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_STATUS),
            responseDate: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.RESPONSE_DATE),
            templateFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TEMPLATE_FLAG),
            templateName: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TEMPLATE_NAME),
            fetchTruck: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.FETCH_TRUCK),
            aceId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.ACE_ID),
            aceFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.ACE_FLAG),
            conveyanceId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CONVEYANCE_ID),
            conveyanceNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CONVEYANCE_NO),
            conveyanceType: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CONVEYANCE_TYPE),
            email: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EMAIL),
            vin: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.VIN),
            dotNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.DOT_NO),
            transponderId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRANSPONDER_ID),
            saveTruckFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SAVE_TRUCK_FLAG),
            truckId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRUCK_ID),
            licensePlateNo1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_NO1),
            licensePlateCountry1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY1),
            licensePlateState1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_STATE1),
            licensePlateNo2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_NO2),
            licensePlateCountry2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY2),
            licensePlateState2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_STATE2),
            licensePlateNo3: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_NO3),
            licensePlateCountry3: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY3),
            licensePlateState3: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_STATE3),
            licensePlateNo4: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_NO4),
            licensePlateCountry4: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY4),
            licensePlateState4: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.LICENSE_PLATE_STATE4),
            iitCode1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.IIT_CODE1),
            iitCode2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.IIT_CODE2),
            iitCode3: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.IIT_CODE3),
            iitCode4: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.IIT_CODE4),
            sealNo1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO1),
            sealNo2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO2),
            sealNo3: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO3),
            sealNo4: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO4),
            sealNo5: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO5),
            sealNo6: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO6),
            sealNo7: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO7),
            sealNo8: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO8),
            sealNo9: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SEAL_NO9),
            integration: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.INTEGRATION),
            equipmentInfo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EquipmentInfo.TITLE),
            equipmentId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ID),
            equipmentAceId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_ACE_ID),
            equipmentType: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_TYPE),
            equipmentNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EquipmentInfo.EQUIPMENT_NO),
            insuranceInfo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InsuranceInfo.TITLE),
            insuranceCompany: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InsuranceInfo.INSURANCE_COMPANY),
            insurancePolicyNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InsuranceInfo.INSURANCE_POLICY_NO),
            liabilityAmount: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InsuranceInfo.LIABILITY_AMOUNT),
            yearOfIssuance: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InsuranceInfo.YEAR_OF_ISSUANCE),
            partyInfo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.TITLE),
            conveyanceRemark: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CONVEYANCE_REMARK),
            paxlstRemark: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_REMARK),
            paxlstHelper1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_HELPER_1),
            paxlstDialogInfo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_DIALOG_INFO),
            fetchCrew: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.FETCH_CREW),
            saveCrewFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SAVE_CREW_FLAG),
            saveButton: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.SAVE_BUTTON),
            updateButton: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.UPDATE_BUTTON),
            autoSubmitPaxlst: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.AUTO_SUBMIT_PAXLST),
            autoConfirmManifest: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.AUTO_CONFIRM_MANIFEST),

            tripSectionHelper: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRIP_SECTION_HELPER),
            truckSectionHelper: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.TRUCK_SECTION_HELPER),
            equipmentSectionHelper: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.EQUIPMENT_SECTION_HELPER),
            insuranceSectionHelper: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.INSURANCE_SECTION_HELPER),
            crewSectionHelper: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.CREW_SECTION_HELPER),
            paxLstSectionHelper: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_SECTION_HELPER),

            partyInfoDetailTitle: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PARTY_INFO_DETAIL_TITLE),
            partyInfoAddressTitle: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PARTY_INFO_ADDRESS_TITLE),
            partyInfoLicenseTitle: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PARTY_INFO_LICENSE_TITLE),
            partyInfoDocumentTitle: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PARTY_INFO_DOCUMENT_TITLE),
            paxlstInfoAddressTitle: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PAXLST_INFO_ADDRESS_TITLE),

            manifestHeaderId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.MANIFEST_HEADER_ID),
            shipmentHeaderId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.SHIPMENT_HEADER_ID),
            dob: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.DOB),
            gender: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.GENDER),
            crewType: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CREW_TYPE),
            partyType: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PARTY_TYPE),
            partyIdentifier: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PARTY_IDENTIFIER),
            partyIdentifierCode: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PARTY_IDENTIFIER_CODE),
            partyIdentifierValue: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PARTY_IDENTIFIER_VALUE),
            name: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.NAME),
            name1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.NAME1),
            name2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.NAME2),
            partyDropDown: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PARTY_DROP_DOWN),
            address1: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.ADDRESS1),
            address2: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.ADDRESS2),
            address3: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.ADDRESS3),
            cityName: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CITY_NAME),
            postalCode: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.POSTAL_CODE),
            country: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.COUNTRY),
            state: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.STATE),
            telNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.TEL_NO),
            ctpatInd: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CTPAT_IND),
            hazmatEndorsement: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.HAZMAT_ENDORSEMENT),
            licenseNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.LICENSE_NO),
            licenseCountry: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.LICENSE_COUNTRY),
            licenseState: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.LICENSE_STATE),
            cdlNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CDL_NO),
            cdlCountry: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CDL_COUNTRY),
            cdlState: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CDL_STATE),
            routingCode: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.ROUTING_CODE),
            brokerDownloadRefNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.BROKER_DOWNLOAD_REF_NO),
            portOfLoading: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PORT_OF_LOADING),
            firstName: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.FIRST_NAME),
            middleName: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.MIDDLE_NAME),
            lastName: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.LAST_NAME),
            aceIdFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.ACE_ID_FLAG),
            fastCard: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.FAST_CARD),
            crewId: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.CREW_ID),
            portCode: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.PORT_CODE),
            fastCardFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.FAST_CARD_FLAG),
            saveFlag: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.SAVE_FLAG),
            nationality: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.PartyInfo.NATIONALITY),

            internationalDoc: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InternationalDoc.TITLE),
            docType: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InternationalDoc.DOC_TYPE),
            docNo: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InternationalDoc.DOC_NO),
            docCountry: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InternationalDoc.COUNTRY),
            docState: translate(Namespace.ACE_HIGHWAY_MANIFEST, AceHighwayManifestKeys.InternationalDoc.STATE)
        },
        ACE_HIGHWAY_SHIPMENT: {
            headerId: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.HEADER_ID),
            aceHighway: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.TITLE),
            shipmentType: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.SHIPMENT_TYPE),
            entryType: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.ENTRY_TYPE),
            scn: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.SCN),
            acquittalNo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.ACQUITTAL_NO),
            pointOfLoading: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.POINT_OF_LOADING),
            fdaIndicator: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.FDA_INDICATOR),
            splitIndicator: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.SPLIT_INDICATOR),
            serviceType: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.SERVICE_TYPE),
            inBond7512No: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.IN_BOND7512_NO),
            masterInBondNo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.MASTER_IN_BOND_NO),
            mexicanPedimento: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.MEXICAN_PEDIMENTO),
            foreignDestination: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.FOREIGN_DESTINATION),
            inBondDestination: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.IN_BOND_DESTINATION),
            firmsCode: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.FIRMS_CODE),
            exitDate: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.EXIT_DATE),
            quantity: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.QUANTITY),
            goodsDetails: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.TITLE),
            goodsDescription: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.GOODS_DESCRIPTION),
            noOfPackages: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES),
            noOfPackagesUom: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES_UOM),
            grossWeight: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT),
            grossWeightUom: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT_UOM),
            monetaryAmount: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.MONETARY_AMOUNT),
            countryOfOrigin: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.COUNTRY_OF_ORIGIN),
            harmonizedCode1: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE1),
            harmonizedCode2: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE2),
            harmonizedCode3: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE3),
            harmonizedCode4: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE4),
            harmonizedCode5: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE5),
            c4Code1: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.C4_CODE1),
            c4Code2: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.C4_CODE2),
            c4Code3: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.C4_CODE3),
            c4Code4: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.C4_CODE4),
            c4Code5: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.C4_CODE5),
            marksNo1: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.MARKS_NO1),
            marksNo2: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.MARKS_NO2),
            marksNo3: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.MARKS_NO3),
            marksNo4: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.MARKS_NO4),
            marksNo5: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.MARKS_NO5),
            vin1: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN1),
            vin2: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN2),
            vin3: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN3),
            vin4: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN4),
            vin5: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN5),
            vin6: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN6),
            vin7: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN7),
            vin8: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN8),
            vin9: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN9),
            vin10: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN10),
            vin11: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN11),
            vin12: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN12),
            vin13: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN13),
            vin14: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN14),
            vin15: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN15),
            vin16: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN16),
            vin17: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN17),
            vin18: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN18),
            vin19: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN19),
            vin20: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GoodsDetails.VIN20),
            saveButton: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.SAVE_BUTTON),
            updateButton: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.UPDATE_BUTTON),
            shipmentHeaderSectionHelper: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.SHIPMENT_HEADER_SECTION_HELPER),
            partySectionHelper: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PARTY_SECTION_HELPER),
            goodsSectionHelper: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.GOODS_SECTION_HELPER),

            partyInfo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.TITLE),
            partyType: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_TYPE),
            partyIdentifier: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER),
            name1: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.NAME1),
            name2: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.NAME2),
            address1: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.ADDRESS1),
            address2: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.ADDRESS2),
            address3: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.ADDRESS3),
            cityName: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.CITY_NAME),
            postalCode: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.POSTAL_CODE),
            country: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.COUNTRY),
            state: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.STATE),
            telNo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.TEL_NO),
            email: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.EMAIL),
            ctpatInd: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.CTPAT_IND),
            partyDropDown: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_DROP_DOWN),
            partyIdentifierCode: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_CODE),
            partyIdentifierValue: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE),
            partyIdentifierValueNote: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_NOTE),
            partyIdentifierValueEIN: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_EIN),
            partyIdentifierValueSSN: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_SSN),
            partyIdentifierValueCAN: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_CAN),
            portCode: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PORT_CODE),
            routingCode: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.ROUTING_CODE),
            brokerDownloadRefNo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.BROKER_DOWNLOAD_REF_NO),
            portOfLoading: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PORT_OF_LOADING),
            filerCode: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.FILER_CODE),
            saveFlag: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.SAVE_FLAG),
            phoneNo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.PHONE_NO),
            faxNo: translate(Namespace.ACE_HIGHWAY_SHIPMENT, AceHighwayShipmentKeys.PartyInfo.FAX_NO)
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <ACEHighwayTranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </ACEHighwayTranslationContext.Provider>
    )
}

export default ACEHighwayTranslationProvider

export { ACEHighwayTranslationContext, ACEHighwayTranslationConsumer }
