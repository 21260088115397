import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import pathMap from 'src/paths/pathMap'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import ManifestViewContent from './ManifestViewContent'
import ConfirmDialog from '../../common/ConfirmDialog'
import FormProperties from './FormProperties'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN }
} = constants

function ViewPage(props) {
  const { history, location, showNotification } = props
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { id } = useParams()
  const { createRecord, deleteRecord, fetchRecord, fetchRecords } =
    useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_MANIFEST, Namespace.ACE_HIGHWAY_SHIPMENT])

  const translatedTextsObject = makeTranslatedTextsObject()

  let firstExpectedPort;
  if(location !== undefined) {
    firstExpectedPort = location.firstExpectedPort
  }

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AceHighwayManifestApiUrls.GET_DETAILS,
        id,
        (res) => res.content[0]
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Transport indicator
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACE_HW_TRANSPORT_INDICATOR'
          }
        ],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value: 'ACEHWY_POE' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Conveyance type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACE_HW_CONVEYANCE_TYPE'
          }
        ],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: EQUAL, value: 'ACE' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Crew type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_CREW_TYPE' }],
        undefined,
        'code'
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_PARTY_TYPE' }],
        undefined,
        'code'
      ),  
      // Entry type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: IN,
            value: 'ACE_HW_ENTRY_TYPE_IN_BOND|ACE_HW_ENTRY_TYPE_SEC'
          }
        ],
        undefined,
        'code'
      ),
      // Point of loading
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value: 'ACEHWY_POL' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(
      ([
        data,
        status,
        transportIndicator,
        firstExpectedPort,
        conveyanceType,
        countries,
        countryStates,
        crewType,
        partyType,
        entryType,
        pointOfLoading
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
        }

        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        var clientData = FormProperties.toClientDataFormat(data)
        clientData.arrivalDate = clientData.arrivalDate + ' ' +clientData.arrivalTime;
        setData(clientData)
        setLookups({
          status,
          transportIndicator,
          firstExpectedPort,
          conveyanceType,
          countries,
          countryStates: states,
          crewType,
          partyType,
          entryType,
          pointOfLoading
        })
      }
    )
  }, [])

  if (data === null) {
    return <CircularProgress />
  }

  function makeTranslatedTextsObject() {
    let aceHighwayManifest = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TITLE
    )
    let aceHighwayTruckConveyance = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRUCK_CONVEYANCE_TITLE
    )
    let carrierScac = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CARRIER_SCAC
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRIP_NO
    )
    let arrivalDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ARRIVAL_DATE
    )
    let transitIndicator = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRANSIT_INDICATOR
    )
    let firstExpectedPort = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.FIRST_EXPECTED_PORT
    )
    let conveyanceNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CONVEYANCE_NO
    )
    let conveyanceType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CONVEYANCE_TYPE
    )
    let aceId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ACE_ID
    )
    let email = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EMAIL
    )
    let licensePlateNo1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_NO1
    )
    let licensePlateCountry1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY1
    )
    let licensePlateState1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_STATE1
    )
    let licensePlateNo2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_NO2
    )
    let licensePlateCountry2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY2
    )
    let licensePlateState2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_STATE2
    )
    let licensePlateNo3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_NO3
    )
    let licensePlateCountry3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_COUNTRY3
    )
    let licensePlateState3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.LICENSE_PLATE_STATE3
    )
    let sealNo1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO1
    )
    let sealNo2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO2
    )
    let sealNo3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO3
    )
    let sealNo4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO4
    )
    let sealNo5 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO5
    )
    let sealNo6 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO6
    )
    let sealNo7 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO7
    )
    let sealNo8 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO8
    )
    let sealNo9 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SEAL_NO9
    )
    let iitCode1 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.IIT_CODE1
    )
    let iitCode2 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.IIT_CODE2
    )
    let iitCode3 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.IIT_CODE3
    )
    let iitCode4 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.IIT_CODE4
    )
    let equipmentInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EquipmentInfo.TITLE
    )
    let partyInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PartyInfo.TITLE
    )
    let partyAceId = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PartyInfo.ACE_ID
    )
    let fastCard = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PartyInfo.FAST_CARD
    )
    let telNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PartyInfo.TEL_NO
    )
    let partyInfoAddressTitle = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PARTY_INFO_ADDRESS_TITLE
    )
    let insuranceInfo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.TITLE
    )
    let insuranceCompany = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.INSURANCE_COMPANY
    )
    let insurancePolicyNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.INSURANCE_POLICY_NO
    )
    let liabilityAmount = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.LIABILITY_AMOUNT
    )
    let yearOfIssuance = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.InsuranceInfo.YEAR_OF_ISSUANCE
    )
    let aceManifestShipment = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.TITLE
    )
    let statusNotificationTitle = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.TITLE
    )
    let statusNotificationName = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.NAME
    )
    let statusNotificationSnType = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.SN_TYPE
    )
    let statusNotificationShipmentControlNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.SHIPMENT_CONTROL_NUM
    )
    let statusNotificationEmail = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.EMAIL
    )
    let statusNotificationMobileNum = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.StatusNotification.MOBILE_NUM
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.DELETE_BUTTON
    )

    return {
      aceHighwayManifest,
      aceHighwayTruckConveyance,
      carrierScac,
      tripNo,
      arrivalDate,
      transitIndicator,
      firstExpectedPort,
      conveyanceNo,
      conveyanceType,
      aceId,
      email,
      licensePlateNo1,
      licensePlateCountry1,
      licensePlateState1,
      licensePlateNo2,
      licensePlateCountry2,
      licensePlateState2,
      licensePlateNo3,
      licensePlateCountry3,
      licensePlateState3,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      iitCode1,
      iitCode2,
      iitCode3,
      iitCode4,
      equipmentInfo,
      partyInfo,
      partyAceId,
      fastCard,
      telNo,
      partyInfoAddressTitle,
      insuranceInfo,
      insuranceCompany,
      insurancePolicyNo,
      liabilityAmount,
      yearOfIssuance,
      aceManifestShipment,
      statusNotificationTitle,
      statusNotificationName,
      statusNotificationSnType,
      statusNotificationShipmentControlNum,
      statusNotificationEmail,
      statusNotificationMobileNum,
      editButton,
      cloneButton,
      deleteButton
    }
  }

  function handleCloneManifest() {
    createRecord.execute(
      AceHighwayManifestApiUrls.CLONE,
      data,
      (data) => {
        history.push(pathMap.ACE_HIGHWAY_MANIFEST_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteManifest() {
    deleteRecord.execute(
      AceHighwayManifestApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <ManifestViewContent
            data={data}
            getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
            id={id}
            translatedTextsObject={translatedTextsObject}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)}
          >
            Back to manifest list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.status === '1005' && (
              <CngButton
                disabled={data.status !== '1005'}
                onClick={() => setConfirmDialogOpen(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              onClick={handleCloneManifest}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                disabled={data.status === '1008'}
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.ACE_HIGHWAY_MANIFEST}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default ViewPage
