import { Box, Card, useTheme } from '@material-ui/core'
import React from 'react'
import ManifestAddForm from './ManifestAddForm'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormStyles } from 'src/common/FormStyles'
import clsx from 'clsx'
import SectionJumper from 'src/components/SectionJumper'

function AddPage({ history, showNotification, onSetLoading }) {
  const theme = useTheme()
  const formClass = useFormStyles()

  return (
    <Card className={clsx(formClass.form, formClass.inputs)}>
      <Stepper steps={['Create Trip']} activeStep={0} />
      <Box padding={2}>
        <ManifestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
      <SectionJumper pages={[
        {
          label: 'Add Manifest',
          sections: [
            {label: 'Trip Information', id: 'trip-info'},
            {label: 'Truck (Conveyance)', id: 'truck-conveyance'},
            {label: 'Equipment Information', id: 'eqp-info'},
            {label: 'Crew Information', id: 'crew-info'},
            {label: 'Insurance Information', id: 'insurance-info'},
            {label: 'Message Function', id: 'msg-fn'},
          ]
        }
      ]} />
    </Card>
  )
}

export default withFormState(AddPage)
