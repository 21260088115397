import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import Switch from 'src/components/Switch'


const {
    form: {
        field: { CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

function MessageFunctionSection({ disabled }) {
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const paxLSTFlag = useWatch({ name: 'paxLSTFlag' })
    const messageFunction = useWatch({ name: 'messageFunction' })

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'messageFunction')} id="msg-fn">
                <Grid container spacing={2}>
                    <CngGridItem xs={12} md={6} shouldHide={!paxLSTFlag}>
                        <CngCodeMasterAutocompleteField
                            size='small'
                            name='messageFunction'
                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'messageFunction')}
                            disabled={disabled}
                            codeType='ACE_HW_MSG_FUNCTION_MULTI'
                            key={messageFunction}
                        />
                    </CngGridItem>
                    <CngGridItem xs={6} md={3} shouldHide={!paxLSTFlag}>
                        <Switch fullWidth name='autoSubmitPaxLst' label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'autoSubmitPaxlst')} />
                    </CngGridItem>
                    <CngGridItem xs={6} md={3} shouldHide={!paxLSTFlag}>
                        <Switch fullWidth name='autoConfirmManifest' label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'autoConfirmManifest')} />
                    </CngGridItem>
                    <CngGridItem xs={12} shouldHide={paxLSTFlag}>
                        <CngCodeMasterAutocompleteField
                            size='small'
                            name='messageFunction'
                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'messageFunction')}
                            disabled={disabled}
                            codeType='ACE_HW_MSG_FUNCTION_SINGLE'
                            key={messageFunction}
                        />
                    </CngGridItem>

                </Grid>
            </CngSection>
        </Grid>
    )
}

export default MessageFunctionSection