import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { DataFlattener, components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from 'react-router-dom'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {
  const loc = useLocation()
  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.ACE_HIGHWAY_MANIFEST}/edit/` + e.id)
  )
  let formikPropsData
  if (loc.state !== undefined && loc.state.shouldRerender === undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      // initialValues: DataFlattener.parse(loc.state)
      initialValues: loc.state
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
      history.push({
        pathname: `${pathMap.ACE_HIGHWAY_MANIFEST}/edit/` + e.id,
        state: { step: 2, manifestData: e }
      })
    }
  }

  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
        <FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          shouldHideMap={shouldHideMap}
        />
      )}
      formikProps={formikPropsData}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      create={{
        url: AceHighwayManifestApiUrls.POST,
        onSuccess: (e) => {
          showNotification('success', 'Created successfully.')
          onSuccessCallback.current(e)
          onSetLoading(false)
        }
      }}
      renderButtonSection={(loading) => (
        <AceHWCustomButtonSection
          history={history}
          loading={loading}
          onSaveAndContinue={handleSaveAndContinue}
          onSetLoading={onSetLoading}
        />
      )}
    />
  )
}

export default AddPage

function AceHWCustomButtonSection(props) {
  const { history, loading, onSaveAndContinue, onSetLoading } = props

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          disabled={loading}
          onClick={() =>
            history.push(`${pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW}`)
          }
          size='medium'
        >
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              disabled={loading}
              // onClick={onSaveOnly}
              type='submit'
              size='medium'
              shouldShowProgress={loading}
            >
              Save
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              disabled={loading}
              type='submit'
              onClick={onSaveAndContinue}
              size='medium'
            >
              Save &amp; continue
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
